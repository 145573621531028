const knownWordsDict = {
  AAH: 'ING',
  AAL: 'IIS',
  AAR: 'RGH',
  ABA: 'BUACASCAYCLICOTCUSDIAKASMPSNETNGANICRISSEDSERSESSGISIASICSIOSSITEDTERTESTICTISTONTORTTUTUAYAH',
  ABB: 'ACYASIATEAYEESSESTEYSOTSOTTREV',
  ABC: 'ESS',
  ABD: 'ALIESTIELUCEUCT',
  ABE: 'DGEGGEIGHLESLIANDSRIASSE',
  ABH: 'ORS',
  ABI: 'DALDEDDERDESEGHENTGEILAOLLAPON',
  ABJ: 'ECTURE',
  ABK: 'ARIARYHAS',
  ABL: 'ACHAREATEAUTAZEENDESTINSOCKOOMUDEUSHUTE',
  ABM: 'HOS',
  ABN: 'AKI',
  ABO: 'ARDBRADAHDEDDESHMSLLAMASNGONNERADRALRTSUNDUTSVES',
  ABR: 'ADEAIDASEASHAUMAZOEEDEGEEIDICOINEOADOMAOODOOKUPT',
  ABS: 'CAMEILENTISTMHOOHMOILORBUMEURD',
  ABU: 'CCOLIALICRSTSEDSEESERSES',
  ABV: 'OLT',
  ABW: 'ATT',
  ABY: 'INGSMSSSA',
  ACA: 'CIACINDIADIEENAJOUMARNTHRIDROLRUSTERTES',
  ACC: 'EDEENDENTEPTESSISEITELOYOILOLLORDOSTRUEUMBUPYUSE',
  ACE: 'DIAITERAERICRINRLIRRATALTICTINTLATOLTUMTYL',
  ACH: 'AFEAGEAPEATEEATECHECKEERENEETEIERILLIMEINGIRAKANOKERASREETELTERUAS',
  ACI: 'DERDICDLYDYLNARNICNUS',
  ACK: 'EESEYSMANMENNEWNOWTON',
  ACL: 'OUD',
  ACM: 'AEAITE',
  ACN: 'IDAODE',
  ACO: 'ASMELAINEMIANICNINPICPONREARIARNSRUSUPAUPE',
  ACQ: 'UIT',
  ACR: 'ACYASYAWLAZEEAKEAMISYITAITEITYOCKOOKOSEOSS',
  ACT: 'AEAIADIANIFYINEINGINSIONIUMIVEORSORYUALURE',
  ACU: 'ATECHIITYLEALEIMENTERTES',
  ACY: 'LAL',
  ADA: 'GESGIOIZELATLIDMASMICNCEPIDPISPTSRMETISTOMUNT',
  ADC: 'ONS',
  ADD: 'EEMENDERSICEICTINGITALEDLESOOMUCEUCT',
  ADE: 'EMSLEALIAMPTNIANINNYLONAPTSSMYSTE',
  ADH: 'AKAARAEREORT',
  ADI: 'ATECEAENTEUSEUXGEIGHEGHTPICPSYPYLTALTIOTUS',
  ADJ: 'ECTIGAOINUREUSTUTE',
  ADL: 'ESS',
  ADM: 'ASSIREITSIXTOVE',
  ADN: 'ATEEXAOUN',
  ADO: 'BESBOSLPHNAINIANICNINNISORSPTSRALREDRERRESRNORNS',
  ADR: 'EADEAMENOENTIANIFTOITOOP',
  ADS: 'BUDORB',
  ADU: 'ANALARLCELTS',
  ADV: 'ECTENAENEENTERBERTICEISEISOISYOKE',
  ADW: 'ARD',
  ADY: 'TONTTATUM',
  AEA: 'CUSEAN',
  AEC: 'IALIUM',
  AED: 'EGIILEINE',
  AEF: 'ALD',
  AEG: 'EANINA',
  AEN: 'ACHEANEASEIDEUS',
  AEO: 'LIALICLIDLISLUSNIC',
  AEQ: 'UOR',
  AER: 'AGEATEIALIEDIERIESIFYILYOBEOPEOSEUGO',
  AES: 'TIITUS',
  AET: 'HERHONIAN',
  AFA: 'CEDINT',
  AFE: 'ARDNILTAL',
  AFF: 'AIRECTEEREIREREICHIEDIESILEINEINGIRMIXTLUELUXORDRAPRAYRETUSE',
  AFG: 'HAN',
  AFI: 'ELD',
  AFL: 'AMEAREOATUSH',
  AFO: 'CAL',
  AFR: 'AIDEETESHETEICAIDIITEITSONTOWN',
  AFS: 'HAHHAR',
  AFT: 'ABAERSOSA',
  AGA: 'CESDICLMAMAEMASMICMIDMISPAEPAIRICRUMTESTHAVESWAMZED',
  AGE: 'DLYINGISMISTNCYNDANESNTS',
  AGG: 'ERSESTIESROS',
  AGH: 'ASTORI',
  AGI: 'BLENGSSMSSTS',
  AGL: 'AIAAOSAREEAFEAMETSINT',
  AGN: 'AILAMEATEEANEAUIZEOSY',
  AGO: 'GICINGNALNESNIANICRAERASROTUTAUTIUTY',
  AGR: 'ACEAFEEATEEDEEREESEGEESTIASIEFIOTISEITOOANOOFOPEOTEUFEUIF',
  AGU: 'ADAAJIARAILTISEISHJONNAH',
  AGY: 'NICRIA',
  AHC: 'HOO',
  AHI: 'MSA',
  AHM: 'ADIEDI',
  AHO: 'LDSRSE',
  AHT: 'ENA',
  AHU: 'ACAULA',
  AID: 'ANTENNERSFULINGMANMEN',
  AIG: 'LETRET',
  AIK: 'ANEIDOONA',
  AIL: 'EENINGURO',
  AIM: 'ARAERSFULINGORE',
  AIN: 'HUM',
  AIO: 'LIS',
  AIR: 'BAGBUSERSESTIERIFYILYINGISHMANMENTEDTHSWAY',
  AIS: 'LEDLESSOR',
  AIT: 'ION',
  AIV: 'ERS',
  AIW: 'AIN',
  AIZ: 'OON',
  AJE: 'NJO',
  AJI: 'MEZVAS',
  AJO: 'INTUREWAN',
  AJU: 'GAS',
  AKA: 'MAINIAROASHAWAIZGA',
  AKC: 'HEH',
  AKE: 'AKEBIALASLEYNESTON',
  AKH: 'ARAROTUND',
  AKI: 'MBO',
  AKM: 'ITE',
  AKO: 'ASMNGE',
  ALA: 'CHAGAOGAUHEEIHIITELIALOILUSMOSNDSNGENINNTSNYLRESRGERIARICRMSRUMSASSKATEDUDAUNDUNTZOR',
  ALB: 'ANYATAEDOEITERTIANIFYINOIONITEOINUCAUGOUMSURN',
  ALC: 'ADEAICAIDALIEDOHEMIDSINELADOVEUMYYON',
  ALD: 'ANEEIAERNERSIDEIMEINEOLSOSERIN',
  ALE: 'CUPGARGERNGEPHSPOTPPORCERSERTARTSSANTAPTTEVINXASXIAXICXINXISZAN',
  ALF: 'AJEAKIIRKREDURO',
  ALG: 'ATEEDIEDOINEINSISTOIDORSOUSUMS',
  ALH: 'AGIENA',
  ALI: 'BISBLECIADADENEENSGHTGNSNEDNERNESNITOTHPEDPINSMASONSOSTERVESYAHYASYOS',
  ALJ: 'AMAOBA',
  ALK: 'AIDALIANEENEIDEIESINEOOLOXYYDSYLSYNE',
  ALL: 'AYSECTEGEELEENEEVEEYSICEIEDIESIUMODSODYOSEOTSOWSOXYOYSTUDUDEUREYICYLSYOU',
  ALM: 'ACHAHSAINEHSERYICEIDAIRANEROINONDOSEOSTOUSUCEUDEUDSUGSURY',
  ALN: 'AGEATHEINICOUIN',
  ALO: 'DIAGIAHASINSNSONZOOFEOSEYAU',
  ALP: 'ACAEENHASHINHOLHOSHYLHYNIANIEUINEIST',
  ALR: 'AUNOOTUNAUNE',
  ALS: 'IKEINEOON',
  ALT: 'AICAIDAIRARSERNERSEZAHEAICAIFYOUNURE',
  ALU: 'DELDRALAELARLETLIMMELMENMICMINMNAMNIPAG',
  ALV: 'EUSINAINEITE',
  ALW: 'AYSISEITE',
  ALY: 'PINPUMTES',
  AMA: 'BELBLEDANDISDOUINEISTLICMAUNDANDENIANTERINRNARTHSTASTYTEDTOLZEDZERZESZIAZON',
  AMB: 'ACHAGEARIARYASHEERERSERYIERIGUITSLEDLERLESURYUSH',
  AMD: 'AHL',
  AME: 'BAEBANBASBICBIDDEOERSIVALETLIALUSNDENDSNIANTANTSNTYRCE',
  AMG: 'ARN',
  AMH: 'RAN',
  AMI: 'ANTCALCEDCESCUSDESDICDIDDINDOLDONDSTGASGOSLESMIANESNICNTARALRAYTIEXIA',
  AML: 'ONG',
  AMM: 'EOSINEINOONO',
  AMN: 'IACIONIOS',
  AMO: 'BERBYREBALESMALMISMUMRALRESRETRPHRUATUSULIUNTURSVEDYAN',
  AMP: 'AROEREERYHIBHIDLERLEXULEULS',
  AMR: 'ITA',
  AMS: 'ATH',
  AMT: 'MANMENRACRAK',
  AMU: 'CKSGISLAELASLETLLANAMRCARRUSEDSEESERSESSGOSIAYON',
  AMY: 'DONLANLICLINLOMLONLUMOUSRINRISROLTAL',
  ANA: 'BASBIACESCIDDEMGAPGEPGUAHAOHAUKESLAVLLYLOGLYTNASNDANKENSINTANYMPESPHEQUARCHRYATOXTTATTOTUMXON',
  ANB: 'URY',
  ANC: 'HATHORIENILEOLYOMEONAONEONYORA',
  AND: 'EANERSEVOHRAIANINEINGIRAOKEREAREWRIARICROLRON',
  ANE: 'ARSATHLEDLESMIAMICNSTPIARGYRLYSISTICUCHZEH',
  ANG: 'AMIARAARYELAELOELSERSICOILDILIILOINALEDLERLESLICLOSOLAORASTSUIDUISULAULEUST',
  ANH: 'ANGELEIMA',
  ANI: 'CCACUTGHTLAOLAULICLIDLINLLAMALMASMESMISMUSONSSALSESSICSILSOLSUMSYLTOS',
  ANK: 'ARALESLETOLIUSH',
  ANL: 'ACEAGEAUT',
  ANN: 'ALEALSALYARDARYATSEALECTEXAEXEISTITEONAOYSUALULEULIULS',
  ANO: 'DALDESDICDONDOSGRAINEINTLESLISMALMERMIAMICMIENADNOLNYMPIAPLAPSYRAKRTHSIATIATTATTOTUSURAUREXIAXIC',
  ANS: 'ATEEISELMWER',
  ANT: 'ARADOMEALEEDERIEVAHEMHERHOLHOSHUSIAEIARICAICKICSINGISIJARLERLIALIDONYRALRESRINRUM',
  ANU: 'BINBISKITRALRANRIARICSESSIM',
  ANV: 'ILS',
  ANY: 'HOWONEWAYWHY',
  AOG: 'IRI',
  AON: 'ACHIAN',
  AOR: 'ISTTAETALTASTIC',
  AOS: 'MIC',
  AOU: 'ADSDAD',
  APA: 'CHEISELITRAITANTHYYAO',
  APE: 'DOMLETMANPSYRCHRCUREAXEDXES',
  APH: 'ETAIDSODIONYTHA',
  API: 'ACAARYCADCALCESDAEECENAENCHOIDOLEOSE',
  APL: 'ACEITEOMBOMEUDA',
  APN: 'EALEASEICOEA',
  APO: 'CHADALDANDESDIAGEEGONISELARLLOLOGNIANICRIASIAZEM',
  APP: 'AIRALEALLALSASTEALEARELSENDEREERTETEIUSLEDLESLOTORTOSEROX',
  APR: 'ONSOWL',
  APT: 'ATEERAESTIANOTE',
  APU: 'LSE',
  AQU: 'AGEAKEATEILAOSE',
  ARA: 'BANBIABICBINBISBITBLECHEDIDINSIREISELESLIALIEMIDMISMUSNEANGANGORAOUANUNAWAKYNE',
  ARB: 'ALOELAITHORSORYOTAOURTRNUSTUTE',
  ARC: 'ADEADYANAANEATEATOCOSHAEHAIHEDHERHESHIEHILHINHITHLYHONINGITEKEDOSESINTANTIATICTOSUALULA',
  ARD: 'ASSEAEEBSEIDENTISHITIITOORSOURURE',
  ARE: 'ACHADYCASCHEITONAENASNGANIGOLAOLETESTTE',
  ARG: 'ALAALIALSANDANSEANEMAENTHANHELIFYILSINEIVELEDLESOANOLSONSOSYOTSUEDUERUESUFYUTAUTEYLEYLL',
  ARH: 'ATS',
  ARI: 'ANABINCINDEDDERDGEDLYELSGHTGUELEDLLIOLEOSEOSIOSOSEDSENSERSESSTASTESTO',
  ARK: 'ITEOSE',
  ARL: 'ENEENGESSINEINGOUP',
  ARM: 'ADAADOARYATAERSETSFULIDAIEDIESILLINEINGLETORSORYOURPADPITURE',
  ARN: 'AUTICAOLD',
  ARO: 'ASTIDSINTLIALLAMALMASNIARASUNDUSEXYLYNT',
  ARP: 'ENSENT',
  ARR: 'ACEACHACKAGEAMEANDANTAYSEARECTENTESTHALIBAIDEIETISHIVEOBAODEOPEOWSOWYOYAOYOTEZ',
  ARS: 'ENOHININEINOONOONS',
  ART: 'ABAABEELSERYFULGUMHELHRAHURIADIERILYISTIZELET',
  ARU: 'MINNDONTASHA',
  ARY: 'ANS',
  ARZ: 'AVAAWA',
  ASA: 'HELRINRONRTARUM',
  ASB: 'EST',
  ASC: 'APEAREEBCENDENTHAMHERIANILLITBITEOMAOTSULA',
  ASD: 'ICS',
  ASE: 'ITYLARLLIMIAMIC',
  ASF: 'AST',
  ASG: 'ARD',
  ASH: 'AKEAMECANERYIERILYINEINGKEYLARLERMANMENOREPANPITRAFRAM',
  ASI: 'ANSDENDESDEULIDLUSMENTIA',
  ASK: 'ANTARIERSILEINGOYE',
  ASL: 'AKEANTEEPOPE',
  ASM: 'ACKEARILEOKE',
  ASN: 'ORT',
  ASO: 'NIAUTH',
  ASP: 'ACEECTENSERNERSICSIDEIREISHORTOUT',
  ASQ: 'UAT',
  ASR: 'AMA',
  ASS: 'ACUAHYAILAISARTARYATEAUTAYSBAAEALELFENTERTESSETHETSIFYIGNISAISEISHISIISTITHIZEMANOILOINORTUMEURDUREYTH',
  AST: 'ALKAREARTATEEAMEEPEERELYERNERSERTHMAIANINTITEONDONEONYOOPORERALRAYRERRIDROLRONRUTUTE',
  ASU: 'ANG',
  ASW: 'AILARMASHEATELLEVEINGIRLOON',
  ASY: 'LUM',
  ATA: 'BALBEGBEKLANMANUNTVICVUSXIAXICZIR',
  ATB: 'ASH',
  ATE: 'LESLIC',
  ATH: 'ENAENSINGINKOLDORTROBYMY',
  ATI: 'MONNGA',
  ATL: 'ATLOID',
  ATM: 'ANS',
  ATO: 'CHACIAKALLLSMICNALNEDNERNESNIANICPENPICRAISSAXICXYL',
  ATR: 'AILEDEESYEUSIALIUMOCEOPAOUSYPA',
  ATS: 'ARA',
  ATT: 'ACHACKAINAMEARSASKEALENDENTERNERRERYESTICEICSILAIREOMYORNOURRAPRIBUNEURN',
  ATU: 'AMI',
  ATV: 'EEN',
  ATW: 'AINEELEENINDIRLISTITEIXT',
  ATY: 'PIC',
  AUB: 'ADEAINREYURN',
  AUC: 'TORUBA',
  AUD: 'ACEADSIANILEINGIONIOSITSREY',
  AUF: 'AIT',
  AUG: 'EANENDERSHTSITERIMUREURSURYUST',
  AUH: 'UHU',
  AUK: 'LET',
  AUL: 'DERETELAY',
  AUM: 'AGAAILBRYERYOUSRIE',
  AUN: 'CELTERTIETLYTRE',
  AUP: 'AKA',
  AUR: 'ANGATAATEEALENEEUSIDEIFYIGAIGOINEISTITEOCHORAOREOUSUMSUNGURE',
  AUS: 'HARPEXSIETERTINUBO',
  AUT: 'ECYEREEURHORISMISTOEDOMAOTAUMN',
  AUX: 'INS',
  AVA: 'ILSLONNCENIANTINYURAMTARUNT',
  AVE: 'LOZNERNGENINNUERAHRIARILRINRSERTSSTA',
  AVI: 'ANSARYATECHIDINDLYDYAJJAKOMONSSOSTALTICVES',
  AVO: 'CATCETIDSSETUCHUREWALWEDWERWRYYER',
  AVS: 'HAR',
  AVU: 'LSE',
  AWA: 'DHIITSKEDKENKESLIMNYURDSSTETCHTER',
  AWE: 'ARYDLYIGHING',
  AWH: 'APEEELEFTILEIRL',
  AWL: 'ESS',
  AWM: 'OUS',
  AWN: 'ING',
  AWO: 'KENRRYRTH',
  AWR: 'EAKECKISTONG',
  AWS: 'HAR',
  AXE: 'MANMENNIC',
  AXH: 'EAD',
  AXI: 'ATELLAOMSSEDSESTES',
  AXL: 'IKE',
  AXO: 'NALNESNIANIC',
  AXS: 'EED',
  AXT: 'REE',
  AXU: 'NGE',
  AXW: 'EEDISEORT',
  AYE: 'NST',
  AYI: 'ELD',
  AYL: 'ESS',
  AYM: 'ARAORO',
  AYT: 'HYA',
  AZA: 'LEANDEZEL',
  AZI: 'DESLUTMINNESOLA',
  AZL: 'ONS',
  AZO: 'LESLLANALNICRESTEATEDTESTHSTICTIN',
  AZR: 'AEL',
  AZT: 'ECAECS',
  AZU: 'REDRES',
  AZY: 'GOS',
  BAA: 'INGLIM',
  BAB: 'ASUBIEBITBLEBLYELSERYHANIEDIESINEIONISHISMISTITEKASLAHLOHOENOOLOONOOSOOTULSUMAURD',
  BAC: 'ABAACHCAECARHEDHELHESILEKEDKENKERKETKIEKUPKUSLINONSONYOPAULAULEULIURY',
  BAD: 'AGAASSAUDAWIAXEDERDIEGEDGERGESGIRHANIANMANMEN',
  BAE: 'RIATYL',
  BAF: 'AROFEDFLETAHYOT',
  BAG: 'ANIASSDADELSFULGEDGERGIEGITHLAIOSMANMENNESNETNIONUTOBOONGPODUETUIOWIGWYN',
  BAH: 'ADAAMAERAIMAUMAUTSUTU',
  BAI: 'DAKDARDYAERAKIELEDLEELERLEYLIELLILORNIERAMRDIRNSTEDTERZASZEDZES',
  BAJ: 'ADAREEURY',
  BAK: 'ERSERYINGSHITUNUBAULAUTU',
  BAL: 'AAMADAAFOAICANTAOSATAATEAWAAWUBOACHECONDEDDENDERDIEDLYEENERSETEEYSIANIJAINEINGITAITIIZEKANKARKEDKERKISLADLAMLANLASLATLEDLERLETLONLOTLOWLSYLUPNEAOCHOLOSAMSASTEITERTICTISUBAUCHUGA',
  BAM: 'BANBOOBOSBUKMEDOTH',
  BAN: 'ABAAGOANAATECALCHACHICOSCUSDARDEDDELDERDHUDIEDITDLEDOGDONDORDOSGEDGERGHYGIAGLEGOSGUPIANINGISHIVAIWAIYAJOSKEDKERKETNATNEDNERNETNUTQUEQUOTAMTAYTERTINTUSUYOYAIYANZAI',
  BAO: 'BAB',
  BAP: 'HIA',
  BAR: 'ACAAKAANIATOAZABALBARBASBEDBELBERBESBETBLEBOYBRABREBUTCANCASCHECOODEDDEEDELDESDICDIEECAEGEEKAELYESTFEDFLYFULGEDGEEGERGESGIRHALHOPILEINGISHITEIUMKANKEDKENKERKEYKLELEYLOWMANMENMIENEYOCOOKOOLOONGONIONSONYOTOQUERADRASRATREDRELRENRERRESRETRIOROWSACSOMTERTONUCHVELWALWAYWINYESYONYTAYTE',
  BAS: 'ALEALTELYESTHAWHEDHERHESIALICSIFYILSINGINSIONKEDKERKETNATNETOGAOIDOKOOTEQUESANSESSETSIASIESLYSONSOSSUSTEDTENTERTESTONUTO',
  BAT: 'AANARAATAAVIBOYEAUELLETEFULHEDHERHESHICHOSHYLIKSINGINOLANLERLETLONMANMENOIDOKAONSOONTAKTEDTELTENTERTIKTLETONTUEULEZEN',
  BAU: 'BEEBLEERALEALKSLKYSONTTA',
  BAV: 'ARYIANIUSOSO',
  BAW: 'BEEBLEDRYLEDLERLEYRELTIE',
  BAX: 'TER',
  BAY: 'AMOANOARDETAETEINGISHLETMANMENONGOUS',
  BAZ: 'AARARSOOS',
  BEA: 'CHYCONDEDDERDLEGLEKEDKERMEDMERNEDNERNIENOSRDSRDYREDRERSTSTAETASTEETENTERTUSUEDUMEUNEUTIUTSUTYVER',
  BEB: 'ACKAITANGITELEDLOTOPSOSSUMPUSY',
  BEC: 'ALLALMAMEAPSARDCHIHERHICKEDKERKETKIEKONLADLAPLAWLIPLOGOMEOOMOSTRAMUNAURL',
  BED: 'AFFAMNAMPAREARKASHAUBAWNAZEBUGCAPDEDDEREADEAFEBTECKEENELLELSENEEWSIMSIRTKEYLAMLARMANOLTOTEOWNOYOPADPANRALRELRIDRIPROPRUGSITUCKUINUKEULLUMBUNGUSKUSTWAY',
  BEE: 'ARNBEECHYDOMFEDFERFINISHLOLMANMENNTOPEDPERTLEVESWAYZER',
  BEF: 'ALLAMEELLILEIREISTITSLAGLAPLEALUMOAMOGSOOLOREOULRETRIZUME',
  BEG: 'ALLANIARIARYASHASSATSAUDAZEECKETSGARGEDGERIFTILDINSIRDIRTLADLEWLICLUCLUENAWOBSOHMONEOUDOWKRAYRIMUINULFUMSUNK',
  BEH: 'ALEALFANGAVEEADEARELDELPESTINDINTOLDOOFOOTORNOVEOWLUNGYMN',
  BEI: 'GELGESNGSNLYRUT',
  BEJ: 'ADEANTAPEAZZUCO',
  BEK: 'ICKINGISSNITNOTNOW',
  BEL: 'ACEADYAGEAITAMYARDASHASTATEAUDAYSDAMDEREAFEAPEEDEFTEVEFRYGAEGASGICIALICKIEDIEFIERIESIKEILIIMEIONITEIVELEDLESLICLISLONLOWLUMOAMOCKOIDONEONGOOKORDOUTOVEOWSTEDTERTIETIRTISTONUGAUTE',
  BEM: 'AILAIMASKATAAULBEXEALEANEETETEIREISTIXTOANOATOCKOILOLEOLTOONUCKUSEUSK',
  BEN: 'AMEAMIBENCHYDAYDEDDEEDELDERDYSEMEGALIGNNELNESNETNISOTESELSHISILSONTONUMBZALZILZINZOLZYL',
  BEP: 'AIDALEARTELTILEILLITYRAYUFF',
  BER: 'AINAKEAPTATEBEREANEDEEFTENDETSGERGUTIDEIMEINGITHLEYLINMESNIENOOOLLRETSILSIMTATTHATHSTIETINUSTVIEYLS',
  BES: 'ANDANTCABEAMEEKEEMEENETSHAGHODHOWIDEIGHINGLABLAPLOWLURMUTNOWOILOINOMSOOTORTOTSOULOURPEDPEWPINPITPOTSELSESSIETABTADTARTAYTEDTERTIRTOWTUDUGOUITWIM',
  BET: 'AILAINAKEALKASKEAREEMELLELSHELIDEIMEIREISEOILONEONSONYOOKORNOSSOYARAPRAYRIMSEYTASTEDTERTORULAWIT',
  BEU: 'LAHRRE',
  BEV: 'EILELSETOIESORS',
  BEW: 'AILAKEALLAREARYASHEEPENDEPTESTHIGIGSITHORKORMORNRAPRAY',
  BEY: 'DOMLICLIKOND',
  BEZ: 'ANTAZZELSILSOARZLE',
  BHA: 'BARDONGATJANKTAKTINGINGSRALRTIVAN',
  BHI: 'KKUNDISTI',
  BHO: 'KRAOSAOTSTIA',
  BHU: 'MIJNGITANTIA',
  BIA: 'CIDLISLYSNCANCOSEDSESXAL',
  BIB: 'BEDBERBLEIRIIRULESLICLOSLUS',
  BIC: 'ARBEPSHIRHOSKERONEORNRON',
  BID: 'DENDERDIEENEENSENTERSERYETSGETINGPAIREE',
  BIE: 'LBYLDSLDYLIDNLYNNENTTTLE',
  BIF: 'ACEARAFEDFINLEXOILOLDORM',
  BIG: 'AMYATEEYEGAHGEDGENGERGIEGINHTSLOTNOUOTSRAMWIG',
  BIH: 'ARI',
  BIJ: 'OUSOUX',
  BIK: 'ERSINGINIRAM',
  BIL: 'AANABEALOANDATIBIEBOABOSDARDEREVEGEDGESIFYITHKEDKERKISLEDLERLETLIELONLOTLOWLYOOBEOXI',
  BIM: 'AHSANAANEBILBOSEBYINIONG',
  BIN: 'ARYATEDERDISDLEFULGEEGESGEYGHIGLEGOSIOUITSMANMENNEDOCSODEOMYOUS',
  BIO: 'FOGGASGENMESNICNTSPICPSYRALSISTASTICTIN',
  BIP: 'ACKEDSODSONT',
  BIR: 'DEDDERDIEEMEGUSIBAKENKIELEDLERLESLIEODOOTAREDRUSSESSITSLETHSTHY',
  BIS: 'ALTCOTECTEXTHOPKOPLEYMARMERONSQUESONTERTRETROULCYNC',
  BIT: 'AKECHYERSINGMAPNETORESERTEDTENTERTIETORUME',
  BIU: 'REARET',
  BIV: 'ANEERBIALIUM',
  BIZ: 'ANTONE',
  BJO: 'RNE',
  BKB: 'NDR',
  BLA: 'BBYCKSCKYDEDDERDESGUEINEINSMEDMERMESNCANCHNCONCSNDANKSNKYREDRESRNYSHYSIASONSTSSTYTCHTTATTIVERWEDYNEZEDZERZESZON',
  BLE: 'ACHAKSAKYARSARYATSATYBBYEDSEPSERYEZEEZYNCHNDENDSNNYSSETHETIA',
  BLI: 'AUTCKYESTGHTMEYMPSNDSNISNKSNKYNTZTESTHETUM',
  BLO: 'ATSBBYCKSCKYKESLLYMANNDENDSODSODYOEYOIEOMSOMYOPSOTHSMYTCHTTOTTYUSEUSYWBYWENWERWIEWSEWSYWTHWUPWZEWZY',
  BLU: 'DGEELYESTESYETHETSEYSFFSFFYGGYINGISHISMMEAMEDMESNGENKSNTSRBSRRYRTSSHTSHY',
  BLY: 'PES',
  BOA: 'RDSRDYRTSSTSTEDTELTERTIETLY',
  BOB: 'BEDBERBIEBINBLECATFLYLET',
  BOC: 'AGECESCIACIECISHERHESHURKEY',
  BOD: 'ACHDLEEGAGERGIEICEIEDIERIESILYINGKENKINOCKONIRAG',
  BOE: 'ING',
  BOF: 'FINFOS',
  BOG: 'ACHANSARDARTEYSGEDGINGLEIERIESLEDLESLETMANONGOTAUEDWAY',
  BOH: 'AWNEASORAUNK',
  BOI: 'DAEGIDLEDLERTES',
  BOJ: 'ITE',
  BOK: 'ARDARK',
  BOL: 'DEDDENDERDINDLYDOSEROETEETIIDEITALEDLENLERLIXLOXOEDSHYSONTEDTELTERTIN',
  BOM: 'BAXBAYBEDBERBESBICBLEBUSBYX',
  BON: 'ACEACIAGHAIRANGASABONDARDEDDERDOCDONDUCEENERSETEGARGEDGOSIERIFYINGISMITAITOITYKEDNAZNESNETNIENNESAISERTEETOKXIEZERZES',
  BOO: 'BOODIEDLEGERGIEGUMHOOINGJUMKEDKERKIEKITKSYLEYLYAMAHMEDMERPICPISRGASTSTEDTEETERTESTHSTIDTIETLEZEDZERZES',
  BOP: 'EEPPEDPER',
  BOR: 'AGEAGOANAANEANIATEDARDELDERDUNEADEALEANEASEENELEERSEUSGHIIDEINEINGISHISMITHITYIZELEYNANNEONYLONSORORELROWSCHSHTUCAZOI',
  BOS: 'HASHERHESKERKETOMSOMYONSQUESEDSERSESSETTALTONUNS',
  BOT: 'ANYCHYEINELSETEFLYHERHIEONEONGONNONYTLETOM',
  BOU: 'BASBOUCANCHECLEDINFFEGARGEEGETGHSGHTGHYGIEKITLESLLENCENCYNDSNTYRGSRNERNSRRERSESEDSERSESTELTONTRE',
  BOV: 'ATEIDSINEOIDVER',
  BOW: 'BOYDENELSERSERYESSFININGKERLEDLEGLERLESLINMANMENPINPOTSAWSEDSERSESSIETELTIEWOWYER',
  BOX: 'CARERSFULIERINGMANTOP',
  BOY: 'ANGARDARSAUSAUXDOMISHISMLASUNA',
  BOZ: 'INE',
  BRA: 'ATACAECEDCERCESCHECKYCONCTSGASGERGGYGLYHMAHMIHMSHUIIDSIESILSINSINYIRDIROISEIZEKEDKERKESKIEMAHMIANCHNDINDSNDYNKSNKYNLENNYNTANTSROWSENSHYSILSSESSYTTYULAUNAVASVEDVERVESVOSWERWLSWLYWNSWNYYEDYERZASZEDZEEZENZERZESZIL',
  BRE: 'ACHADSAKSAMSASTATHCHTCIADESECHEDSEDYEKSEZEEZYGMAHONIRDKKYLANLAWMIANDANTSTONVESVETVISVITWEDWERWISWST',
  BRI: 'ARDARSARYBEDBEEBERBESBRICKSCKYDALDESDGEDIEDLEEFSERSERYEVEGGSGHTGIDGUELLSMLYMSENCENEDNERNESNGSNIENKSNNYONYQUESESSKSSKYTONTTS',
  BRO: 'ACHADSCHECHOCHTCKSDEEDIEGANGUEILSKENKERKESLGALLYMALMESMICMIDMINMOLMOSMUSNCONCSNZENZYOCHODSODYOKEOKSOKYOMSOMYOSESESSOTSSETANTELTHETHSTHYUGHUZEWEDWETWISWNSWNYWSEWST',
  BRU: 'ANGBRUCIACINGESGHSINSISEITSJASJOSLEELESLOTMALMBYMESMMYNCHNELNETNTSSHYTALTEDTESTUS',
  BRY: 'NZAONY',
  BUB: 'ALEALSBERBLEBLYOEDOES',
  BUC: 'AREAYOCALCANCINKEDKERKETKIEKLEKRA',
  BUD: 'DAHDEDDERDHADHIDIEDLEGEDGERGESGETGIELETUMAZAT',
  BUE: 'NASNOS',
  BUF: 'FEDFERFETFINFLEFOS',
  BUG: 'ALAARADOMEYEGEDGERLEDLERLESLETONGOUTSHA',
  BUI: 'BUICKSLDS',
  BUK: 'SHI',
  BUL: 'BARBEDBELBILBULBUSDERGARGEDGERGESGURIESIMYKEDKERKINLAELANLEDLERLETLITLOMTENTERTEYTOW',
  BUM: 'BEEBLEFEGKINMEDMELMERMIEMILMLEPEDPEEPERPSY',
  BUN: 'CALCHYCOSDERDLEDOCDTSGEDGEEGERGEYGFUGLEGOSIONKEDKERKIEKOSKUMNIASENTALTEDTERTONYAHYANYASYIP',
  BUO: 'YED',
  BUQ: 'SHA',
  BUR: 'ANSBLEBLYBOTDENDIEDONEAUELEELYETSGAUGEEGERGHSGLEGOOGULGUSHELIALIANIATIEDIERIESINSIONITIKEDKEIKERKESKHALAPLEDLERLETLEYMANNEDNERNETNIENUPNUTPEDRAHREDREERELRERRIOROSROWSAESALSARSASSCHSESSTSSTYTON',
  BUS: 'AOSBARBOYERAHEDHELHERHESHETHIEHWAIEDIERIESILYINEINGKEDKERKETKINKLEMANMENSEDSERSESTEDTEETERTICTLEWAY',
  BUT: 'ANECHAEINENEEOSINELEDLERLESOXYTALTEDTERTESTLETONUTSYLSYNE',
  BUX: 'INE',
  BUY: 'ERSINGOUT',
  BUZ: 'ANEUKIZEDZERZESZLE',
  BWA: 'NAS',
  BYB: 'LIS',
  BYE: 'LAWMAN',
  BYG: 'ANEONE',
  BYH: 'AND',
  BYL: 'ANDAWSINAINEINY',
  BYN: 'AME',
  BYP: 'ASSASTATHLAY',
  BYR: 'LAWLEDNIEOADRUS',
  BYS: 'SALSINSUS',
  BYT: 'ALKIME',
  BYW: 'ALKARDAYSORDORK',
  BYZ: 'ANT',
  CAB: 'AANACKAHOALAALLALSANAANEASABEDBERBIEBLEERSFULIAIINSIRILEDLERLESLETMANMENOBSOOKOTSREERETRIERITUJAURNUYA',
  CAC: 'ANAAOSARACIAHEDHESHETHOTHOUHUAKEDKLEOONTALTUS',
  CAD: 'BITDEDDIEDISDLEDOWEAUENTEREETSGEDGERGESISHISMJANMIAMICMUSOUKRESUACUCAUKEWAL',
  CAE: 'CALCUMLUMLUSOMASAR',
  CAF: 'ARDFLEFOYFREILATANUSO',
  CAG: 'EOTERSIERILYINGMAG',
  CAH: 'IERILLITAOOTOWS',
  CAI: 'LLEMANQUERBARDSRNSRNYSSETIF',
  CAJ: 'ANGAVAETAOLEUNS',
  CAK: 'IERILEING',
  CAL: 'ABAADEAISALUAMIASHCARCEDCESCHECICDENEANEFYESAGONICOIFSIGAIGOILIINAINEIPHITEKEDKERKINLANLASLATLEDLERLESLETLIDLOOLOPLOTLOWLUMLUSMEDMERMLYOOLORYPACPULQUETHAUSAVEDVERVESVINVUSXESYON',
  CAM: 'ACAAILAKAARAASSATABERBIABIODENELSEOSERAERYIASINOIONISAISEIZELETMASMEDOCAOISOTEPALPEDPERPHOPITPLEPOOPOSPUSUSE',
  CAN: 'AANABAADAALEALIALSAMOAPEARDARIARYAUTCANCELCERCHACHICRIDIDDILDLEDORDUCDYHDYSELAELLELOERSEVAFULGANGIAGLEGUEHAMIDSINEINGIONJACKERMANNASNATNEDNELNERNETNIENONNOTOEDOESONSOPYROYSOSTABTARTEDTELTERTHITICTILTLETONTORTOSTUSTUTUCKULAVASYONZONZOS',
  CAO: 'INE',
  CAP: 'ERSFULHARIASINGITAITEITOIVIKINLANLETLINMANOMOONEONSOTEPAEPEDPERPIEPLERICRIDRINRISRONRYLSIDTANTORUANULI',
  CAQ: 'UET',
  CAR: 'ACKACOACTAFEAHOAJAAJOANEANXAPAAPOARAATEATSBINBONBOYBROBYLCANCELCERDEDDELDERDIADINDOLDONEENEEREMEENEERSESSESTETSEYSFAXFULGOSHOPIANIBEIBIICAIDAIEDIENIESINAINGIRIISAITYIYOKEDLESLETLIELINLOSLOTMANMELMENMOTNACNALNELNETNEYNICNIEOBAOBSOCHOIDOLEOLIOLSOMSONEOONPALPEDPELPERPETPIDPOSPUSRATREERELRIEROMROTROWRUSRYSSESSONTEDTELTERTESTONTOPVALVEDVELVENVERVESVOLVYLZEY',
  CAS: 'ABAABEATEAUNAVAAVEAVIBAHCANCOLEFYEICEINERNEUMHAWHEDHELHERHESHEWHOOHOUINAINGINOIRIITAKEDKETLONPARPERQUESANSHESIASIESISSONTEDTENTERTESTLETORTRATROUALULAULE',
  CAT: 'ALOCHYDOMENAERSERYEYEGUTHARHAYHINHOPHROHUDINGIONIVOKINLAPLINNAPNEPNIPSOSSUPTANTEDTERTIETLE',
  CAU: 'CHOCUSDADDAEDALDEXDLEFLEGHTKEDLDSLESLISLKSNCHNOSNUSQUIRUSSAESALSEDSERSESSEYSONSSESUSTELTERTIO',
  CAV: 'ATEDIAEAEEATERNERSIARIESILSINAINGISHITYIYAORT',
  CAW: 'INGKERNEYNIE',
  CAX: 'IRITON',
  CAY: 'APAAPOMANUCAUCOUGAUSE',
  CAZ: 'IBIIMI',
  CEA: 'RINSEDSES',
  CEB: 'ELLIANIDSINEOID',
  CEC: 'ILEILSILYITYUMS',
  CED: 'ARNARSARYENSENTERSINGRATRICRINROLRONRUSULAULE',
  CEI: 'BASBOSLEDLERNTE',
  CEL: 'EBEEBSERYIACITELAELARLEDLOSOMSSIATICTISURE',
  CEM: 'ENT',
  CEN: 'DREIZOOBEOBYOTESEDSERSESSORSUSTAITALTASTERTONTOSTRATRETROTRYTUM',
  CEO: 'RLS',
  CEP: 'HASHENHIDHUSOUSTERTOR',
  CER: 'AGOATAATECALCISCLECUSEALEUSEZAIASIDEINEINGIONIPHISEITEIUMMETNEDOIDOMAOONOTEOUSRISTESTIETIFTISULEUSEVIDVIXVUS',
  CES: 'AREIUMSEDSERSESSIOSORTASTOITONTOSTUITUSTUYURAURE',
  CET: 'ANEENEERA',
  CEV: 'IANINE',
  CEY: 'LON',
  CHA: 'BERBOTBUKCMACRACTECUNDARDORDRIETAFEDFERFESFFSFFYGALGANGGAGULHARINEINSIRSISEKARKRAKSILAHLEHLETLKSLKYLLALLYLONLOTLTAMALMARMMAMMYMOSMPAMPEMPSMPYNCANCENCONCYNDINDUNGANGENGSNSTNTSNTYORIOUAPAHPEDPELPESPINPONPPEPPYRACRASRCARCORDSREDRERRESRETRGERKARKSRMSRNURONRRERRORRSRRYRTARTSRUKSEDSERSESSIDSMASMSSMYSSESTESTYTONTOTTTATTITTYULEUNAUNTURIUTEUTHUVEVELVERWANWEDWERWIAWLEYMAZAN',
  CHE: 'APOAPSAREATSBECBELBOGCKECKSCKYDARDEREKSEKYEPSEPYEROERSERYESEESYGOEGREKANKENLAELASLEMLLOLYSMICMINMISMMYNARNDENETNEYQUEQUYREMRIERNARRYRTERTSRTYRUBRUPSILSONSTSSTYTAHTHSTIFTTYUNGVALVEEVENVETVINVONVREVVYWEDWERWETWIE',
  CHI: 'ACKASMAUSAVEBOLCERCHACHICKSCKYCLECLYCOSCOTDEDDERDESDRAEFSELDELSEVEGGAGOEHFUKEELDELESLLALLILLOLLSLLYLTEMARMBEMBSMEDMERMESMINMLAMPSNARNASNCHNDINEDNEENESNIKNINNKSNKYNNYNOANOLNOSNSENTSNTZPPYRALRKSRMSRONROSRPSRPYRRERRSSELTAKTALTHETINTONTRATTYULEURMVERVESVEYVVY',
  CHK: 'FIL',
  CHL: 'EUHOREORO',
  CHO: 'ANAATEATYBIECHOCKSGAKIAKICEICYILEIRSISEKEDKERKESKEYKRALAMLEELERLICLLALOSLUMMERMPSNTAOKYORAOSEOSYPASPINPPYRAIRALRDARDSREAREDREEREIRESRGIRICRIORTIRUSSENSESTTSUANUGHUKAULEUSEUSHWEDWRYWSEZAR',
  CHR: 'ISMISTOMAOMEOMOOMY',
  CHT: 'EAU',
  CHU: 'ANABBYCKSCKYDICETAFASFFSFFYHRAKARKKAKORLANLHALLOLPAMARMMYMPAMPSMPYNAMNGANKSNKYPAKPONRCHRELRLSRLYRNSRRORRSSERTEDTERTESZWI',
  CHW: 'ANA',
  CHY: 'ACKLESMESMIAMICPRETRA',
  CIB: 'OLAOLSORY',
  CIC: 'ADAALAALEELYEROHARONEUTA',
  CID: 'ERS',
  CIE: 'RGERZO',
  CIG: 'ALAALEARS',
  CIL: 'ERYICEIUM',
  CIM: 'BALBIABRIIERNEL',
  CIN: 'CHADERDIEEMAENEEOLGLENYLQUETERTREURA',
  CIP: 'AYEHERPUS',
  CIR: 'CARCLECUECUMCUSCUTQUERUS',
  CIS: 'COSELEINGIUMSUSTAETEDTICTUS',
  CIT: 'ERSESSHERIEDIESIFYINGOLAOLERALRICRILRINRONRULRUS',
  CIU: 'DAD',
  CIV: 'ETSICSIESILEISMITEORY',
  CIW: 'IES',
  CIX: 'IID',
  CLA: 'CHSCKSDUSGGYIMSIREITHMERMMYMORMPSNGSNKSPPEQUERESRETRINRKEROSRRERTSRTYSHYSPSSPTSSYSTSTCHTTYUDEUSEVAEVALVELVERVESVISVUSWEDWERXONYEDYENYERYEY',
  CLE: 'ACHANSARSATSAVECHECHYDDEDGEDGYECHEKSEKYFTSNCHOIDOMEPEDPESRGYRICRIDRKSRUMRUSTCHUCHUKSVERVISWED',
  CLI: 'ACKCHECKSCKYENTFFSFFYFTSFTYMAXMBSMESNAHNALNCHNESNGSNGYNIANICNIDNKSNTSNTYONAONEPEIPSEQUEQUYTCHTESTHETIATICVALVERVIAVISVUS',
  CLO: 'ACAAKSCHECKSDDYGGYINEMPSNALNEDNERNESNICNKSNOSNUSOTSQUESEDSENSERSESSETSKYTHETHOTHSTHYTTYUDSUDYUEEUGHURSUTSUTYVENVERVESWERWNSWREYEDYERYNE',
  CLU: 'BBYCKSCKYINGMPSMPYMSEMSYNCHNKSPEAPPESIATCH',
  CLY: 'ERSPEISESSISSMA',
  CNE: 'MICMIS',
  CNI: 'CINCUSDAE',
  COA: 'CHSCHYCTSGEDGELITAKUMLASLEDLERPTSRCTRSESTSTEDTEETERTIETISXALXEDXERXES',
  COB: 'AEAALTANGBEDBERBINBLEBLYBRACABEGOIASLESNUTOLAOSSRASURGWEB',
  COC: 'AINAMAASHCALCICCIDCINCUSCYXHALHERHINHONKALKEDKERKETKIEKLEKLYKSYKUPLEAOASONAOONUYO',
  COD: 'DEDDERDLEECSEIAEINENSERSGERIFYINGISTIUMLINMANONSRUS',
  COE: 'CALCUMDITLARLHOLIALINLOMMPTNLARCETUSVAL',
  COF: 'ANEFEAFEEFERFINFLE',
  COG: 'ENTGEDGERGIEGLEGLYHLEIDAITOMANMENNACONSWAY',
  COH: 'EADEIRENSEREERTOBAOGSORNORTOSHOSTUNE',
  COI: 'FEDFFEGNEGNSGNYGUELEDLERNEDNERSNSTALTUS',
  COJ: 'OIN',
  COK: 'ERSERYING',
  COL: 'ADAAGEANEATEDERDLYEENENTERAEUREUSFOXIASICSIESIMAINGINSIUSLABLARLATLENLETLEYLIELINLISLOPLOQLOWLUMLUNLUTLYRMAROGSOLAOMBONIONSONYORSORYOSSOUROVEPEOPUSTERUGOUMNUREYBAYUMZAS',
  COM: 'ADEAKEALSARTATEBATBEDBERBESBLEBOSBOYBREDIAEDOEDYELYERSETHETSFITICSIDAINGINOISMITEITYMASMEMMIEMISMITMIXMONMOSMOTODOOIDOSEOUSPAAPARPEDPELPLYPOSPOTPTEPTSSATTESVIA',
  CON: 'ANDANTCHACHECHOCHSCHYCIOCURDERDOGDOMDORDOSDUEEENEYSFABFEDFERFITFIXGASGEDGEEGERGESGIIGOSGOUICSIESIMAINEINGINSITEIUMJEEJONKEDKERNEDNERNEXNIEOIDRADREDREYSOLSTRSULTAMTEKTERTESTEXTINTOSTRAULEUREVEXVEYVOYYZA',
  COO: 'BAHBOODLEEEDEESERSEYSHEEINGKEDKEEKERKEYKIELEDLENLERLEYLIELLYLTHMBEMBSNERPEDPEEPERPTSREERIESERSUCTCHTERTIE',
  COP: 'AINALMALSARTECKEIAENSERSIEDIERIESINGISTITALOTOUTPASPEDPERPETPINPLEPRARAHRASSESTERTICTISULA',
  COQ: 'UETUIN',
  COR: 'ACHAGEAJIALSBANBEDBELBETBIECIRCLEDALDAXDEDDELDERDIADISDONDYLEIDEMAERSGISIALIININGITAIUMIXAKEDKERKIRMACMELMUSNEANEDNELNERNETNICNINNIXNUANUSODYOJOOLLONAONEOPOOUNOZOPSEPSYPUSRALREARELREORIERUPSACSAKSERSESSETSIESOSTESTEXTEZTINTONUCOVEDVEEVENVERVESVETVUSYMBYPHYZA',
  COS: 'CETEATECHECSETSEYSHEDHERHESIERIESIGNILYINEINGINSMICMOSSASSETSICSIDSIETAETALTARTEDTERTLY',
  COT: 'ANSEAUEENELEERYHAMHONICEINGISEMANOINOROOXOSETTAETARTASTEDTERTIDTONTUSUITULAWALWINYLAYLEYPE',
  COU: 'CALCHECHYDEEGARGHSLDNLEELIELISNTSNTYPEDPEEPERPESPLEPONRAPRBERGERIERILRSERSYRTSRTYSINTELTERTETTHETHSTHYTILXIAXIO',
  COV: 'ADOARYENSENTERSERTETSEYSIDOINEINGITE',
  COW: 'AGEARDBOYDIEEENERSIERINGISHLEDMANMENPATPEAPENPERPOXRIESON',
  COX: 'IERINGITE',
  COY: 'DOGESTINGISHNYEOTEPOUPUSURE',
  COZ: 'ENSEYSIERIESILYINGZES',
  CRA: 'ALSBBYBERBITBUTCCACKSCKYDDYDGEDLEFTSFTYGGYKEDKERKESKOWMBEMBOMELMPSMPYNCENCHNEDNERNESNETNEYNIANICNKSNKYNNYNTSPEDPESPONPPOPPYPWASESSISTCHTEDTERTESTONVATVEDVENVERVESWLSWLYYERYONZEDZES',
  CRE: 'ACHAGHAKSAKYAMSAMYANTASEASYATEAZECHEDALDITDOSEDSEKSEKYELSEPSEPYESEESHMESMORNAENELNICOLEPEDPESPEYPISPLYPONSOLSSYSTSSYLTANTICTINVETVISWEDWELWERWET',
  CRI: 'ANTBLECKECKSERSKEYMEAMESMMYMPSMPYNALNCHNEDNELNETNGENIDNUMPESSESSICSISSLESPSSPYSSASTASTISTYTCHTIC',
  CRO: 'AKSAKYAPECHECINCKOCKSCKYCUSFTSIIKISEJIKKERMERMMENELNESNETNIENUSOCHOKSONSOSEPPAPPYRESSBYSETSNESSETALTCHTINTONTYLUCHUKEUPEUPSUPYUSEUTEUTHWDSWDYWEDWERWNSZEDZERZESZLE',
  CRU: 'CESCHECISDDYDERDESDLEELSENTETSETYISEIVEMBSMBYMENMMYMPSMPYNCHORSRALSCASESSETSIESTASTSSTYTCHXES',
  CRW: 'THS',
  CRY: 'INGPTAPTOPTSSTE',
  CSE: 'CTS',
  CTE: 'NII',
  CUA: 'DRARTARTO',
  CUB: 'AGEANEANSBYUDOMEBSERAERSICAICSINGISMISTITIITOITSOID',
  CUC: 'HANHIAKOOULEULIURBUYO',
  CUD: 'AVADENDIEDLEDLYGEL',
  CUE: 'CASINGISTMANMENRDARPOSTA',
  CUF: 'FEDFERFINFLE',
  CUI: 'EJONFORIESSETLE',
  CUL: 'BUTDEEETSETTEUSGEELASLAYLEDLENLERLETLISMEDMENPAEPASPEOPONTCHTERTICTUSVER',
  CUM: 'ARAARUBERBHABLEBLYBREENEHALINSMERMINSHAULI',
  CUN: 'DUMEALEUSILAILIJAHJERNERYIEZIE',
  CUO: 'RIN',
  CUP: 'ELSFULHEAIDSMANOLAPASPEDPENPERPINRICRUMULAULE',
  CUR: 'ACEACYAGEAGHARAAREARIATEBEDBERCASCHYDEDDLEDLYDOOERSETSFEWIAEIALIAMIESIETINEINGIOSITEIUMLEDLERLEWNEYNIEPELPINPLERANREDRIESALSEDSENSERSESSORSUSTALTEDTERTISTLYTSYUBAULEUROVALVEDVERVESVETVEYVLE',
  CUS: 'CUSECSHAGHATHAWHIEPALPEDPIDPISSEDSERSESSOSTOMTOS',
  CUT: 'CHAELYESTESYEYSIESIFYINSLASLERLETOFFOSEOUTSETTEDTERTLETOETOOUPSWAL',
  CUV: 'AGEIES',
  CWI: 'ERC',
  CWR: 'ITE',
  CYA: 'MIDMUSNEANICNIDNINNOLNUSTHI',
  CYB: 'ELEORG',
  CYC: 'ADSLARLASLEDLERLESLICLOPLOSLUS',
  CYD: 'ERS',
  CYE: 'SESSISTIC',
  CYG: 'NETNIDNUS',
  CYM: 'ARSBALBELBIDENELINOIDOLSOSEOUSRICTIAULE',
  CYN: 'ARAIASICSIPSISMOID',
  CYP: 'HERRESRIARIDRISRUS',
  CYR: 'ANO',
  CYS: 'TALTEDTICTIDTINTIS',
  CYT: 'ASEODEOIDOMAOMEONEONSOSTULA',
  CYW: 'YDD',
  CZA: 'RIC',
  CZE: 'CHS',
  DAB: 'BEDBERBLELETOIAOYA',
  DAC: 'ELOHASIANITEKEROITRONRYARYDTYL',
  DAD: 'DERDLEINGOEDOES',
  DAE: 'DALKONMON',
  DAF: 'FEDFLETARTERTLY',
  DAG: 'ABAAMEESHGARGEDGERGLEGLYMAROBAOES',
  DAH: 'LIALINOON',
  DAI: 'DLEDLYKERKONMENMIOMONMYONTYSEDSEESESTYA',
  DAK: 'HMAOITOTA',
  DAL: 'AGAASIEDHETHLANLASLESLISLOPTON',
  DAM: 'AGEANSARAARSASKIERINEMARMEDMERMITNEDNERNIINITNUMONEPEDPENPERPLYPNESELSON',
  DAN: 'AANAIDAISAROCEDCERCESDERDIEDISDLEGEDGERGLEIANIELIOSISHISMITEIZEKERKLYNERNIETONUBEURIZIGZON',
  DAO: 'INE',
  DAP: 'HNEHNIICOPEDPERPLESON',
  DAR: 'BHADANDICERSGAHGERGUEICSIENINGIUSKEDKENKERKEYKIEKLEKLYNEDNELNERNEXNIXOGARENRYLTEDTERTLETOSTREVONWANWINZEE',
  DAS: 'EINEWEHEDHEEHELHERHESSIETURWENYUS',
  DAT: 'ANAARYCHAERSINGIONISIISMIVESUNTOSUMSURA',
  DAU: 'BEDBERBESBRYCUSDEDDITKINNCHNCYNERNIINTSRNATEDTIE',
  DAV: 'ACHENSIESITSOCHYNEYUM',
  DAW: 'DLEINGISHKINNEDSONTEDTETTIETIT',
  DAY: 'BEDBOYFLYINGLITMANMENNETTON',
  DAZ: 'INGZLE',
  DCL: 'ASS',
  DDN: 'AME',
  DEA: 'CONDENDERDLYFENFERFLYIRSLERNEDNERRERRIERLYRTHSILTHSTHYVEDVES',
  DEB: 'ARKARSASEATEBIECLEEAKELLILEINDITEITSLAIONEORDOSHOSSOUTRISTEDTEETORUGSUNKURRUTSYES',
  DEC: 'ADEADIALSAMPANEANIANTARDAREARTASTATEAYSEDEEITENEENTERNERPESSIANIDEILEIMAIMEINEISEIUSKEDKELKENKERKIEKLENETOATOCTODEOICOKEOLLOREORSOYSREEREWURTURYUSSYNE',
  DED: 'ANSUCEUCTUIT',
  DEE: 'DEDJAYMEDMERMIENERPENPERPLYSESSISVEYWAN',
  DEF: 'ACEADEAILALKAMEAMYANGATSEATECTEITENDERSIALIEDIERIESILEINELEALEXLOWLUXOAMOGSOILORMOULRAYTERTLYUSEUZE',
  DEG: 'AGEAMEAMIASSERMGEDGERLUTOLDRASREEUMSUST',
  DEH: 'AIRGANKANORNORSORTULLUSKWAR',
  DEI: 'CEDCERCESFICGNSMOSNOSRIDSINSMSSTSXIS',
  DEJ: 'ECT',
  DEK: 'AREINGKOS',
  DEL: 'ACEATEAWNAYSEADETEFTSIANICEICTIESIMEINEINTIREISKISTOULTALTASTICUCEUDEUGEUXEVEDVERVES',
  DEM: 'AINANDARKASTEANELEENTIESIOXISEISSISTITSOBSODEOIDONSOTEOVEPNEUREURS',
  DEN: 'AMEARIAROARYDRAGUEIALIEDIERIESIMSIZENEDNETNISOTESENSERSUSTALTEDTELTERTESTEXTILTINUDEVERYER',
  DEO: 'DAR',
  DEP: 'AIRARKARTASSENDERMICTLOYONEORTOSEOTSSIDTHSUREUTEUTY',
  DER: 'AILATEATSAYSEREFLYHAMIDEIVEMADMALMASMICMISMOLNEDNERNLYOBEOUTRIDRISTRAUST',
  DES: 'ALTANDCRYEAMEEDERTIGNILTIREISTIZEMANMICMIDMONOLEORBPINPOTRAYSILSUSTINTRYUME',
  DET: 'ACHAILAINANTECTENTENUERMERSESTICKORTOURRAYUNEURBURN',
  DEU: 'CEDCESNAMSANTONZAN',
  DEV: 'ALLANTASTATAAULEINELSESTICEIDEILSILYISEOIDOIRONSOTAOTEOTOOUROUTOVEVEL',
  DEW: 'ANIANSATACAPCUPIERILYINGITTLAPOOLORMRETROTTRY',
  DEX: 'IESTERTRO',
  DEZ: 'INC',
  DFA: 'ULT',
  DHA: 'MANMMANUKRMARNAURAURI',
  DHE: 'NEB',
  DHI: 'KRS',
  DHO: 'BEEBEYBIEBISLESNEYOLYORAOTITEETIS',
  DHU: 'RNARRARRYTIS',
  DHY: 'ANA',
  DIA: 'BLEBLOCIDCLEDEMDICENELEDLERLINLOGLUPMATMINMYLNILPERPIRRCHTOMULIXONZIDZIN',
  DIB: 'ASEBEDBERBLEBUKROM',
  DIC: 'ASTCONERSHASIERINGKERKEYKIEKTYOTSTICTUMYAN',
  DID: 'ACTDERDLEESTIESINELEROESRICUCE',
  DIE: 'INGLECNERNESSELSESSISTALTEDTERTIC',
  DIF: 'FER',
  DIG: 'AMYENYESTGEDGERHTSITSLOTRAM',
  DIH: 'ALOELY',
  DII: 'AMBODO',
  DIK: 'AGEASTDIKERSETOINGKOPSHATAT',
  DIL: 'ATEDOEDOSEMIKERLISLUEOGYUTEUVY',
  DIM: 'ANEBERBLEERAERSISSITYMEDMERMETMITOUTPLEPLYPSYWIT',
  DIN: 'ARSDERDLEDONEROERSGARGEDGEEGERGEYGHYGLEGLYGUSINGKEDKEYKLYKUMMANNEDNERTED',
  DIO: 'BOLDESDIADONECYNYMSMATICXANXIDXIN',
  DIP: 'ALALEXLOENOIODEODYOLEPEDPERPPYSASSEYSIESOSTERYGIYRE',
  DIQ: 'UAT',
  DIR: 'DUMECTELYESTGEDGESGIEHAMHEMIANIGEIGOITYKEDLEDNDLTEN',
  DIS: 'ARDARMAWAAZOBARBUDCALCEDCOSCUSDARDUBEMEERTEURFENGIGHEDHERHESKEDKERKOSLIPMALMANMAWMAYMESMITNEWNEYORBOUROWNPARPELPLERAYSUETADTALTERTILUSEWIT',
  DIT: 'ALIHERINGIONONETAYTEDTONTOS',
  DIU: 'RNARNERON',
  DIV: 'ANSASTATAELLERBERSERTESTIDEINEINGISAISEISIORTOTOOTS',
  DIW: 'ANIANSATA',
  DIX: 'AINIESITS',
  DIZ: 'AINDARENSOICZEN',
  DJE: 'BELHADLABLFARIBRSA',
  DJI: 'NNINNSNNY',
  DOA: 'BLETEDTER',
  DOB: 'BEDBERBIEBINIESLASLONRAORASSONULE',
  DOC: 'ENTILEITYKEDKENKERKETMACTORTUS',
  DOD: 'DEDDERDIEDLEGEDGERGESKINLETMANOESOMAONAUNK',
  DOE: 'SNT',
  DOF: 'FEDFER',
  DOG: 'ANAATEDOMEAREYSGEDGERGETGIEGLEIESLEGMANMASMENNAPRIBTIE',
  DOH: 'TER',
  DOI: 'GTELEDNGSTED',
  DOK: 'HMA',
  DOL: 'CANENTESSINAINEINGITEIUMLARLEDLEYLIALIELINLOPMANMASMENORSOSEOUROUSVEN',
  DOM: 'AGEAINBOCENTETTIFYINAINEINGINIINOITENEIOID',
  DON: 'ACKALDARYATEDIAEESGONJONKEYMEHNASNEDNEENIENOTORSSIESKYUTSZEL',
  DOO: 'COTDABDADDAHDIADLEKETKITLEELEYLFULIEMEDMERRBAREDVERZER',
  DOP: 'ANTERSIERINGPEDPERPIAPIO',
  DOR: 'ADOASKBELBIEBUGCASIANIESINEISMIZELOTMERMIEMINNICOBOPERSADSALSELSERSUMTERUCK',
  DOS: 'ADHAGEAINERSINGSALSEDSELSERSESSIL',
  DOT: 'AGEANTARDATEERSHERIERINGISHKINLETTEDTELTERTLE',
  DOU: 'ANEBLEBLYBTSBTYCETCHECINDLEGHSGHTGHYLCEMASPERRAHRASRERRLYSEDSERSESTER',
  DOV: 'ENSISH',
  DOW: 'AGECETELSERSERYILYINGLASNBYNEDNERSEDSERSESSET',
  DOX: 'IES',
  DOY: 'ENSLEY',
  DOZ: 'ENSENTERSIERILYINGZLE',
  DRA: 'BBYBLYCHMCINCMADGEFFSFFYFTSFTYGEEGGYGONILSINEINSKESMASMMEPEDPERPESPETVYAWEEWERWLSWLYYEDZEL',
  DRE: 'ADSAMSAMTAMYARYCHECKSDGEEGHEPYGGYICHIDLIGHINTNCHNGHSSYTCHVELYNT',
  DRI: 'ECHEGHERSESTFTSFTYLLSMYSNKSNKYPPYVELVENVERVES',
  DRO: 'GERGUEICHITSKPALLSLLYMEDMICMOIMONMOSNEDNELNERNESNETNGONTEOLSOLYOPSOPTOPYPAXPPYPSYSKYSSYUKSUMYUTHVEDVERVESWNDWNSWSEWSYWTE',
  DRU: 'BLYDGEERYGGEGGYIDSITHKPAMLYMMYNKSPALPELPESSEDSESXEY',
  DRY: 'ADSERSESTFATINGISHLOTOPEOPSROTSNE',
  DSE: 'CTS',
  DSN: 'AME',
  DTE: 'NTE',
  DUA: 'DICLINLLYRCH',
  DUB: 'ASHBAHBEDBEHBERBINLIN',
  DUC: 'APEATOATSHANKEDKERKIETALTEDTORTUSULA',
  DUD: 'AIMDERDIEDLEEENGENINEISHISMLERLEYMAN',
  DUE: 'FULLEDLERLLILLONASNDENNASSATTO',
  DUF: 'FEDFELFERFLEOILTERTRY',
  DUG: 'DUGONGOUTWAY',
  DUI: 'KER',
  DUK: 'ELYERYKERKHAUMA',
  DUL: 'CETCIDCINCORIASLEDLERSESTIEUTH',
  DUM: 'BEDBERBLEBLYDUMMELOSEOUSPEDPERPLEPTY',
  DUN: 'AIRCANCESDEEDERGANGASGEDGERGOLGONITEKEDKERKLELAPLINLOPNEDNERTEDTERTLE',
  DUO: 'LOGMOSPOD',
  DUP: 'ERSERYINGIONLETLEXLONPEDPER',
  DUR: 'AINANIANTBANBARDUMENEESSGAHGANGENHAMIANINGIONITYNEDOCSOUSRASRIERINUMSWANZEE',
  DUS: 'ACKCLEKEDKENKLYTEDTEETERTINTUKTUP',
  DUT: 'CHYIEDIES',
  DUY: 'KER',
  DVA: 'ITA',
  DVO: 'RAK',
  DWA: 'RFSRFYYNE',
  DWE: 'LLS',
  DWI: 'GHTNEDNES',
  DYA: 'BLEDIC',
  DYB: 'BUK',
  DYE: 'ING',
  DYI: 'NGS',
  DYK: 'AGEING',
  DYN: 'AMOASTODE',
  DYS: 'URY',
  DYV: 'OUR',
  DZE: 'RENRINRON',
  EAD: 'IOSISH',
  EAG: 'ERSLEDLESLETRES',
  EAN: 'ING',
  EAR: 'BOBCAPFULINGLAPLETNEDNERNIEOCKTABTAGTHSTHYWAXWIG',
  EAS: 'ELSERSIERIESILYINGSELTEDTERTRE',
  EAT: 'AGECHEERSERYHLYING',
  EBB: 'ETSINGMAN',
  EBC: 'ASCDIC',
  EBU: 'LUSRINRNA',
  ECA: 'NDARTE',
  ECB: 'OLE',
  ECC: 'LES',
  ECE: 'SICSIS',
  ECH: 'ARDINGINIIUMOEDOEROESOEYOICUCA',
  ECI: 'TON',
  ECL: 'AIRATSEGM',
  ECO: 'LESRCHUTE',
  ECR: 'ASE',
  ECT: 'ENEOPYYPE',
  ECU: 'RIE',
  ECZ: 'EMA',
  EDD: 'AICIEDIESISHOES',
  EDE: 'MASMICNIC',
  EDG: 'ERSIERILYINGREWROW',
  EDI: 'BLECTSLESSONTALTEDTOR',
  EDM: 'ONDUND',
  EDP: 'LOT',
  EDU: 'CEDCESCTS',
  EDW: 'ARDINA',
  EEB: 'REE',
  EEL: 'BOBERYIERINGPOT',
  EER: 'IERILYOCK',
  EES: 'OME',
  EEY: 'UCHUCK',
  EFE: 'CKS',
  EFF: 'ACEAREATEECTEIRETEIGYLUXORDORMORTRAYUDEUMEUNDUSEUSO',
  EFR: 'EET',
  EFT: 'EST',
  EGA: 'LLYRDS',
  EGB: 'ERT',
  EGE: 'NCENCYRANRIASTASTS',
  EGG: 'ARSCUPERSHOTINGLERNOG',
  EGI: 'PTOSES',
  EGL: 'ING',
  EGO: 'ISMISTITYIZE',
  EGR: 'ESSETSIOT',
  EHL: 'ITE',
  EHR: 'MAN',
  EHU: 'AWA',
  EID: 'ENTERSOLA',
  EIF: 'FEL',
  EIG: 'HTHHTSHTY',
  EIK: 'ONS',
  EIL: 'EEN',
  EIR: 'ACKENE',
  EIS: 'ELL',
  EIT: 'HER',
  EJE: 'CTACTS',
  EJI: 'DALDOS',
  EKH: 'IMI',
  EKT: 'ENE',
  ELA: 'BOREISINEINSNCENDSNETNUSPHEPIDPSETEDTERTESTHATOR',
  ELB: 'ERTOICOWSOWYUCK',
  ELC: 'AJAHEE',
  ELD: 'ERNERSESTINGRED',
  ELE: 'CHICTOCTSGITMINMISMOLNCHNGEUINVENVON',
  ELF: 'DOMINSISHKIN',
  ELI: 'CITDEDDESJAHNORSHASORSSATESXEDXIR',
  ELK: 'DOMUMA',
  ELL: 'ECKICEICKINGIOTOPS',
  ELM: 'IER',
  ELO: 'DEADESHIMIGNINEINSISEPEDPERPES',
  ELR: 'AGE',
  ELS: 'HIN',
  ELT: 'IMEROT',
  ELU: 'ANTATECIDDEDDERDESENTTEDTESTORVIA',
  ELV: 'ERSIRAISH',
  ELW: 'OOD',
  ELY: 'MUSSEESIATRA',
  EMB: 'ACEAINALEALLALMANKARKARSASEAYSDENEAMEDSERSIIDINDIRALEMLICODYOILOLEOLIOLOOLYOSKOSSOSTOWLOWSREWRUERYOUIAULKULLUSHUSKUSY',
  EMC: 'EEDEES',
  EME: 'ERSNDSRASRGERILRITRODRSESESSISTIATICTINUTE',
  EMI: 'GRELIASSI',
  EMM: 'ERSETSETTIESOVE',
  EMO: 'DINLOATEDTERTES',
  EMP: 'AIRALEALLARKARLASMERYESTEXAIREIRYLOYORYTIOTORUSA',
  EMR: 'AUDODE',
  EMU: 'LGENCTNGE',
  EMY: 'DEADES',
  ENA: 'BLECTSENAJIMLIDMELMORRCHRMETESTIC',
  ENB: 'IBELOC',
  ENC: 'AGEAKEAMPASEASHAVEELLINAISTLOGODEOILOMYOOPOREOWLURLYCLYST',
  END: 'ARKAZEEAREBTENTEREERSICTINGITEIVEOMEOREOSSOWSRINUEDUESURAUREUROYMA',
  ENE: 'MASRGYRVEUCHUGH',
  ENF: 'ACEANTECTIEFILEIREIRMOILOLDORKORMORTOULRAIREEUME',
  ENG: 'AGEAOLARBAUDAZEHLEILDINEIRDIRTLADLERLUELUTLYNOBEOLDOREOUERAMRIDULF',
  ENH: 'ALOELMORTUSK',
  ENI: 'GMAGUASLE',
  ENJ: 'AILAMBOINOYS',
  ENK: 'IDU',
  ENL: 'ACEARDEAFEENIEFIFEIMNINKISTIVEOCKUREUTE',
  ENM: 'ASKASSESHISTITYOSSOVE',
  ENN: 'AGEEADOICUISUYE',
  ENO: 'DALINTLICPLASISUGH',
  ENR: 'ACEAGEAILANKAPTICHINGIVEOBEOLLOLSOOTUIN',
  ENS: 'AFEANDATEEALEAMEAREATEELEEMERFETEIGNILENOWOULTARUEDUERUESURE',
  ENT: 'ACHADAAILAMEERAERSETEICEIERIESIFYIREITYOILOMBONEOURRAPRECREEREPRERREZRIAUNE',
  ENU: 'KKIREDRESRNY',
  ENV: 'AYEEILIEDIERIESINEIREOISOYS',
  ENW: 'ALLINDINGOMBOODOVERAP',
  ENZ: 'ONEYMEYMS',
  EOC: 'ENE',
  EOG: 'AEA',
  EOI: 'THS',
  EOL: 'IANITH',
  EON: 'IANISM',
  EOS: 'ATEIDEINEINS',
  EOZ: 'OICOON',
  EPA: 'CMECTSRCHULE',
  EPE: 'IRANLARUARVA',
  EPH: 'AHSEBEEBIETEODSORIORSYRA',
  EPI: 'CALCLYDIAGEEGNEGONKIALOGMERROTTHITRAZOA',
  EPO: 'CHACHECHSDESDICISTNGENYMPEESES',
  EPR: 'ISEOSY',
  EPU: 'LISRAL',
  EQU: 'ALSANTATEINEIPSIPTITYOID',
  ERA: 'SEDSERSES',
  ERB: 'IUM',
  ERE: 'BUSCTSMICNOW',
  ERG: 'ANEATEOTS',
  ERI: 'CADCALCASNGONYS',
  ERM: 'ANIINE',
  ERN: 'EST',
  ERO: 'DEDDESSESTIC',
  ERR: 'ANDANTATAINGITEORSSYN',
  ERS: 'ATZ',
  ERT: 'HENHLY',
  ERU: 'CICCINCTSDITGOSPTS',
  ERV: 'ILS',
  ERY: 'NGOOPS',
  ESC: 'APEARPARSHARHELHEWOBAORTOTSOUTRODROLROWUDO',
  ESD: 'RAS',
  ESE: 'RIN',
  ESK: 'ARSERSIMO',
  ESN: 'ECY',
  ESO: 'DICPGIPUS',
  ESP: 'ACEADAAVEECEIALIEDIERIESINORIT',
  ESR: 'OGS',
  ESS: 'ANGARTAYSEDAEDEENEERAIVEOIN',
  EST: 'ADOAFAALLAMPANGATEATSEEMERSHERIVEOCSOILOPSRALRAYRIFRINRUMRUSUDYUFAURE',
  ETA: 'GRELONMINPES',
  ETC: 'HEDHERHES',
  ETE: 'RNE',
  ETH: 'ANEENEERSICSIDEINEIONIOPIZENALNICNOGNOLNOSOXYROGYLSYNE',
  ETN: 'EAN',
  ETO: 'FFEILE',
  ETR: 'IEROGS',
  ETT: 'LED',
  ETU: 'DESVEE',
  ETW: 'EESITE',
  ETY: 'MICMOLMONPIC',
  EUB: 'OIC',
  EUC: 'GIAHRELEALIDONE',
  EUD: 'EVEISTORA',
  EUG: 'ENEENY',
  EUL: 'IMAOGY',
  EUN: 'DEMICEOMYUCH',
  EUO: 'DICNYMUAE',
  EUP: 'HONIONNEA',
  EUR: 'EKAIPIITEOPAOPEOUSYONYTE',
  EUT: 'AXYONY',
  EUV: 'ROU',
  EUX: 'INE',
  EVA: 'CUEDEDDERDESDNELUENID',
  EVE: 'JARLYNNEDNERNLYNOONTSQUERLYRMORSERTS',
  EVI: 'CKECTSLERLLYNCETEDTES',
  EVO: 'DIAKEDKERKESLVEMITVAE',
  EVU: 'LGELSE',
  EVV: 'IVA',
  EVZ: 'ONE',
  EWE: 'RER',
  EWO: 'UND',
  EXA: 'CTACTSCUMLTELTSMENRCHUDI',
  EXC: 'AMBAVEEEDELSEPTERNERPESSIDEISEITELAMOCTUSEUSSYST',
  EXE: 'DRAMPTQUYRCERTSUNT',
  EXH: 'ALEORTUME',
  EXI: 'LEDLERLESLICNESSTSTEDTUS',
  EXM: 'OOR',
  EXO: 'DICDOIDOSDUSGENLVEMISNERNYMPODTIC',
  EXP: 'ANDECTEDEELSENDERTIREIRYLATONEORTOSEUGN',
  EXS: 'ECTERTHIP',
  EXT: 'ANTENDENTERNILLIMAIMEINEIRPOLLOLSORTRASUNDURB',
  EXU: 'DEDDESLTSRBSRGEVIA',
  EYA: 'LETSES',
  EYD: 'ENT',
  EYE: 'BARCUPDOTFULINGISHLETLIDPIT',
  EYL: 'IAD',
  EYR: 'ANTIES',
  EYS: 'OGE',
  FAA: 'ILK',
  FAB: 'IANLEDLERLESRICULA',
  FAC: 'ADEERSETEETSEUPIALIASIERIESILEILYINGSIMTORTUMULAUND',
  FAD: 'DLEERSGEDGESING',
  FAE: 'CALCESNASNCENUSRIEROE',
  FAF: 'FLENIR',
  FAG: 'ALDARAGEDGERGOTINEINSOTSOTTOTY',
  FAI: 'KESLEDLLENERNLYNTSNTYREDRERRLYSANTHSTOR',
  FAK: 'EERERSERYINGIRSOFO',
  FAL: 'CERCESCONERNLALLENLERLOWSENSERSIESUMTERUNS',
  FAM: 'BLEILYINEINGISHOSEOUSULI',
  FAN: 'DOMEGAGASGEDGERGLEGOTIONJETKLEMANNEDNELNERNIANONONSOUTTADTEETODTOMUMS',
  FAQ: 'IRSUIR',
  FAR: 'ADSANDAONCEDCERCESCIECINDEDDELERSFALFELFETINAINEINGISHLESLEULEYMEDMEROFFOUKRELRISROWSETTED',
  FAS: 'CESCETCIACIOCISELSHEDHERHESOLATEDTENTERTLYTUS',
  FAT: 'ALEALSHERHOMIHAIMAINGSIASOSTEDTENTERUUS',
  FAU: 'CALCESCETCREFELLDSLTSLTYNAENALNASNCHNUSREDSENTORVES',
  FAV: 'ELAISMORSOSEOUROUS',
  FAW: 'NEDNER',
  FAX: 'ING',
  FAY: 'INGLES',
  FAZ: 'ING',
  FDN: 'AME',
  FDT: 'YPE',
  FEA: 'GUEKEDLTYREDRERSEDSESSORSTSTERTLYZEDZES',
  FEB: 'RESRIS',
  FEC: 'CHEIALKETKLYULAUND',
  FED: 'DANITYORA',
  FEE: 'BLEBLYDEDDERINGLERRIEZEDZES',
  FEG: 'ARY',
  FEH: 'MIC',
  FEI: 'GNSJOANTSRIESTSSTY',
  FEL: 'IDSINELAHLASLEDLENLERLICLOELONLOWOIDONSONYSICTEDTER',
  FEM: 'ALECEEMESORAPTYURS',
  FEN: 'CEDCERCESDEDDERIANITEMANMENNECNELNERNIGRIRTER',
  FEO: 'DALDUMFFSWER',
  FER: 'ASHBAMFELFETGUSIAEIALIASINEITYKINLIEMALMISNEDOCEOUSRELRENRERRETRICRISRUMTERTILULAULEVIDVOR',
  FES: 'APOCUEELSSEDSESTAETALTERTINTON',
  FET: 'IALICHINGISEISHLOWORSTEDTERTLEURE',
  FEU: 'AGEARSCHTDALDEDDEEDERDUMINGTER',
  FEV: 'ERSERY',
  FEW: 'ESTNESTER',
  FEY: 'EST',
  FEZ: 'ZANZEDZES',
  FIA: 'CREDORNCENNANTSSCOUNT',
  FIB: 'BEDBERDOMERSREDRESRILRINULA',
  FIC: 'ARYCHEHATHESHUSINSKLEKLYOESOIDTILTORULA',
  FID: 'ATEAWIDEDDLEDLYELEEOSFADGEDGESGETLEY',
  FIE: 'LDSLDYNDSRCERTESTA',
  FIF: 'ERSINGISHTHS',
  FIG: 'AROARYBOYENTGEDGLEGUMHTSURAUREURY',
  FIJ: 'IAN',
  FIK: 'ERYING',
  FIL: 'ACEAGOATEAZEERSETSIALINGITEIUSLEDLERLESLETLIPMEDMERMETMICOSAOSETERTHSTHYTRE',
  FIM: 'BLE',
  FIN: 'ALEALSARYCASDALDERDONEERELYERYESTGALGANGERIALICKIFYINGISHITEITYJANKEDKELLETNACNANNEDNERNICNIPNOC',
  FIO: 'RDSRIN',
  FIP: 'PLE',
  FIQ: 'UES',
  FIR: 'ERSINGKEDKERKINLOTMANMEDMERMLYSTSTHS',
  FIS: 'CALCUSHEDHERHESHETSLETEDTERTICTLE',
  FIT: 'CHECHYFULOUTTEDTENTERTIT',
  FIU: 'MAN',
  FIV: 'ERSISH',
  FIX: 'AGEATEERSGIGINGIONITYIVEUPSURE',
  FIZ: 'GIGZEDZERZESZLE',
  FJE: 'LDS',
  FJO: 'RDS',
  FLA: 'BBYBELBRACKSCONGGYGONILSIRSITEITHKEDKERKESMBEMEDMENMERMESNCHNESNGENKSNKYPPYREDRERRESSERSHYSKSTEDTHETLYTUSUNTUTOVIAVICVIDVINVORWEDXENXESYEDYER',
  FLE: 'AMSAMYCHECKSCKYDGEDGYECEECHECYERSETSINGMERNCHNSERRYSHYTCHURYWEDWITXEDXESXORYED',
  FLI: 'CKSCKYDGEERSESTGHTMSYNCHNGSNGYNTSNTYOMAPEDRTSRTYSKYTCHTEDTESTTY',
  FLN: 'EUR',
  FLO: 'ATSATYBBYCCICKSCKYDGENGSODSODYOEYORSOSYOZYPPYRAERALRANRASRESRETRIARIDRINSSASSYTASTERURSURYUSEUSHUTSWEDWER',
  FLU: 'ATECANENTFFSFFYGELIDSINGKEDKESKEYMEDMESMPSNKSNKYORSRRYSHYTEDTERTESTEYVIOXEDXERXESYTS',
  FLY: 'BOYBYSERSINGMANMENOFFSCHTEDTESWAY',
  FOA: 'LEDMEDMER',
  FOB: 'BED',
  FOC: 'KLESLE',
  FOD: 'DERGEL',
  FOE: 'HNSISHMANMENTALTIDTORTUS',
  FOG: 'BOWDOGDOMEYSGEDGERIESMANMENRAMRUM',
  FOI: 'BLELEDLERNEDSONSTSSTYTER',
  FOK: 'KER',
  FOL: 'ATEDEDDENDEREYEIALIARIESILYIOSIOTIUMKSYLERLESLISLOWSOM',
  FOM: 'ENT',
  FON: 'DAKDEDDERDLEDLYDONDUEDUKDUSTALTEDTES',
  FOO: 'BARDERLEDLERNERTEDTERTIETLETSYZLE',
  FOP: 'PED',
  FOR: 'AGEAMSANEAYSBADBARBIDBITBOWBYECATCEDCERCESCETCHECUTDAMDEDDIDEBYEDOEGOESTFARFEXFITGABGATGEDGERGESGETGIEGOTHOOHOWINTKEDKERLAYLETLIEMALMATMBYMEDMEEMELMERMESMICMINMLYMOLMYLNAXNIXOLDPETPITRADRELRILRITRUESARSAYSEESETTESTHYTINTISUMSVAYWHY',
  FOS: 'ITESAESEDSESSETSILSORTER',
  FOT: 'HERIVEMAL',
  FOU: 'ETTGHTGUELEDLERLLYNCENDSNTENTSRERRRERTHSSATERTRATRE',
  FOV: 'EAEEALENT',
  FOW: 'AGEENTLEDLER',
  FOX: 'ERYIERILYINGISHITE',
  FOY: 'ERSSEN',
  FOZ: 'IER',
  FRA: 'CASCHECIDELAENAGORILEILSISEISTKENMEAMEDMERMESNCANCENCONCSNGINKSNZYPLEPPESCOSERSSETCHTERTRYUDSUENXINYEDYNEZEDZERZIL',
  FRE: 'AKSAKYATHDDODDYEBYEFDELYENDERSESPESTETYEZEEZYGITIJOITHITYNALNCHNNENUMNZYRESSCOSNESNOTTETTYTUMYJA',
  FRI: 'ANDARSARYBBYCTIDAYDGEEDAENDERSESEEZEEZYGGAGHTGIDGORJOLLALLLSLLYNGENGYSCASCHSCOSESSIISKSSKYSONTHSTHYTTSVOLZEDZELZERZESZZY',
  FRO: 'CKSGGYISEKINLICNDENDSNTSRENSTSSTYTHITHSTHYUGHUSTUSYUZEUZYWERWNSWNYWSTWSYWZEWZYZEN',
  FRU: 'GALITSITYMPSMPYSLASTATEX',
  FRY: 'ERSINGPAN',
  FSI: 'EST',
  FST: 'ORE',
  FTN: 'CMDERR',
  FUB: 'BED',
  FUC: 'ATEKEDKEROIDOSEOUS',
  FUD: 'DERDLEGEDGERGES',
  FUE: 'LEDLERRTE',
  FUF: 'FITFLE',
  FUG: 'ACYARAARDATEATOGEDIOSLEDLERLESUEDUES',
  FUH: 'RER',
  FUL: 'ANICRAFILFULGIDGORGURHAMICAIGOLAMLDOLEDLERLOMMARMENVIDYIEZIE',
  FUM: 'ADEADOAGEAGOANTBLEERSETSIERIFYILYINGISHMELMLEOSEOUSULI',
  FUN: 'BREDALDEDDERDICDUSESTGALGIAGICGIDGINGUSKEDKERKIANEDNELORI',
  FUR: 'ANEANSCAECALDELDLEFURIALIEDIESIFYILELANLEDLERNERNITOICOIDOINOLEOREORSPHYREDROWTHYTUMZEDZES',
  FUS: 'AINATECINEAUEESELSILEILSINGIONOIDSEDSERSESSLETEETERTETTICTIETINTLETOCULAUMAURE',
  FUT: 'ILETAHTERUREURO',
  FUZ: 'EESILSINGZEDZESZLE',
  FYL: 'FOTGJAKER',
  FYT: 'TES',
  GAA: 'TCH',
  GAB: 'ARIBAIBEDBERBLEBROERTGABIESIONLEDLERLESLETOON',
  GAD: 'ABAAEABEEDEDDERDISEANFLYGERGETIDSITEMANOIDUIN',
  GAE: 'LIC',
  GAF: 'FEDFERFESFLE',
  GAG: 'AKUATEERSGEDGERGLEINGMANMEN',
  GAI: 'ETYNEDNERNLYNORNSTTEDTER',
  GAL: 'AGOAHSANTANYAXYBANCHAEAEEASEGAEIDENAEODERAEREEUSGALIBIIOTIUMLAHLEDLERLETLEYLICLONLOPLOWLUPLUSOCHOOTOPSOREOSHUTHYACYAK',
  GAM: 'AHEARIASHBASBESBETBIABIRBITBLEBOLDIAELYENEESTETEGEEGIAIERILYINEINGINSMASMEDMERMONONDONEONTORIUTS',
  GAN: 'DERDHIDULDUMEFSEVSGANGEDGERGESGLYGSAGUEGWAJASNERNETOFSOIDOINSELSERSEYTONTRYTSLYIEZIE',
  GAO: 'LEDLERNIC',
  GAP: 'ERSINGPEDPER',
  GAR: 'AGEAVAAWIBEDBELBLECONDENDONETHGETGILGLEGOLIALIBAISHLICNELNERNETOTEOUSRANRATREDRETRONROOROTRYASILTENTERTHSUDAVEYVIE',
  GAS: 'BAGCONHEDHERHESHLYIFYKETKINLITMANMENPARPEDPERSEDSERSESSITTEDTERTLY',
  GAT: 'EAUHERHICINGSBYTENTER',
  GAU: 'CHECHOCIEFERFREGEDGERGESILYLICLINLSHLTSMEDNCHNTYPUSRICRIEZES',
  GAV: 'AGEALLELSIAEIALOTS',
  GAW: 'AINCEYCIEGAWISHKEDKERNEYSIE',
  GAY: 'ALSCATESTETYISHPOOWAYYOU',
  GAZ: 'ABOEBOERSINGOOKUMP',
  GDI: 'NFO',
  GEA: 'REDSONTAS',
  GEB: 'ANGBIE',
  GEC: 'KEDKOS',
  GED: 'ACTDERUNK',
  GEE: 'GAWINGJEERAHSTSZER',
  GEG: 'GEEGER',
  GEI: 'GERKIASHASON',
  GEL: 'ADAANTATEDEDDEREEMEESLEDONGOSE',
  GEM: 'ARAELSINIMAEMANMEDMELMEROTEOTSSESUTI',
  GEN: 'APPDEREALEAREATEKIEPIERAETSEVAIALIANIESIONIPAIPSIUSOASOMEOMSRESROSSONTESTILTLETLYTOOTRYUAL',
  GEO: 'DALDESDICGENIDSMYSNICNIMRGESIDTIC',
  GEP: 'EOOOUN',
  GER: 'AHSALDARAARDATEATYBILEFAENTFULKINMALMANMENMINMONONTRESSUMTIEUNDVAOVASYON',
  GES: 'ITHTAETEDTENTERTESTICTIOTOR',
  GET: 'HERSPASULTERUPS',
  GEU: 'LAH',
  GEW: 'GAW',
  GEY: 'SER',
  GHA: 'FIRISTLVARRIRRYTTIUTSZALZELZIS',
  GHE: 'BERDDARAOTTITTO',
  GHI: 'BLI',
  GHO: 'STSSTYULS',
  GHR: 'USH',
  GHU: 'RRY',
  GHY: 'LLS',
  GIA: 'NTSOURRRARRE',
  GIB: 'AROBARBEDBERBETBOLBONBUSERSIERINGLEHLETOIASON',
  GID: 'DAPDEAEONGEAGEEJEEYEA',
  GIE: 'INGNAHSEL',
  GIF: 'OLATEDTIE',
  GIG: 'ANTFULGEDGERGETGITGLEGLYLETLIOLOTMANOLOOTSUESUNU',
  GIL: 'AKIDEDDENDERENOGAIGIEGULGUYIAKLARLEDLERLESLIELOTOURPEYTENVER',
  GIM: 'BALBLEBRIELSLETMALMERMORPEDPER',
  GIN: 'ETEGALGERGKOGLEKGONEDNELNERNETNEYNLE',
  GIP: 'ONSPEDPERSER',
  GIR: 'DEDDERDLEKINLIENALNEDNELNIEONSRITSLETEDTHS',
  GIS: 'ANTLERMOSPIN',
  GIT: 'ANAANOTER',
  GIU: 'LIONTASTO',
  GIV: 'ENSERSETHING',
  GIZ: 'MOSZEN',
  GLA: 'CESCISCONDDYDENDESDIIDLYDYSGAHGOLIKSIREIRSIRYIVEKEDMORNCENDSNISREDRESRRYSSYUKEURYVERZEDZENZERZES',
  GLE: 'AMSAMYANSARYAVEBAEBALBESDESDGEEDSEKSETSETYGLYTTYYDE',
  GLI: 'BLYDEDDERDESFFSFFYMEDMESNSENTSOMAOSARESSKYTCHTZY',
  GLO: 'AMSATSBALBEDBESBINBUSEALGGSMUSOMSOMYPPYRIASSASSYSTSTUMUTSVEDVERVESVEYWEDWERZEDZERZES',
  GLU: 'CICCIDCKEERSIERILYINGISHMALMESMLYMMYMPYNCHSIDTCHTEITENTIN',
  GLY: 'CANCICCIDCINCOLCYLPHS',
  GNA: 'EUSMMARLSRLYRRSTHOTOOTTYWEDWER',
  GNE: 'ISSTUM',
  GNO: 'MEDMESMICMONSESSIS',
  GOA: 'DEDLEDLEELERLIENNATEETLYVES',
  GOB: 'ACKANGANSBEDBERBETBINBLEIANIESIIDLETLINOESONY',
  GOC: 'ART',
  GOD: 'DAMDEDFULIVAKINLETOWNSIBSONWINWIT',
  GOE: 'MOTTAETHETIATIC',
  GOF: 'ERSFERFLE',
  GOG: 'GANGLEGLYLET',
  GOH: 'ILA',
  GOI: 'DELNGSTERTRE',
  GOL: 'ACHDENDERDICDIEDINEMSFEDFERIADLARLERLOPOCHOKAOSH',
  GOM: 'ARIARTBAYBOSLAHUTI',
  GON: 'ADSAKEERSGEDIACIALIFSIONIUMOFSOPH',
  GOO: 'BERDBYDERDIEDLYFAHFEDFERGLYGOLGULIERLAHLDENCHNDANEYNIERALROOSEDSESSEYTEEZLE',
  GOP: 'HERURA',
  GOR: 'ALSBALBETBITBLEDONFLYGEDGERGESGETGIAGIOGONHENIERILYINGKUNLINMANMAWMEDRAFRELSES',
  GOS: 'AINHENLETPELSANSEPSIPTER',
  GOT: 'CHYHAMHICTEN',
  GOU: 'GEDGERGESJATJAYJONLANNAUPENPINRDERDSRDYSTYTERTTE',
  GOV: 'ERN',
  GOW: 'ANSANYDIEFERKEDKITLANNEDPENPIN',
  GOY: 'ANAISH',
  GOZ: 'ELLILLZAN',
  GRA: 'ALSBBYBENCEDCERCESDALDEDDERDESDINDUSEAEEMEFTSGERHAMIANILSINEINSINYITHKLEMASMMAMMEMMYMPAMPSNAMNATNCHNDENDONDSNESNETNGENMANNYNTHNTSNUMNZAPEDPESPEYPHSPHYPPAPTASNISPSSSYTAETEDTERTESTIATINTISUNTVATVEDVELVENVERVESVICVIDWLSYEDYERYLYZEDZERZESZIE',
  GRE: 'ASEASYATSAVEBESECEEDSEDYEKSENSENYESEETSEVEFFEGALGAUGGEGORGOSIGEINGITHLOTMIOMMYNATSILTELUNDWIAYEDYERYLY',
  GRI: 'DEDDESECEEFSEGEEKOEVEFFEFFSFTSGRILLELLSLLYLSEMEDMESMLYMMENCHNDSNGONNYOTSPEDPERPESPEYPHEPPEPPYQUASLYSONSTSSTYTHSTTYVETVNAZEL',
  GRO: 'ANSATSCERGGYINSMETMIAMILMYLOMSOMYOSEOTYOVEOVYPEDPERPESSERSETSSESSOSZYTENTTOTTYUCHUGHUNDUPSUSEUSYUTSUTYUZEVEDVELVERVESVETWANWEDWERWLSWLYWSEWTHWZEYNEZERZET',
  GRU: 'BBYDGEELSFFSFFYGRUINELLAMESMLYMPHMPSMPYNCHNDYNGYNTHNTSPPOTCH',
  GRY: 'LLELLI',
  GTH: 'ITE',
  GUA: 'CHOCINCOSDUAIACIOLNAYNGONINNOSNYLQUERDORDSREARRIVASZZO',
  GUB: 'BIN',
  GUC: 'KED',
  GUD: 'AMEDLEGETRUN',
  GUE: 'BRELPHMALMULNONRRESTSTARTRE',
  GUF: 'FAWFERFIN',
  GUG: 'GLELETLIALIO',
  GUI: 'ANADEDDERDESDONGNELDSLEDLERLESLTSLTYMPENDENEANFOSEDSERSESTAR',
  GUL: 'ANDASHDENFEDGULISTLAHLEDLERLETLEYOSEPEDPERPIN',
  GUM: 'BOSHARLAHMASMEDMERMICPUS',
  GUN: 'ATEDIEDOGEBOITEJAHMANMENNARNEDNELNENNERONGSELTERTUBUNGYAHYEH',
  GUR: 'DLEGEDGESGLEGLYIANISHJANJUNKHALETNETNEYRAH',
  GUS: 'AINHEDHERHESHETLEESETSIETEDTUS',
  GUT: 'IUMNICSERTAETARTEDTEETERTIETLETURTUS',
  GUY: 'ANADOMERSINGOTS',
  GUZ: 'ZLE',
  GWE: 'DUCEON',
  GYA: 'SSA',
  GYB: 'ING',
  GYM: 'MALNICPIESIA',
  GYN: 'ICSURA',
  GYP: 'PEDPERSUM',
  GYR: 'ANTATEENEINGOMAONSOSEOUS',
  GYT: 'TJA',
  GYV: 'ING',
  HAB: 'BLEBUBEASENAEREILEIRIIRUITSNABOOBOUBUKA',
  HAC: 'EKSHISKEDKEEKERKIAKIEKINKLEKLY',
  HAD: 'ADABOTDENDERDIEDINEANINGITHJEEJESJISROMRON',
  HAE: 'INGMADMALMICMINRES',
  HAF: 'FATFETFITFLEGANLINNIANYLTEDTER',
  HAG: 'ADABUTDENDINDONEENEINGAIGEDGERGISGLEGLYLETLINRID',
  HAI: 'ARIDANDEEDUKKAIKALKUNLEDLERLESLSENAINANNCHNEDRDOREDRENRIFROFRSERSTRUPSLAVER',
  HAJ: 'JESJIS',
  HAK: 'DAREEMIMS',
  HAL: 'AKAALAAWIEBIELYERSERUERZESTFENFERFLYIDEIDSINGITELAHLANLELLEXLOALOOLOSLOTLOWLUXOEDOESOIDPERSENSERTEDTERUTZVAHVASVEDVERVES',
  HAM: 'ADAALDALSATAATEAULBERBLEBROEILELTETZFATILTITELAHLETMALMAMMEDMEROSEOUSPERULEULIZAHZAS',
  HAN: 'AFICEDCESDEDDELDERDLEGARGBYGEDGEEGERGIEGLEGULGUPKEDKERKIEKLEKULSELSESSOMTEDTLE',
  HAO: 'LESRIS',
  HAP: 'ALEPEDPENPERTENTICTORUKU',
  HAR: 'ACEANGARIASSASTAYABORDENDERDIEDIMDLYEEMELDEMSICOIERINGISHKEDKEEKENLEDLEMLOTMALMANMEDMELMERMINMONOLDPEDPERPINRIDRISROWTALTENTINTLYVEY',
  HAS: 'ARDHABHEDHERHESLETPEDSARSELSLETEDTENTERTESTIF',
  HAT: 'BOXERSFULHORINGPINREDTEDTERTICTIE',
  HAU: 'GHSGHTLEDLERLMSLMYLSENCENCHNTSNTYPIASENSSEYNE',
  HAV: 'AGEANAENSENTERSIERINGIOROCS',
  HAW: 'AIIINGIYAKEDKERKEYKIESEDSERSES',
  HAY: 'BOXCAPERSINGMOWSEL',
  HAZ: 'ANSARAARDELSERSIERILYINGZAN',
  HEA: 'DEDDERDLEDLYLEDLERLTHPEDPERRERRSERSTRTHRTSRTYTEDTENTERTHSTHYUMEVEDVENVERVES',
  HEB: 'ETEREW',
  HEC: 'ATEKLETARTICTORUBA',
  HED: 'DLEEBOERAERSGEDGERGES',
  HEE: 'DEDDERHAWLEDLERZEDZESZIE',
  HEF: 'TEDTER',
  HEG: 'ARIIRA',
  HEI: 'FERGHTKUMLEDMINNIEREDRLOSTSZED',
  HEJ: 'AZIIRA',
  HEL: 'BEHDERENAENNIACIDEINGIONIOSIUMLEDLENLERLIMLOSLUOMEDMETOMAOTSPEDPERPLYVEDVERVESVINZEL',
  HEM: 'ASEERAINAINEINSMEDMELMEROIDPENPIEULE',
  HEN: 'BITDLYISMNASNESNINPENRYSTEDTER',
  HEP: 'CATPENPERTADTALTYL',
  HER: 'ALDAUDAUSBALBARBERBIDDEDDERDICEATEBYEINEOFEONEROESYETOILEIOTMAEMAIMANMESMITNIAOESOICOIDOINOLAONSPESPETSEDSIRULI',
  HES: 'PEDPELPERTERTIA',
  HET: 'EROHENMANTERTIE',
  HEU: 'CHSGHSVEL',
  HEW: 'ERSGAGING',
  HEX: 'ACEACTADDADEADSANEDRAENEERSINEINGODEOICONEOSESUBYLSYNE',
  HEY: 'DAYDEYNNEPENRAT',
  HEZ: 'RON',
  HIA: 'TALTUS',
  HIB: 'BINITO',
  HIC: 'ACOCUPKETKEY',
  HID: 'AGEDENERSING',
  HIE: 'DERINGMALRONROS',
  HIG: 'DONGLEHERHLYHTHHTS',
  HIJ: 'ACK',
  HIK: 'ERSINGULI',
  HIL: 'ARYLEDLELLERLETLOALOSSAHTED',
  HIM: 'ATIENEPLE',
  HIN: 'DERDOODUSGEDGERGESGLENERNEYOIDOKITEDTER',
  HIO: 'DON',
  HIP: 'PEDPENPERPIAPICPIEPLEPOSPUS',
  HIR: 'CICCINCUSERSINGMOSPLESELSLETCHUDO',
  HIS: 'PIDSEDSELSERSESTEDTERTIETON',
  HIT: 'CHYHERLERTER',
  HIV: 'INGITE',
  HIZ: 'ZIE',
  HOA: 'GIERDSREDRSEXEDXEEXERXESZIN',
  HOB: 'BEDBERBETBILBITBLEBLYITSLOBNOBOEDOES',
  HOC: 'KEDKERKETKEYKLE',
  HOD: 'ADSDENDERDINDLEFULMANMENURE',
  HOE: 'FULING',
  HOG: 'ANSGEDGEEGERGETGIEGINNUTPENSTYTIETON',
  HOI: 'CKSDENSEDSESSTS',
  HOK: 'IERINGUMS',
  HOL: 'ARDCADCUSDENDERDUPIERIESILYINGISMISTKEDLASLERLINLOALOOLOSLOWMESMIAMICMOSOKUOURPENSOM',
  HOM: 'AGEARDBREELYERSIERILYINGINYISHRAI',
  HON: 'ANSCHODASERSESTEYSIEDILYINGKEDKERKEYKIEORAORSOUR',
  HOO: 'DEDDIEDLEDOOEYSFEDFERKAHKASKEDKERKEYKUMKUPLEELEYLIENDIPEDPERPLAPLEPOEPOORAHRAYROOTAYTCHTEDTERVEDVENVERVESVEY',
  HOP: 'ERSINGOFFPEDPERPETPLE',
  HOR: 'ACEAHSARYDEDDESKEYMICMOSNEDNERNETNIERAHRALRAYRIDRORSEDSERSESSEYSTESTS',
  HOS: 'ELSIERINGTALTEDTELTERTIETLETLYTRY',
  HOT: 'BEDBOXCHADOGELSKEYPOTRODTEDTERTIETLE',
  HOU: 'DAHDANLETNCENDSNDYRISRLYSALSEDSELSERSESSTYTOU',
  HOV: 'ELSERS',
  HOW: 'ARDDAHDERDIEFFSISHITZKEDKERKITLEDLERLET',
  HOY: 'DENLESMAN',
  HPI: 'TAL',
  HRD: 'WRE',
  HUA: 'SHI',
  HUB: 'BEDBERBLEBLYBOBBUBCAPERTRISSHI',
  HUC: 'HENKLE',
  HUD: 'DLEDUPSON',
  HUE: 'FULMULRTA',
  HUF: 'FEDFERFLE',
  HUG: 'ELYESTGEDGERGINGLEHESHOC',
  HUI: 'PILTRE',
  HUL: 'CHYDAHDEEKEDLEDLERLOALOOLOSVER',
  HUM: 'ANEANSATEBLEBLYBUGEANECTERIHUMIFYISMISTITELIEMEDMELMERMIEMUMMUSORSOUROUSPEDPHSPTY',
  HUN: 'CHYDERGARGERGRYKERNERNICTEDTERYAK',
  HUP: 'PAHPOT',
  HUR: 'DENDISDLEEEKKLELEDLERLEYRAHRAYRERROOTEDTERTLE',
  HUS: 'HEDHELHERHESKEDKERPELPILSARTLE',
  HUT: 'LETTEDUNGZPA',
  HUX: 'TER',
  HUZ: 'OORZAHZAS',
  HYA: 'DESENAHYALINLTS',
  HYB: 'LANRIDRIS',
  HYD: 'AGENUMRAERASRIARICRIDROAROLROSRUS',
  HYE: 'MALNASNIANICTAL',
  HYG: 'EENEIARICRIN',
  HYL: 'EANIDSISMISTLUSOID',
  HYM: 'ENSNALNEDNERNIC',
  HYN: 'DER',
  HYO: 'IDS',
  HYP: 'ATEHAEHALHENINGNICNOSNUMOEDOIDOTHPED',
  HYR: 'ATECAN',
  HYS: 'ONSSOP',
  HYZ: 'ONE',
  IAM: 'BICBUS',
  IAT: 'RIC',
  IBA: 'NAG',
  IBE: 'RESRIARICRISXES',
  IBI: 'CESDEMLAOSES',
  IBY: 'CUS',
  ICA: 'RIARUS',
  ICE: 'BOXCAPMANMENRYA',
  ICH: 'EBUIBUORS',
  ICI: 'CLEESTNGS',
  ICK: 'ERSIER',
  ICO: 'NESNIC',
  IDA: 'EANEINLIA',
  IDE: 'AEDALSALYATAATEISTNCESIA',
  IDI: 'ASMGBOOCYOMSOTSTOL',
  IDL: 'EBYERSESTETYINGISH',
  IDO: 'ISMISTLETLONLUMTEA',
  IDY: 'LERLLS',
  IFE: 'CKS',
  IFF: 'IER',
  IFR: 'EAL',
  IFU: 'GAO',
  IGB: 'IRA',
  IGI: 'TUR',
  IGL: 'OOS',
  IGN: 'AMEAROIFYITEOREOTE',
  IGO: 'ROT',
  IGU: 'ANA',
  IHR: 'AMS',
  IKH: 'WAN',
  ILE: 'XES',
  ILI: 'ADSAHICICCIN',
  ILK: 'ANE',
  ILL: 'ANOECKECTESSESTINGIPEISHITEIUMUDEUMEUPIUREUST',
  ILY: 'SIA',
  IMA: 'GEDGENGERGESMAHMICRETUMS',
  IMB: 'ALMANDARKARNASEEDSIBEODYOSKREDREXRUEUEDUESUIAUTE',
  IMD: 'TLY',
  IME: 'LLE',
  IMI: 'DESDICNES',
  IMM: 'ANEASKERDESHIESINDISSIXTOTEUNDUNEUREUTE',
  IMO: 'GEN',
  IMP: 'ACKACTAIRALAALEALLALMANEARKARLARTAVEAWNEDEELSENDENTERFERSERYESTHEEIESINGISHLEXOFOONEOORORTOSEOSTREGUGNUNEUREUTE',
  INA: 'BLEMIANERNESNGARCHRMSXON',
  INB: 'ENTITSLOWODYONDORNREDUSH',
  INC: 'AGEAICAMPANTARNASEASKASTAVEAVOEDEENDEPTESTHEDHERHESIDEISEITELIPOGSOMEONYORDORPORROUPTRIUBEUBIULKULPULTURSUSEUSSUTE',
  IND: 'ABAANEARTEAREBTECLEEDENEENTIANICEICOICTIESIGNIGOISHITEIUMOINOLEOLSONEOOROWSRISUCEUCTUEDUESULTUNAURE',
  INE: 'RMIRTSUNT',
  INF: 'ACEAIRALLAMEAMYANDANGANSANTAREECTEEDEFTELTEOFERNERSESTILEILLILMIMAIRMLEXLOWLUELUXOLDORMREEULAUMEUNDUSE',
  ING: 'ANGATEENEENTENUENYESTINEIRTLESLUTNUEOTSRAMROWUENULFUSH',
  INH: 'ALEAMEAULELLEREIVEOLDOOPUME',
  INI: 'OMEOMIQUESLETALTIOTIS',
  INJ: 'ECTOINUREURYUST',
  INK: 'ERSIERIESINGISHLESMANNITNOTOSIPOT',
  INL: 'ACEAIDAIKAKEANDARDAUTAYSEAKESSETSIERIKEINEOOK',
  INM: 'ATEEATESHOREOST',
  INN: 'AGEATEERSESSESTINGUIT',
  INO: 'DESGENSICSINWER',
  INP: 'ORTOURUSHUTS',
  INR: 'AILINGOADOLLUSH',
  INS: 'ACKAMEANEEAMECTEERERTETSHIPHOEIDEISTITEOLEORBOULPANTALTARTEPTILTOPULAULTUMEUNKURE',
  INT: 'ACTAILAKEENDENSENTERJERNERSEXTICEILLIMAIMEINEIREISYOEDOMBONEORTOWNRAPRODROSRUSUBEUITUNEURNUSE',
  INU: 'LINNCTREDRESRNS',
  INV: 'ADEARSECTEILEINENTERTESTICTILEITEOKE',
  INW: 'ALEALLARDEEDICKINDITHOODORKORNOVERAPRIT',
  INY: 'ALAOKE',
  IOD: 'ATEIDEIDSINEINSISMITEIZEOLSOSOOUSOXY',
  IOL: 'ITE',
  ION: 'IANICSISEISMISTIUMIZEONE',
  IOT: 'IZE',
  IOW: 'ANS',
  IPE: 'CAC',
  IPI: 'DAE',
  IPO: 'MEA',
  IRA: 'DESNICQISTER',
  IRC: 'HIN',
  IRE: 'FULNIC',
  IRI: 'DALDESDICDINSEDSESSHYSINTICTIS',
  IRK: 'ING',
  IRO: 'NEDNERNESNICNLY',
  IRR: 'ATEEALIDEITEUPT',
  IRV: 'ING',
  ISA: 'BELGONIAHIANMINRIATICTIDTINTIS',
  ISC: 'HARHIAOSE',
  ISE: 'ULT',
  ISH: 'IME',
  ISI: 'CLEDAEDIANAI',
  ISL: 'ANDETAETSING',
  ISM: 'DOM',
  ISO: 'BARDEFGAMGENGONHELLDELEXLOGMERNYMPAGPODPORTAC',
  ISR: 'AEL',
  ISS: 'EISITEUEDUERUES',
  IST: 'ANAHMILESOKE',
  ISU: 'RETRUS',
  ISW: 'ARA',
  ITA: 'LICLONUBAVES',
  ITC: 'HEDHES',
  ITE: 'MEDRUM',
  ITH: 'ACAANDIEL',
  ITO: 'ISMISTNIA',
  ITS: 'ELF',
  ITT: 'RIA',
  ITY: 'LUS',
  ITZ: 'EBU',
  IUR: 'ANT',
  IWA: 'IWA',
  IWO: 'RTHUND',
  IWW: 'OODORT',
  IXI: 'AMA',
  IXO: 'DESDICDID',
  IXT: 'LES',
  IZA: 'FAT',
  IZC: 'HAK',
  IZZ: 'ARD',
  JAA: 'LIN',
  JAB: 'BEDBERBLEERSIRUOTS',
  JAC: 'ALSAMIANAAREATEENSENTKALKEDKERKETKEYKIEKYEOBYTUS',
  JAD: 'DEDDERERYINGISH',
  JAE: 'GER',
  JAG: 'ATHEERERSGARGEDGERHIRONGRASUARUEY',
  JAH: 'VEH',
  JAI: 'LEDLERLOR',
  JAJ: 'MAN',
  JAL: 'APAAPSKAROPSOPY',
  JAM: 'BEDBEEBERBESBONBOSBOYBULMEDMERNIANUTOKEPAN',
  JAN: 'APAGARGLEGLYICEKERNERTEE',
  JAP: 'ANSERSERYINGISH',
  JAR: 'ABEANABLEBOTDINDONEEDFLYFULGLEGONINANUTOOLRAHREDRETVEYVIEVIS',
  JAS: 'EYSIESMINPERPISSID',
  JAT: 'ACOAKAOBA',
  JAU: 'DIEKEDNCENERNTSNTYPED',
  JAV: 'ALI',
  JAW: 'ANSING',
  JAY: 'ANTCEEESHGEEPIEVEE',
  JAZ: 'EYSIESZEDZERZES',
  JEA: 'MESNIENNE',
  JEB: 'ELSUSI',
  JEE: 'INGREDRERTEE',
  JEF: 'FIE',
  JEH: 'ADS',
  JEJ: 'UNAUNE',
  JEK: 'YLL',
  JEL: 'ICKLABLEDLIBSKE',
  JEM: 'BLEIMA',
  JEN: 'INEKINNETNIEOARSON',
  JER: 'ALDBILBOAEEDEMYIDSKEDKERKINNIEOMEQUERIDRIESEYVIAVIN',
  JES: 'PERSEDSESSIESURTEDTEETERUIT',
  JET: 'HROONSSAMSOMTEDTERTONTRU',
  JEW: 'DOMELSELYESSINGISHISM',
  JEZ: 'AILIAH',
  JHA: 'RAL',
  JHU: 'RIA',
  JIB: 'BAHBEDBEHBERERSINGMANMENOYA',
  JIC: 'AMAARA',
  JIF: 'FLE',
  JIG: 'GEDGERGETGITGLEGLYMANMENOTESAW',
  JIH: 'ADS',
  JIL: 'LETTEDTEETER',
  JIM: 'INYJAMMERPERPLYSON',
  JIN: 'CANETEGALGKOGLEGLYKEDKERKETKLENEESHAXEDXES',
  JIP: 'PER',
  JIR: 'BLEGAH',
  JIS: 'SOM',
  JIT: 'NEYTER',
  JIV: 'AROING',
  JIZ: 'YAHZEN',
  JNA: 'NAS',
  JOA: 'NNANNE',
  JOB: 'ADEBEDBERBETBLEMANMENSON',
  JOC: 'ANTHENKERKEYKOSOSEOTEUMAUNDUNO',
  JOD: 'ELR',
  JOE: 'YES',
  JOG: 'GEDGERGLEGLY',
  JOH: 'ANNNINNNY',
  JOI: 'NEDNERNTSNTYSTS',
  JOJ: 'OBA',
  JOK: 'ERSIERINGISHIST',
  JOL: 'LOPTEDTER',
  JON: 'AHSDLAQUEVAL',
  JOR: 'AMSDANDENISTOPORAMUMS',
  JOS: 'EPHHEDHERHESHUAIAHKINSERSESTLE',
  JOT: 'ISITEDTERUNN',
  JOU: 'ALSKEDLESNCENCYRNOSTSTES',
  JOV: 'IALIANITE',
  JOW: 'ARIARSERYINGLEDLERLOPSERTER',
  JOY: 'ANTFULHOPINGLETOUSPOP',
  JUB: 'ARBATEBAHHAHILEILI',
  JUC: 'UNA',
  JUD: 'AICDEREANGEDGERGESICAICEITHOGIOKA',
  JUE: 'CES',
  JUF: 'FER',
  JUG: 'ALEATEFULGEDGERGLELARULAUMS',
  JUI: 'CEDCERCES',
  JUJ: 'UBE',
  JUK: 'ING',
  JUL: 'EPSIANIENIESIETIUSOIDOLE',
  JUM: 'ADAANAARTBALBIEBLEBLYBOSENTFRUPEDPER',
  JUN: 'CATCOSCUSDIEEAUGLEGLIGLYIORIUSKEDKERKETKIETASTOS',
  JUP: 'ARDATIONS',
  JUR: 'ANEANTARAAREATAATSELSIESINGISPISTORS',
  JUS: 'LIKSALSELTEDTENTERTINTLETLYTUS',
  JUT: 'ISHTED',
  JUV: 'ENTITE',
  JUW: 'ISE',
  JWA: 'HAR',
  JYM: 'OLD',
  KAB: 'ABSAKAALAARDARSAYAERUIETIKIOBSUKIULIYLE',
  KAC: 'HIN',
  KAD: 'AGAAYADEREININEISH',
  KAF: 'FIRILAIRIIRSTAN',
  KAG: 'URA',
  KAH: 'ALAILIUNA',
  KAI: 'AKSBABMANNAHNGANITNSINYNRINROSSERTHI',
  KAK: 'APOKAK',
  KAL: 'ACHAMSANGEMAENDIANIFSIPHIUMLAHMIAMUKONGPAKPASPISWAR',
  KAM: 'AHIALAASSBALBOHBOUEELIANIASIKAIKSIYAMEUSIN',
  KAN: 'AFFAGIAKAARAARIDOLGLAGLIGRIJISKIENENONEOONREDSANSASTARTENTRYUKAURIWARYAW',
  KAO: 'LIN',
  KAP: 'OKSOTEPASPIEUKAUTT',
  KAR: 'AKAAMUATEATSAYAEAOEAUELAEWAITEITILUKMASMICOOSOSSPASREERENROOSHASTSTELTOSUNAVALVARWARYON',
  KAS: 'BAHHANHASHERHGAHIMIDASAK',
  KAT: 'ANAHAKHALIONIPOMONSUPUKA',
  KAU: 'RIS',
  KAV: 'AICASSIKA',
  KAW: 'AKAIKA',
  KAY: 'AKSLESOEDOESVAN',
  KAZ: 'OOS',
  KEB: 'ABSARSBIELAHOBSYAR',
  KEC: 'HELKEDKLEKSY',
  KED: 'DAHGEDGERGES',
  KEE: 'KEDKERLEDLERLIENEDNERNLYPERRIEVES',
  KEF: 'FELIRSTIU',
  KEG: 'FULLER',
  KEH: 'AYA',
  KEK: 'CHIUNA',
  KEL: 'DEREBEIMALEGLETLIALYSOIDPEDPERPIESONTERTICTIETOIVIN',
  KEM: 'PASPLEPTS',
  KEN: 'AFSDALDIRDNADOSDYRELMEMAMPYNEDNELNERNETTIATLETONYANYTE',
  KEP: 'HIRPEDPEN',
  KER: 'ACIANABEDEWAFEDITEMANMESMISNEDNELNERNESNOINOSRIARIERILSEY',
  KES: 'LEP',
  KET: 'CHYENEHIBINEMIEOLEONEOSETLEUBAUPAWAY',
  KEU: 'PER',
  KEV: 'ELSILS',
  KEW: 'PIE',
  KEY: 'AGEAKIINGLETMANMENPADSETWAYWRD',
  KHA: 'DISIKIJURKISLALLATLIFLSAMALMTINDANGANUMRAJRIARIFRUARWATIBTINTRITTIYALZARZEN',
  KHE: 'DAHDASLLA',
  KHI: 'LATRKATANVAN',
  KHO: 'DJAJAHTANWAR',
  KHU: 'LDATBA',
  KIA: 'LEENGSUGH',
  KIB: 'BEHBERBLEITZLAHLASOSHSEY',
  KIC: 'HELKEDKEEKERKUP',
  KID: 'ANGDEDDERDIEDLEDOSLETNAPNEYVID',
  KIE: 'KIERAN',
  KIK: 'ORIUELUYU',
  KIL: 'DEEERGEYSHIGIMSLASLCULEDLERLIGLOWNEDOHMTEDTERTIEUBAUCK',
  KIM: 'CHIMERNELONOURA',
  KIN: 'ASEBOTCOBDALDERDLEDLYEMAGEDGLYHININSKEDKERKLEKLYNORONEOOSSENTARTRATRYURA',
  KIO: 'SKSWANWAY',
  KIP: 'AGEFELPEDPENPERPINPURSEYUKA',
  KIR: 'KERMANMEWNEDPANSCHSENSTYTLEVER',
  KIS: 'ANGHENHKAHKEHONLEVMATMETSARSEDSELSERSESWAH',
  KIT: 'ABIBAGCATERSHEDHESINGISHMANSCHTARTEDTELTENTERTIETLETLYTUL',
  KIU: 'TLE',
  KIW: 'ACH',
  KLA: 'TCHXON',
  KLE: 'PHTSHA',
  KLI: 'PPE',
  KLO: 'NGSOCHOFSSSEWET',
  KLU: 'DGETZYXER',
  KNA: 'CKSCKYGGYPPEPPYRLERRYTCHTTEVESWEL',
  KNE: 'ADSELSLLSTCHVEL',
  KNI: 'AZIFEDFERFESGHTTCHVEDVESVEY',
  KNO: 'BBYCKSLLSLLYPPYSPSTTYUTSWERWNS',
  KNU: 'BBYCKSFFERLSRLYRRYTTY',
  KNY: 'AZISNA',
  KOA: 'LAS',
  KOB: 'ANGIRDOLDONG',
  KOC: 'HABHIA',
  KOD: 'AGUIAKKODOGU',
  KOH: 'EMPENSLAN',
  KOI: 'ARIBALLONNESNON',
  KOJ: 'ANGIKIIMAIRI',
  KOK: 'AKOAMAILALASOONOPU',
  KOL: 'ACHAMIHOZKKAKOZLERSUNUSH',
  KOM: 'ATIMOSPOWTOK',
  KON: 'FYTIGAINIJAKRADYAK',
  KOO: 'DOOKIEKRILAHLAUNTIRKASIN',
  KOP: 'ECKEKSJESPASPENPIE',
  KOR: 'AITANAARIDAXEANECIEROHMNONAOVARELUNAUNYYAKZEC',
  KOS: 'HERIMOONG',
  KOT: 'OKOOWSUKUWALYLE',
  KOU: 'LANMISMYSROIROSSINSSO',
  KOW: 'HAITOW',
  KOZ: 'UKA',
  KPU: 'ESI',
  KRA: 'ALSFTSITSKENNTZSISTERUTS',
  KRE: 'ESELOSPIS',
  KRI: 'GIALLSSESSTITON',
  KRO: 'NENNERNORNOSNURONIONS',
  KRU: 'BISBUTMAN',
  KTH: 'IBH',
  KUB: 'ERAONG',
  KUC: 'HEN',
  KUD: 'IZERUNZUS',
  KUH: 'NIA',
  KUK: 'ANGERIUPA',
  KUL: 'ACKAKIAKSANGDIPMETTUR',
  KUM: 'ARAARIBUKHARISSKUMMELRAH',
  KUN: 'DRYKUR',
  KUP: 'HARPER',
  KUR: 'GANSCHTASUBAUKHUMAUNGVEY',
  KUS: 'KOSKUSSOS',
  KUT: 'CHATABTAR',
  KUV: 'ASZERA',
  KUW: 'AIT',
  KVA: 'SES',
  KVE: 'TCH',
  KVU: 'TZA',
  KWA: 'CHAMMENZARTA',
  KYA: 'CKSNOLUNG',
  KYB: 'ELE',
  KYL: 'IESITE',
  KYM: 'NELRIC',
  KYR: 'IALIESINEIOS',
  KYT: 'HEDHESOON',
  KYU: 'RIN',
  LAA: 'GER',
  LAB: 'ARABEREFYELSIALILEITEIUMLABORSOURRALRASRETRIDRUMRUSRYS',
  LAC: 'CICCINCOLERSERTERYHESHSAIERILYINGKEDKERKEYMUSOCARYMTAMTICTIDTIMTOLTYLUNAUNE',
  LAD: 'ANGDERDIEENSERSIESIFYINGINOKINLEDLERLESNERRONYFYYLY',
  LAE: 'LIATIC',
  LAF: 'ITE',
  LAG: 'ANSENAENDERSGARGEDGENGERGINOONUNAUNE',
  LAH: 'NDAOREULI',
  LAI: 'CALCHSDLYGHSNEROSERDSREDSERSSETHE',
  LAK: 'ERSIERINGISHISMISTMUSOTA',
  LAL: 'ANGLANLED',
  LAM: 'AICANOANYBDABEDBERBESBIEBLYBOYDANDENEDHEDSELYENTESTIAEIASIIDINAINGISHIUMMASMEDMERMIENIDPADPASPEDPERPIC',
  LAN: 'AISATECEDCERCESCETCHADAUDEDDERELYETEGCAGELGKAGLEGUEGURIERIUSKERKETKLYNEROSESATSEHSONTUMUGOZON',
  LAO: 'DAH',
  LAP: 'DOGELSFULIESINSITHPEDPERPETPICSEDSERSESSUSTOPUTA',
  LAQ: 'UEI',
  LAR: 'CINDEDDERDONDRYGENGERGESGETGOSIATICKIGOIIDINEKEDKERNAXNYXOIDREERUPUMSVAEVALVASYNX',
  LAS: 'CARERSHEDHERHESINGIUSKETQUESESSETSIESOSTEDTERTEXTLYTRE',
  LAT: 'EENELYENSENTERAESTHAMHEDHEEHENHERHESHIEIANIGOINOINSIONISHITEIVEOMYONAOUNRIARISRONTENTERTINUKAVIA',
  LAU: 'ANSDEDDERDESGHSGHYLAUNCENCHRAERASRELRICRIERINRUSRYLTER',
  LAV: 'ABOAGEANTASHEEREHRERSINGISH',
  LAW: 'FULINEINGISHMANMENNEDNERRIESONTERTONYER',
  LAX: 'ATEESTISMISTITY',
  LAY: 'BOYERSERYINGLOCMANMENNEROFFOUT',
  LAZ: 'ARSARYIEDIERIESILYINGULEULI',
  LBI: 'NIT',
  LDI: 'NFO',
  LEA: 'CHYDEDDENDERDINFEDFENFERFITGUEKEDKERLLYLTYMERNEDNERNLYPEDPERRNSRNTSEDSERSESSOWSTSVEDVENVERVES',
  LEB: 'BANBEKENS',
  LEC: 'AMAHEAHERHESHWEKERTHITORYTH',
  LED: 'GEDGERGESGET',
  LEE: 'FULGTEPITREDSERTLEWANWAY',
  LEF: 'SELSENTER',
  LEG: 'ACYALSATEATIATOBARENDERSGEDGERGINIONISTITSLENLETMANMENONGUANUME',
  LEH: 'MERUAS',
  LEI: 'GERPOA',
  LEK: 'ACHANEKER',
  LEL: 'WEL',
  LEM: 'ANSMASMONMUSNADONSONYOSIUELURS',
  LEN: 'AEAAPEARDCANDEDDEEDERGERGTHIFYITYNOWORASEDSESTENTICTILTORTOSVOIVOY',
  LEO: 'NESNIDNIS',
  LEP: 'AGECHAEROERSRICRIDTIDTONTUS',
  LER: 'RET',
  LES: 'ATHBIACHEIONKEALIESEESENSERSESSONSORTER',
  LET: 'CHYHALHESOFFTEDTENTERTICUPS',
  LEU: 'CICCINCONCYLDESKON',
  LEV: 'ADEANAANTEEDEESELSERSIEDIERIESINSITEITYYNE',
  LEW: 'DERDLYINGIST',
  LEX: 'EMEICA',
  LEY: 'DEN',
  LIA: 'BLEISEMBANASNESNGSRDSSON',
  LIB: 'ANTARDATEBEDBERBETBRAELSERAERSGETIDOKENKINRAERALRASRIDRISYAN',
  LIC: 'HAMHEEHENHISHTSKEDKERORNOURTORURIURY',
  LID: 'ARSDEDDERIAS',
  LIE: 'BIGDERFERFLYGERGESNALNEENICNORPOTRNERREVER',
  LIF: 'ERSLODTEDTER',
  LIG: 'ANDANSASEATEGATGERHTSHTYNESNINNUMULAULEUREYDA',
  LIK: 'ELYENSERSESTINGKERNONUTA',
  LIL: 'ACSIALIANIEDIESITHIUMTEDYFY',
  LIM: 'ACEAILANSBALBASBATBECBEDBERBICBIEBOSBUSEANENSEYSIERINAINEINGITSITYMERNALNEDNERNICOIDOSAOSEOSIOUSPEDPERPETPIDPINPLYPSYULI',
  LIN: 'ACSAGAAGEDENDEREALEAREASENEENSENYERSEUPGAMGASGELGERGETGLEGOEGOTGUAHAYIERINGINSIYAKEDKERKUPLEYNETOUSPINSEYTELTENTERTIETOLUMS',
  LIO: 'NELNETNLYNNE',
  LIP: 'ASEIDEIDSINSLETOIDOMAPEDPENPERPIAPIE',
  LIQ: 'UERUETUIDUOR',
  LIR: 'ATEOTH',
  LIS: 'BONERELESPEDPERSESSOMTEDTELTENTER',
  LIT: 'ANYATUCHIERSHERHIAHICHOGHOLHOSMUSRESSEATENTERTLEUUSVAK',
  LIU: 'KIU',
  LIV: 'EDOELYENSERSERYESTETHIANIERINGISHRESYER',
  LIX: 'IVE',
  LIY: 'UAN',
  LIZ: 'ARDARYZIE',
  LLA: 'MASNOSUTU',
  LOA: 'DEDDENDERDUMFEDFERMEDMMINEDNERNGENINTHETHYVES',
  LOB: 'ALEATAATEBEDBERFIGINGOLAOLOOSAOSEULEULI',
  LOC: 'ALEALSATEHANHIAHUSKEDKERKETKUPOEDOESULEULIUMSUST',
  LOD: 'ENSGEDGERGES',
  LOE: 'ING',
  LOF: 'TEDTER',
  LOG: 'ANSEIAEUMGATGEDGERGETGIAGIEGINICSIERILYINSIONIUMJAMLETLOGMANOESOFFOUTRESRIARISWAY',
  LOH: 'ANAOCHOCK',
  LOI: 'MICNEDTER',
  LOK: 'IECMAN',
  LOL: 'IGOIUMLEDLERLOPLUP',
  LOM: 'ATABOYENTITA',
  LON: 'DONELYERSGANGEDGEEGERGESGLYGUEGUSGYIHYNTAR',
  LOO: 'EYSFAHFASFIEIESINGKEDKEEKERKUMKUPMEDMERNEYPEDPERSEDSENSERSESTEDTENTERTIEVER',
  LOP: 'ERSHININGPEDPERPET',
  LOQ: 'UAT',
  LOR: 'ANSATECHADANDEDDLYEALICAIENIESINGIOTIUS',
  LOS: 'ANGELSERSINGSERSES',
  LOT: 'AHSASEHLYIONIUMONGTEDTERTIETOSUKO',
  LOU: 'CHEDENDERDLYGHSIESIQAISAISEKASNGENGYPEDPENPESRDYREDRIESEDSESTEDTERTREVARVERVRE',
  LOV: 'AGEELYERSERYIERING',
  LOW: 'BOYDAHDERELLERSERYESTINGISHMANMENNLYRIESEDSERSIN',
  LOX: 'ING',
  LUB: 'BERRIC',
  LUC: 'BANENTERNIANIDAILEINAITEIUSKEDKENKIEKLYRESRUMULEUMAUMO',
  LUD: 'DENIANLOWWIG',
  LUE: 'LLATIC',
  LUF: 'FASFEDFER',
  LUG: 'GARGEDGERGIEINGNAS',
  LUJ: 'ULA',
  LUK: 'ELY',
  LUL: 'ABSAVSLAYLEDLERUAI',
  LUM: 'BARBERBUSENSINAINEMOXPEDPENPERPET',
  LUN: 'ACYAREARSARYATAATEETSGANGEDGEEGERGESGIEGISGYIIERIESKEROIDTEDULAULEYIE',
  LUP: 'EOLINEINSOIDOMAOUS',
  LUR: 'DANERSINGKEDKER',
  LUS: 'HAIHEDHEIHERHESHLYIADIANORYTEDTERTLYTRATRE',
  LUT: 'ANYAYOEALEICEINEUMHERINGISTOSERIN',
  LUV: 'IANISH',
  LUW: 'IAN',
  LUX: 'ATEIVEURY',
  LUZ: 'ULA',
  LVA: 'LUE',
  LYA: 'NCESES',
  LYC: 'EALEESEUMHEEIANINEIUMOSATIDTUS',
  LYD: 'IANITE',
  LYF: 'KIE',
  LYG: 'EUM',
  LYI: 'NGS',
  LYM: 'PHSPHY',
  LYN: 'CIDDONXES',
  LYR: 'AIDATEICSISMIST',
  LYS: 'ATEINEINGINSSASSIC',
  LYT: 'TAETAS',
  LYX: 'OSE',
  MAB: 'BLEELAOLOUTIYER',
  MAC: 'ABIACAACOANAAWSCUSERSHANHARHINHOSIESINGKLELEDLESLIBOMARAMRLIRONROSTRAUCAULAULEUPAUPIUSIUTAUTE',
  MAD: 'AFUAMEAMSCAPDEDDENDERDLEEFYHABHVAIGAMANMENNEPRASRESRIDRIHRILROATOMURO',
  MAE: 'INGNAD',
  MAF: 'FIAFLEIASTIRURA',
  MAG: 'AHIANIGEDGIEGLEGOTIANICSILPISMMASNESNETNONNUMNUSOTSPIERIMUEYYAR',
  MAH: 'ALAALYANTBUBESHEWUMALOESOLIONEORIOUTRANSIRSURZOR',
  MAI: 'DANDENDIEDINDLYGREHEMLEDLERLESLIELLELLSMEDMERMONMULNANNLYNOROIDOLIRIESONSTSTREZERZES',
  MAJ: 'LISOONORAORS',
  MAK: 'ALEARAARIARSERSEUPINGLUKOPAOUARANUTAUTU',
  MAL: 'ADEADYAGAAPIARSATEATIAWIAYAAYSEICEOSFEDGREICEIGNIKIINEISMISTKINLAMLEDLEELEILETLOWLOYLUMLUSMAGMEDOCAOPETEDTERTHATHETOLVALVIN',
  MAM: 'AMUBASBOSERSEYSIESLUKMAEMALMASMEAMEEMERMETMEYMIEMINMONMUTONAOTYPUSZER',
  MAN: 'ACEADAAGEANAATIBOTCHECHUCUSDANDARDATDILDIRDOMDRADUADYIEGEENTESSFULGALGARGELGERGESGEYGLEGOSGUEGWEIACIASICSIFYILAINIIOCISMISTITOITUIUSIVAJAKJELKIEKINLETNANNASNEDNERNETNIEOBOOIRORSQUEREDSERSESTALTASTELTERTESTICTIDTISTLETONTRATUATZUUALUAOUELUKAUMAUREWAYZASZIL',
  MAO: 'ISMISTMAORIS',
  MAP: 'ACHLESPEDPENPER',
  MAQ: 'UIS',
  MAR: 'ACAAGEAISANGARAAUDAVIBLEBLYCANCELCIACIDCORCOSCOTECAFIKGAYGEDGESGIEGINGOTIANICAIESIETINAINEIONIOUISHISTITAITIKABKAZKEBKEDKERKETKISKKAKUPKUSLEDLERLETLINMARMITMORMOTNIXOONQUERAMREDREERERRONROTROWRYSSESSHASHSSHYTEDTELTENTESTHATINTYNTYRVELVERVINWER',
  MAS: 'AISCLECONCOTDEUERSHAKHALHAMHEDHERHESHIEHRUJIDKEDKEGKERKMVKOILINONSORAQUESASSEDSELSERSESSIFSIGSOYTAXTEDTERTICTIX',
  MAT: 'ACOAPIARACHYELYERSEYSHERHESICOIESINAINGINSIPOKAHLOWMANOKERAHRALRESRICRISRIXRONSUETEDTERTESTINUREZAHZASZOHZOSZOT',
  MAU: 'DLEGERGHTGISGREKINLEDLERLEYLVIMEEMETNCHNDSNDYNGENGYNNASERVES',
  MAV: 'ENSIESINS',
  MAW: 'ALIGERINGKINSIE',
  MAX: 'IMAIMSIXE',
  MAY: 'ACAANSDAYESTEYEFLYHAPHEMINGORSPOPSINTENTHEVIN',
  MAZ: 'AMAAMEARDDURERSIERILYINGUCAUMA',
  MBE: 'UER',
  MBI: 'RAS',
  MBU: 'NDA',
  MEA: 'BLECONDERDOWGERGRELEDLERLIENEDNERNIENLYSLESLYTALTEDTHETICTUSZLE',
  MEC: 'ATEATICANCASHIRUMS',
  MED: 'AKAALSDLEIADIAEIALIANIASICAICIICKICOICSIMNINAINEINOISHISMIUMIUSIZELARLEYULAUSA',
  MEE: 'BOSHANKENKERKLYREDTENTERTLY',
  MEG: 'ARAASSERGGERILPMHOOHMRELREZRIM',
  MEH: 'ARITAR',
  MEI: 'KLELERNIESJESSA',
  MEK: 'ONG',
  MEL: 'ADAANODEDDEREESENAENEIANICAINEIORLAHLAYLEDLERLICLITLONLOWODYOIDONSOSAOTETEDTERTONVIE',
  MEM: 'BERNONOIRORY',
  MEN: 'ACEADSAGEALDDEDDEEDELDERFRAGWEHIRIALINXISEKARKIBNOMNONSAESALSASSCHSEDSESSISTALTHATHETISTORTUMUKIURAYIEZIE',
  MEO: 'WED',
  MER: 'CALCATCERELSELYESTGEDGERGESGUSIAHICEIDAINOISMISTITSKINLESLINLONMANMENMISOPEOPSRILROWTONUITULA',
  MES: 'AILCALELAELYHEDHESIADIALIANIONKEDLENODEOLEONSOREOSTPILPOTSANSEDSERSESSETSINSORSRSTEETER',
  MET: 'AGEALLALSAPHATEELYEOREPAERSHERHIDHODHOLHYLIERINGOACOPERANREDRESRIARICRONROSTARTLEUMP',
  MEW: 'ARDINGLEDLER',
  MEX: 'ICAICOITL',
  MEZ: 'AILAIRCALUZAZOS',
  MIA: 'CISMIAOUSOWSSMASMSUERULS',
  MIC: 'ASTATEELLHEDHELHERKEYKLEMACRONROS',
  MID: 'AIRDAYDENDESDLEGESGETGUTLEGPITRIBSTSTAPWAY',
  MIF: 'FED',
  MIG: 'ALEGLEHTSHTYLIONONUEL',
  MIH: 'RAB',
  MIK: 'ADOAELINGRONVAHVEH',
  MIL: 'ADIADYAGECHYDENDERDEWDLYEDHERSICEIEUIUMJEEKEDKENKERLEDLERLESLETLIENERORDPASSEYSIETEDTERTONTOSVUS',
  MIM: 'BARBLEEOSERSICSINEINGISHMEDOSAPEISEY',
  MIN: 'BARCEDCERCESCIODEDDELDERDLYERSERYGIEGLEHAGHAHIFYIMAIMIIMSINGIONISHIUMNIENOWOANORAORSTEDTERUETUTEXESYAEYANYAS',
  MIO: 'MBOSESSISTIC',
  MIR: 'ACHAGEAGYANAATEDHAFAKIAMIERIKIINGISHKERKLYLEDRORTHSZAS',
  MIS: 'ACTADDAIMATECALCUECUTDIDEATEREERSERYFITGYEHAPHITHMIIMAKALKENKINLAYLEDLENLIELINLITMETPAYPENPUTRUNSALSAYSEDSELSESSETSISSITSUSTALTEDTERTICTLETRYURAUSEWAYWED',
  MIT: 'ERSHANHERHRAIERINGOMERALREDRERRESTENTLE',
  MIU: 'RUS',
  MIX: 'ERSINGITETECUPS',
  MIZ: 'ENSPAHRAHZENZLEZLY',
  MLA: 'NGE',
  MNE: 'MICSICVIS',
  MNI: 'OID',
  MOA: 'NEDRIATED',
  MOB: 'BEDBERBIEBLECAPILEULA',
  MOC: 'HASHELKEDKERKUPOANOCKUCK',
  MOD: 'DERELSEMSENAERNESTICAIFYILIISHISTIUSREDULAULEULIULO',
  MOE: 'BLEURS',
  MOF: 'FLE',
  MOG: 'DADGANGEDGIOHANHULOTEUEYULS',
  MOH: 'AIRAVEAWKELSISMOCKURS',
  MOI: 'DERESTETYLEDLERLESLEYRAIRESSONSTY',
  MOK: 'IHISHA',
  MOL: 'ALAARSARYAVEDEDDERESTIESIFYINEINGLAHLESLIEMANMENOCHOIDTEDTENTER',
  MOM: 'BINBLEENTISHISMISTMASMERMETSERZER',
  MON: 'ACHACOACTADSASAASEAULDAYDESDOSEMEERAESTETHEYSGERGOEGOLGOSGSTIALIASICAIEDIERIESISHISMISTKEYKLYODYOIDONTOSEROESIATEMTESTHSTIATONTRE',
  MOO: 'CAHCHADERDIRDLEINGLAHLASLETLEYLUMLVINALNEDNERNETNIENJAREDRUKRUPSEYTCHTEDTER',
  MOP: 'ANEANIEDSERSERYIERINGISHLAHOKEPEDPERPETSEY',
  MOR: 'ADAAEAALEALSASSATEAYSBIDBUSCHADVAEENELSENAGANGAYGENGUEIANICEIONISHKINLOPMALMONMYRNAYNEDONEONGONSOSEPHOPHSRALRISROSROWSALSELTALTARTEMTISTONULAULEVIN',
  MOS: 'AICCHICOWEYSHAVKERLEMQUESEDSERSESSIETICTLYTRA',
  MOT: 'ELSETSHEDHERIFSILEIONIVEIVOLEYMOTORSORYTESTLETOSYKA',
  MOU: 'CHEDIEGHTILLJIKLDSLDYLINLTSLVINDSNDYNTSNTYRNERNSSEDSEESERSESSEYSLESMESSETANTHETHSTHYTONZAH',
  MOV: 'ANTENTERSIESING',
  MOW: 'ANACHTERSHAYINGRAH',
  MOX: 'IES',
  MOY: 'ITE',
  MOZ: 'ARTING',
  MPO: 'NDO',
  MTS: 'CMD',
  MUC: 'AGOAROATEHELHESHLYINSKEDKERKETKLEKNAKSYLUCOIDORSOSAOSEOUSUNA',
  MUD: 'CAPCATDEDDENDERDLEFATRAS',
  MUE: 'RMOSLITTE',
  MUF: 'FEDFERFETFINFLETIS',
  MUG: 'FULGARGEDGERGETGURUETWET',
  MUI: 'LLA',
  MUJ: 'IKS',
  MUK: 'ADEDENLUKTARTUK',
  MUL: 'ADAADICTSDERETAEYSIERINGISHISMITALAHLARLASLEDLENLERLETLEYLIDMULTUMVEL',
  MUM: 'BLEMEDMERMIAPEDPER',
  MUN: 'CHYDALDICDILDLEGERGEYGOSICHIFYITEITYSEESHISIFTIN',
  MUO: 'NIC',
  MUR: 'AGEALSCHYDEREINIDSIELINEINGITIIUMKERKLYLINMUROIDPHYRAHRALRASRAYRESREYRHATHYUXIZIM',
  MUS: 'ANGARDCAECATCIDCLECLYCOTCOWERSERYEUMHAAHEDHERHESHLAHRUICAICOICSILYINGIONIVEJIDKATKEDKEGKETKIEKITKOXLIMLINMONNUDROLSALSEDSELSESSUKTEDTEETERTHSTNT',
  MUT: 'AGEANTASEATEELYESTINEINGINYISMISTIVESJETENTERTONUALUELULEUUM',
  MUU: 'MUU',
  MUV: 'ULE',
  MUY: 'SCAUSA',
  MUZ: 'HIKJIKZLE',
  MYA: 'CEALIARIASESSIS',
  MYC: 'ELEOIDOSE',
  MYD: 'AUSINE',
  MYE: 'LICLINLON',
  MYG: 'ALE',
  MYI: 'TIS',
  MYK: 'ISS',
  MYN: 'AHS',
  MYO: 'DESGENMASPESPIAPICSESSINSISTICWUNXUS',
  MYR: 'CIAIADICAICKRHSRHYTALTLETOLTUS',
  MYS: 'ELFELLIANOIDOREOSTTAXTESTIC',
  MYT: 'HICHOIHOLHOSHUS',
  MYX: 'INEOIDOMA',
  MYZ: 'ONT',
  MZU: 'NGU',
  NAA: 'MAN',
  NAB: 'BEDBERBUKLASLUSOBSOTH',
  NAC: 'HASHUSKETREDRES',
  NAD: 'DEREEMIRS',
  NAE: 'VUS',
  NAG: 'AMIANAARAARIGARGEDGERGINGLEGLYINGMANNAGUAL',
  NAH: 'ANEANIOORUAN',
  NAI: 'ADSANTFLYGIEGUELEDLERQUETLYVERVES',
  NAK: 'HODONGULA',
  NAL: 'EDSITALAH',
  NAM: 'AREBANELYERSINGMAD',
  NAN: 'AKOCESDINDOWGCAGERGKAIGOISMKINNIEOIDPIETLE',
  NAP: 'AEAALMEADERYIERKINLESOOHPEDPERPESPIERON',
  NAR: 'COSDOODUSESHGILIALICAINEKEDRASROWWAL',
  NAS: 'ALSARDCANHIMHUAIALIEIIONKHIROLSAUTICUTE',
  NAT: 'AKAALEALSANTHANHERICAICKIONIVERAJRIXRONTERTLEURAURE',
  NAU: 'GERGHTLUMSEASETTCHTIC',
  NAV: 'AHOAIDAJOARSELSELYETAETEETYIESITE',
  NAW: 'ABSIES',
  NAY: 'AURSAY',
  NAZ: 'ARDIFYISM',
  NEA: 'KESNICPEDRBYREDRERRLYTENTERTLYVIL',
  NEB: 'ACKBEDBUKIIMRISULAULEULY',
  NEC: 'KARKEDKERTARTON',
  NED: 'DER',
  NEE: 'BORDEDDERDLEDLYDNTGERMBATUP',
  NEF: 'AST',
  NEG: 'ARAATELIGOCEROS',
  NEI: 'GHSLAHPER',
  NEK: 'KARTON',
  NEL: 'KENLIESON',
  NEM: 'EANINEPNE',
  NEO: 'NED',
  NEP: 'ALIETAHEWMANMENOTE',
  NER: 'EIDEISINEITAITEIUMOICOLIOLSVALVEDVERVESVIDVIIVUS',
  NES: 'HLYIOTKHILIASESSUSTEDTERTLETOR',
  NET: 'CHAFULHERMANMENOPSTEDTERTIETLETLY',
  NEU: 'MESMICRADRALRICRINROLRONTER',
  NEV: 'ADAELLOIDOME',
  NEW: 'ARIARKCALELSESTINGISHTON',
  NEX: 'TLY',
  NGU: 'YEN',
  NIA: 'CINGRA',
  NIB: 'BEDBERBLELICONGUNG',
  NIC: 'ELYENEESTETYHEDHERHESHILHTSKARKEDKELKERKEYKIEKLEKUMOLOOLS',
  NID: 'ANAARYDERDLEGETIFYINGIOTUDIULI',
  NIE: 'CESLLILLOVES',
  NIF: 'FER',
  NIG: 'GEDGERGETGLEGLYGOTGRAGUNHEDHERHLYHTSHTYNAYNYEORI',
  NIH: 'ILS',
  NIK: 'ENOKUD',
  NIL: 'GAIGAULEDOUS',
  NIM: 'BEDBLEBLYBUSINYMEDMERRODSHI',
  NIN: 'COMCUMETYGLEGPOONSTHS',
  NIO: 'BICBID',
  NIP: 'MUCPEDPERPLEPONTER',
  NIR: 'LES',
  NIS: 'EISNAS',
  NIT: 'ENTERSERYHERONSREDRESRICRIDRILROSRYLTERWIT',
  NIV: 'EAU',
  NIX: 'IESING',
  NIY: 'AMAOGA',
  NIZ: 'AMS',
  NOA: 'HICNCE',
  NOB: 'BERBLEBUTLEDLERLESLEYODY',
  NOC: 'AKEENTIVEKEDKETTENTISTUA',
  NOD: 'DEDDERDLEIAKOSEOUSULEULI',
  NOE: 'BCDCHOSISTIC',
  NOF: 'ILE',
  NOG: 'ADAAKUGEDGENGIN',
  NOI: 'LERRESSEDSES',
  NOM: 'ADEADSEUSIALINAINEINYISMNEM',
  NON: 'ACTAGEAIDAIRANEARYCESCOMCONEGOENEENTFATGASGODIONIUSMANMENNATOICPARRUNTANTAXUSEWAR',
  NOO: 'DLEKEDKIENEDSEDSERSESTKA',
  NOP: 'ALS',
  NOR: 'ARDATEDICIASICEITEITOKYNMALMANMEDROYSELTHSWAY',
  NOS: 'EANEMAHEDHERHESIERILYINEINGISMITESELTERTICTOC',
  NOT: 'ARYATECHYERSHALHERHUSICEIFYINGIONISTOUR',
  NOU: 'CHEGATGHTMEANALSELSES',
  NOV: 'ALEATECICELAELSENAENEIALICEITY',
  NOW: 'AYSDERHATHENHITISETHE',
  NOX: 'IAL',
  NOY: 'ADEANTFULOUS',
  NOZ: 'ZLE',
  NRI: 'TTA',
  NUA: 'NCE',
  NUB: 'BINBLEBLYIANIASILE',
  NUC: 'HAEHALLEIULAULE',
  NUD: 'ATEDLEELYENSESTGEDGERGESIESISHISMISTITYNIK',
  NUG: 'GARGETIFY',
  NUL: 'LAHLEDLOSLUMLUS',
  NUM: 'BATBEDBERBLEBLYDAHEROIDAINAINEMUSNAH',
  NUN: 'CIOCLELETNED',
  NUP: 'HARSON',
  NUR: 'AGHHAGLEDSEDSERSESSLE',
  NUT: 'ANTATELETMEGRIATEDTER',
  NUZ: 'ZERZLE',
  NYA: 'LASNJANZA',
  NYB: 'BLE',
  NYC: 'TEA',
  NYL: 'ASTGAUONS',
  NYM: 'PHAPHOPHS',
  NYR: 'OCA',
  NYT: 'RIL',
  OAF: 'DOMISH',
  OAK: 'BOYLETUMSWEB',
  OAN: 'NES',
  OAR: 'AGEIALINGIUMLOPMAN',
  OAS: 'EAN',
  OAT: 'BINEARERSHAYHED',
  OBA: 'RNERNI',
  OBD: 'UCEURE',
  OBE: 'AHSCHEISHISMLIALUSRONYEDYEOYER',
  OBF: 'IRMUSK',
  OBI: 'ISMSPOTALTER',
  OBJ: 'ECTURE',
  OBL: 'ASTATAATEIGEONG',
  OBO: 'ISTLESLETLOSLUSNGOVAL',
  OBR: 'IENIZE',
  OBS: 'EDEESSIDEIGNTET',
  OBT: 'AINECTENDENTESTUNDUSE',
  OBV: 'ERT',
  OCC: 'AMYULTUPYURS',
  OCE: 'ANSLLILOT',
  OCH: 'AVAAVOERSERYONEREAREDRESYMY',
  OCI: 'MUM',
  OCL: 'OCK',
  OCO: 'TEA',
  OCR: 'ACYEAE',
  OCT: 'ADSANEANSANTARYAVDAVEAVODRAECTENEETSILEINEOADODEOICOIDOONOPIOSEOYLROIROYUORYLSYNE',
  OCU: 'LARLLILUS',
  OCY: 'ROE',
  ODD: 'ESTISHITYMAN',
  ODE: 'LETONSSSA',
  ODI: 'BLENICOUSUMS',
  ODL: 'ING',
  ODO: 'REDURS',
  ODW: 'YER',
  ODY: 'LESLIC',
  OEC: 'ISTOID',
  OED: 'EMA',
  OEK: 'IST',
  OEN: 'ONE',
  OES: 'OGI',
  OEU: 'VRE',
  OFF: 'ALSCUTENDERSICEINGISHLAPLETPAYSET',
  OFL: 'ETE',
  OFT: 'ENSEST',
  OGA: 'IREMIC',
  OGB: 'ONI',
  OGD: 'OADOAS',
  OGH: 'AMS',
  OGI: 'VALVEDVES',
  OGL: 'ALAERSING',
  OGR: 'ESSISHISM',
  OGY: 'GIA',
  OHI: 'OAN',
  OHM: 'AGE',
  OID: 'IUM',
  OIL: 'CANCUPDOMERSERYIERILYINGISHLETMANMENWAY',
  OIN: 'KED',
  OIT: 'AVA',
  OJI: 'BWA',
  OKA: 'PIAPISYED',
  OKO: 'UME',
  OKR: 'OOGUZI',
  OKU: 'ARI',
  OLA: 'CADMIC',
  OLD: 'ERSESTIESISH',
  OLE: 'ANAARYASEATEFININEINSNIDNUSOSEOUSOYLRONUMS',
  OLF: 'ACT',
  OLI: 'BANNIAVEDVERVESVETVIAVIL',
  OLL: 'AMHOCKUCK',
  OLN: 'EYA',
  OLO: 'MAO',
  OMA: 'GRAGUAHASSUM',
  OMB: 'ERSRES',
  OME: 'GASLETLIENEDNTA',
  OMI: 'TIS',
  OMM: 'IAD',
  OMN: 'IFYISTIUM',
  ONA: 'GERGRAGRI',
  ONC: 'OMEOST',
  OND: 'INEINGULE',
  ONE: 'HOWIDAILLISMTHEYER',
  ONF: 'ALLLOW',
  ONG: 'ARO',
  ONI: 'ONSONY',
  ONL: 'AIDEPYESSINEOOK',
  ONO: 'NIS',
  ONR: 'USH',
  ONS: 'ETSIDE',
  ONU: 'SES',
  ONW: 'ARD',
  ONY: 'CHAMALXESXIS',
  OOC: 'YSTYTE',
  OOD: 'LES',
  OOE: 'CIA',
  OOF: 'IER',
  OOG: 'AMYENYLEAONE',
  OOH: 'ING',
  OOI: 'DAL',
  OOL: 'ITEITHOGYONG',
  OOM: 'IACIAKPAHPHS',
  OOP: 'ACK',
  OOR: 'ALIIAL',
  OOT: 'IDSYPE',
  OOZ: 'IERILYINGOID',
  OPA: 'CUSIONLEDQUE',
  OPC: 'ODE',
  OPE: 'LETNEDNERNLYRAERASRLARON',
  OPH: 'IANIONISMITERYS',
  OPI: 'ANEATEFEXISMLIANEDNERNESUMS',
  OPO: 'RTO',
  OPP: 'IANIDAONEOSEUGN',
  OPS: 'INS',
  OPT: 'ANTATEICSIMAIMEINGIONIVE',
  OPU: 'LUSSES',
  ORA: 'CHECLELERLLYNGENGSNGYNTERIATEDTESTOR',
  ORB: 'ATEELLINGITEITSITYLET',
  ORC: 'EINHATHELHENHICHIDHILHISINEINS',
  ORD: 'AINEALENEERSURE',
  ORE: 'ADSGONIDEJONMANMUSNDATICXINXIS',
  ORF: 'RAY',
  ORG: 'AMYANAANSANYASMEATIACIESONEUILYIA',
  ORI: 'ANSBISELSENTGANGINHONOLESON',
  ORK: 'HON',
  ORL: 'AGEEANOPS',
  ORM: 'AZDERSOLUOND',
  ORN: 'ARYATEERYIFYITH',
  ORO: 'GENIDE',
  ORP: 'HANHICINCINEINS',
  ORR: 'ERYICE',
  ORS: 'EDE',
  ORT: 'HALHICHIDHISIGAIVEMANRUD',
  ORW: 'ELL',
  ORY: 'XES',
  OSA: 'GESMIN',
  OSC: 'ARSINEULAULE',
  OSE: 'LLALLE',
  OSI: 'ERSERYRIS',
  OSM: 'ATEICSINAITEIUMOLSONDOSEOUSUND',
  OSO: 'PHY',
  OSP: 'ERMOREREY',
  OSS: 'EINIANIFY',
  OST: 'ARAEALEINENDENTIALIUMLERMENOMYRCAREARYASISYAK',
  OSW: 'ALDEGO',
  OTA: 'LGYRIA',
  OTE: 'LLO',
  OTH: 'AKEERSMAN',
  OTI: 'ANTDAEDESDIAOSETICTIS',
  OTO: 'SISTOI',
  OTT: 'ARSAVAAVEAWAERS',
  OTU: 'RIA',
  OUA: 'NGA',
  OUC: 'HES',
  OUG: 'HTS',
  OUN: 'CES',
  OUP: 'HES',
  OUR: 'ALIANGARIEBIOUBSEL',
  OUS: 'ELSTEDTEETER',
  OUT: 'ACTADDAGEASKATEAWEBANBARBATBEGBIDBOWBOXBUDBUYBYECRYCUTDIDEATERSEYEFEDFITFLYFOXGASGUNHERHITHUEHUTINGISHJETJUTLAWLAYLEDLERLETLIELIPLOTMANMENPAYPOPPRYPUTRANRAPRAYRIBRIGROWRUNSATSAWSAYSEASEESETSINSITSPYSUMTOPVIEWARWAYWINWITWOE',
  OUV: 'ERT',
  OUY: 'EZD',
  OUZ: 'ELS',
  OVA: 'LLYMBOMPORIARINTED',
  OVE: 'NEDNLYRBYRDOREDRGORLY',
  OVI: 'BOSDAEGERNAENESNIASAC',
  OVO: 'IDSLOSNIC',
  OVU: 'LARLESLUM',
  OWE: 'LTYNIARBY',
  OWH: 'ERE',
  OWL: 'DOMERYETSINGISHISM',
  OWN: 'ERSING',
  OXA: 'CIDLANLICLISLYLMICMIDNICZIN',
  OXB: 'ANEIRDOWS',
  OXC: 'ART',
  OXE: 'ATEOTEYES',
  OXF: 'ORD',
  OXG: 'ALLANGATEOAD',
  OXH: 'EADEALERDIDEOFTORN',
  OXI: 'DESDICMES',
  OXL: 'ANDIKEIPS',
  OXO: 'NIC',
  OXR: 'EIM',
  OXS: 'HOEKIN',
  OXT: 'AILERS',
  OXW: 'ORT',
  OXY: 'AZOGASGENGONMELOPY',
  OYE: 'LET',
  OYS: 'TER',
  OZA: 'ENA',
  OZO: 'ENANEDNERNESNICNID',
  PAB: 'BLELUM',
  PAC: 'ANEATEAYACHAERSHAKHASIFYINGKEDKERKETKLYOTATUM',
  PAD: 'ANGAUKDEDDERDLEEYEIGEINALESNAGOUKRESSAWUAN',
  PAE: 'ANSGELGLELLAONSONYPAE',
  PAG: 'ANSERSGLEINAINEINGNESODAODSRUSUMA',
  PAH: 'ARI',
  PAI: 'CHEDLEGLEKEDKERLOOLOULOWNCHNEDNTSNTYOCKREDRERRLESANSASUTEZED',
  PAJ: 'AMAEROOCK',
  PAK: 'AWAEHA',
  PAL: 'ACEACHAICAISAKAALAAMAAMEANKATEEAEEALELYEOGEONESTETSETZFRYGATIERILAINGISHKEELAELAHLARLASLEDLETLIALIDLORMADMAEMARMEDMERMICMINMUSOLOOMAOURPALPEDPONPUSTERTRYUDEULEULI',
  PAM: 'ELAENTIRIPASPERPRE',
  PAN: 'ACEADAADEAKAAMAARYDALDANDARDASDERDITDLEELAELSFILFRYFULGASGEDGENGWEHASICSIERIMEINIIONISCISKKINMANMUGNAGNAMNEDNELNERNESNUMNUSOANSITTASTEDTERTIETLETODTONTOSTRYTUNUNGUREYARZER',
  PAO: 'PAO',
  PAP: 'ACYAGOAINAIOANEAWSAYAERNERSERYESSIERIONISHISMISTIZEPEAPOXPUSREGUANULAULEYRI',
  PAQ: 'UET',
  PAR: 'ADAADEADOAGEALEAMOANGAPHATEAVACAECELCHYDAHDALDAODEDDEEDIEDONECYEILEJAENSENTERSEUSEVEFEYFUMGEDGESGETGOSIAHIALIANICAIESIETIFYINEINGISHITIITYKASKEDKEEKERKINLAYLEDLESLEYLIALORMAKNASNELOCHODEODIODYOLEOLIOLSOUSPALPENRAHRALREDRELROTSECSEDSEESERSESSICSONTANTEDTENTERTESTIETIMTISTLYTONURAUREVIS',
  PAS: 'ANGCALCHAEARELAENGEOSEWAHASHEDHESHIMHKAHTOSAYSEDSEESELSENSERSESSIMSIRSUSTASTEDTELTERTESTILTISTORTRY',
  PAT: 'ACAACOAKAANAANDARTATACHYEFYENSENTERAERSESIGIAHANHEDHICHOLHOSINAINEINSIOSISEMOSOISOLARIARINRIXROLRONTEDTEETENTERTIETLETOOUCAWIN',
  PAU: 'CALLARLIELINLUSNCHPERSAISALSEDSERSES',
  PAV: 'ADEAGEANEANSEEDERSIERIESINGINSIORISELOVOISONE',
  PAW: 'ERSINGNEDNEENERNIENORPAW',
  PAX: 'WAX',
  PAY: 'BOXDAYEESENYERSESSINGNIMOFFOLAONGORSOUT',
  PAZ: 'END',
  PEA: 'CEDCESCHYCODGESHENKEDKERLEDLERNUTPODRCERCHRLSRLYSENSESSONVEYVIE',
  PEB: 'BLEBLY',
  PEC: 'ANSHANHAYHEDHYSIFYITEKEDKERKETKLEKLYORATENTICTINTUS',
  PED: 'AGEALOALSANTARYATAATEDERDLEIADIALIONLARLERROSULE',
  PEE: 'INGKEDLEDLERNEDNGEPEDPERPULREDRIERLYVEDVERVESWEEWIT',
  PEG: 'ALLBOXGEDGERGLELETMANMENUAN',
  PEI: 'NEDSEDSERSESTHO',
  PEK: 'ANSINGINSOES',
  PEL: 'ADEADOAGEEANENGEUSHAMIASICKIKEIOMITELARLASLERLETMETOIDOPSOTATAETEDTERTRYUDOUREVESVICVIS',
  PEN: 'AEAANGCELCEYCHECILDEDDLEDOMEIDESTFULGOSGUNIALIDEILELOPMANMENNAENEDNERNETNEYNIANISNONOUNSEESILSUMTADTITTOLTYLULTURY',
  PEO: 'NESPLERIA',
  PEP: 'FULINOLOSLUMLUSPEDPERPINSINSISTICTID',
  PEQ: 'UOT',
  PER: 'ACTCHACHECIDCURDIEDITDIXDUEDUSEANEIAFAYFINFIXICUILSINEIODISHITEKEDKINLIDMITMIXNEANELNIONISNODNORNYIOBAONEOXYPETRIERONSAESEASESSIASICSIOSISSONSUETENTERTLYUKEULAULEUSE',
  PES: 'ACHADEAGECODETAEWAHWATERTISTLE',
  PET: 'ALSALYARAARDARYATEECAERSFULHERITEITSKINREARELRIEROGROLTAHTEDTERTLEUNE',
  PEU: 'CYLMUS',
  PEW: 'AGEDOMEESFULINGITSTER',
  PEY: 'OTEOTLTON',
  PEZ: 'IZA',
  PFU: 'NDE',
  PHA: 'EDOGESJUSLLINARNICNOSROSSEDSERSESSICSISSMASORTIC',
  PHE: 'CDAEALMICMIENICNINNIXNOLNOMNYL',
  PHI: 'ALEALSLIALICLIPLOLLOSPPEZESZOG',
  PHL: 'EGMEUMOEM',
  PHO: 'BIABICBOSCALCIDEBELADLASNALNEDNERNESNETNEYNICNOLNONNOSOEYOKARIARIDSISSSYTALTICTOGTOMTONTOS',
  PHR: 'ASEASYYMA',
  PHT: 'HOR',
  PHU: 'LWA',
  PHY: 'CICLAELARLICLONLUMMASSESSICSIDSISTICTINTOLTONTYL',
  PIA: 'CHECLEFFELYNNETNICNKANOSROATTIZINZZAZZE',
  PIC: 'ARAARDAROARYEINENEHEYINEKAXKEDKEEKELKERKETKINKLEKUPNICOIDONGORYOTEOTSRICRISROLRYLTUNUDAUDOULEULS',
  PID: 'DLEGIN',
  PIE: 'CEDCENCERCESDLYDRAGANINGLETLUMMAGMANPANRCERIDRISRRETASTERTICTON',
  PIF: 'EROFLEINE',
  PIG: 'DANDOMEONFULGEDGIEGINGLELETMANMEWNETNONNUSNUTPENSTY',
  PII: 'TIS',
  PIK: 'AKEERSING',
  PIL: 'AFFAFSAGEARYATEAUSAWSERSEUMEUPEUSFERFREGERIESINEINGLARLASLEDLERLETLOWORIOSEOTIOTSOUSPAIPAYPULULAULE',
  PIM: 'ENTOLAPEDPLAPLEPLOPLY',
  PIN: 'ANGARDATACERCHEDALDEREALENEERYETAGEDGERGLEGOSGUEIERINGIONITEJRAKEDKENKERKEYKIEKLYKOSMANNAENALNASNEDNELNERNETOLEONSSONTASTIDTLETOSUPSXITYINYON',
  PIO: 'LETNEDNICTEDTTYURY',
  PIP: 'AGEALSERSERYETSIERILEILOINGIRIITSKINPEDPENPERPINPLE',
  PIQ: 'UEDUESUETUIAURE',
  PIR: 'ACYANAATEATYAYAENELIENEDNERNIEOGIOOTRIE',
  PIS: 'ACAANGCESCIDCISGAHHEDHESKUNOTESEDSESTIATICTILTLETOLTON',
  PIT: 'AYACHICHYHEDHESHOIHOSIEDIERIESMANMENONSPANPITRISSAWTEDTERURI',
  PIU: 'PIU',
  PIV: 'OTS',
  PIX: 'ELSIES',
  PIZ: 'AZZZASZLE',
  PLA: 'CEDCERCESCETCIDCITCKSCUSGAEGALGESGUEGUYICEIDSIDYINSINTINYITSKATNARNCHNEDNERNESNETNGINKSNKYNTANTSNUMQUESHYSMASMSTANTCHTEATEDTENTERTESTICTIETLYTTYTYSYASYEDYERYTEZAS',
  PLE: 'ACHADSASEATSBBYBESDGEIADIONNTYNUMURAVINWCHWGHXALXORXUS',
  PLI: 'ANTCAECALERSGHTNKSNTHSKYSSETCH',
  PLO: 'DGEIDYIMANKONKSTCHTTYUGHUKYVERWEDWERYED',
  PLT: 'ANO',
  PLU: 'CKSCKYFFYGGYMBSMEDMERMESMETMMYMPSMPYNGENGYNKSRALRELSESSHYSIATEITONTUS',
  PLY: 'ERSING',
  PNE: 'UMAUME',
  POA: 'CHYLES',
  POB: 'EDY',
  POC: 'HAYILLKEDKET',
  POD: 'DEDDERDIADLEEONGERIALITEIUMLERLEYSOLTIAUNKURAZOL',
  POE: 'METSIESISTICTLYTRY',
  POF: 'FLE',
  POG: 'EYSIESROM',
  POI: 'ANALUSNDSNTENTSNTYSEDSERSESSON',
  POK: 'ERSEYSIERIESILYINGOMOUNT',
  POL: 'ACKANDARSARYDEREAXEISERSEYNIADIANICEICYIESINGIOSISHITEITYKASLAMLANLEDLEELENLERLETLEXLOILUXONYSKAYADYOLYPIYPSYVE',
  POM: 'ACEADAADEANEARDARYATEATOELOELYEYSMEEMELMERMETMEYOLOONAPALPEYPOMPON',
  PON: 'CESCHODERDOKDUSENTERAGEEGIDICAIEDIERIESTACTALTEETESTICTILTINTONTUS',
  POO: 'DERDLEGYEHEDJAHKOOLEDLERNACNAHNCENGAPEDRERRGARISRLY',
  POP: 'EANELYERYESSEYEGUNIANIFYISHJOYLARLETLINODEPASPEDPELPERPETPINPLEPLYULI',
  POR: 'AILETTGERINAINGIONISMITEKERKETKINNOSOMAOSEOUSRETTALTASTEDTERTIATIOTLYTORULE',
  POS: 'ADAERSEURHERHLYIEDIESINGITSNETOLEOLOSESSETSIESUMTALTEATEDTELTERTICTIETILTIN',
  POT: 'AGEAILASHASSATEATOBOYDAREENENTEYEFULGUNGUTHERHOSIONLEGLIDMANMENONGOOSPIESIETAHTEDTERTLETOSTUR',
  POU: 'CERCEYCHYFEDFFEFFSLETLPELTSNCENCYNDSREDRERRIESERSSETEDTER',
  POW: 'CATDERDRYERSNIETERWOW',
  POX: 'ING',
  POY: 'OUS',
  PRA: 'AMSBHUGUEHAMHUSISEISSJNANCENCYNGSNKSNKTNKYSESTALTAPTEDTERTESTEYTTYVINWNSWNYXESXISYEDYER',
  PRE: 'ACEACHACTAGEAMPARMBIDCELCESCISCOXCUTDAYDRYENSEZEFABFERFETFIXLAWLIMMANMEDMENMIAMIEMIOMITMIXPAYPPYSAWSAYSEESERSESSETSTOSTSSULTANTAXTERTILTORTRYTTYVOTVUEWARXESYEDYER',
  PRI: 'APICEDCERCESCEYCKSCKYDEDDESERSESTLLSMALMARMASMEDMERMESMLYMOSMPSMUSNCENKSNKYNOSNTSORIORSORYSALSEDSESSMSSMYSONSSYTCHVETZEDZERZES',
  PRL: 'ATE',
  PRO: 'ACHARTBALBEDBERBESBITCNEEMSFERFITFREGNEJETKERLANLEGLESLIALINLIXLOGLYLMICMITMPTNGSNGYNICNPLNTOODEOFSOFYPELPERPIOPOSPUSPYLREXRSASALSARSEDSERSESSITSOSSSYTAXTEATEITIDTONTYLVEDVENVERVESWARWEDWERWLS',
  PRU: 'DESIGONEDNERNESNUSTAHTOT',
  PRY: 'ERSINGLER',
  PSA: 'LISLMSLMY',
  PSE: 'TTAUDO',
  PSH: 'AWS',
  PSI: 'LOI',
  PSO: 'CIDRICVIE',
  PSU: 'EDO',
  PSY: 'CHECHOCHSLLAWAR',
  PTE: 'LEARICRINRISRNARON',
  PTI: 'NIDNUSSAN',
  PTO: 'SESSISTIC',
  PTY: 'XIS',
  PUB: 'BLEIANLIC',
  PUC: 'KERKLE',
  PUD: 'DEEDERDLEDLYENTSEY',
  PUE: 'BLORERRTO',
  PUF: 'FEDFERFINFTN',
  PUG: 'DOGGEDGERGLEGRYMANREE',
  PUI: 'SNESNY',
  PUJ: 'ARI',
  PUK: 'EKAEKOINGISHRAS',
  PUL: 'AYAERSEYNIANINGIOLISHLEDLENLERLETLEYLUSPALPARPEDPERPITQUESARSEDSERSESSUSTONTUNVICVILWAR',
  PUM: 'ELOICEMELPEDPERPETPLE',
  PUN: 'AMUCHYDITDUMECAESEGARGERGEYGIEGLEGYIICAIERILYISHJUMKAHKASKERKEYKIEKINLETNEDNERNETNICTALTEDTELTERTILTOS',
  PUP: 'ATEELOILSOIDPEDPETPISULO',
  PUR: 'ANADAHDASDONEEDEESELYESTFLEFLYGEDGERGESIFYINEINSIRIISMISTITYLEDLERLINPIEPLEPLYRAHREDREERELRERSEDSERSESSETSUEUHAVEYVOE',
  PUS: 'GUTHEDHERHESHTUHUMHUPILLLEYSESSLYZTA',
  PUT: 'AGEAINEALELIHERINGLOGOFFOISONSOUTRIDSCHTANTEDTEETERTIETOOURE',
  PUZ: 'ZLE',
  PYA: 'LLA',
  PYC: 'NIANICNID',
  PYE: 'LICMIAMIC',
  PYG: 'ARG',
  PYJ: 'AMA',
  PYK: 'NIC',
  PYL: 'ONSORI',
  PYN: 'UNG',
  PYO: 'SESSIS',
  PYR: 'ANSENAENEGOMITEOBIOIDOLAONEOPERHAROLRYLULAUWL',
  PYT: 'HIAHICHON',
  PYU: 'RIA',
  PYX: 'IES',
  QAN: 'ATSTAR',
  QAS: 'IDA',
  QIN: 'DARTAR',
  QIV: 'IUT',
  QUA: 'CKSCKYDERDLEDRAEREFFSGGAGGYHOGICHIFEIGHILSILYINTKEDKERKESLIALLYLMSLMYNDONDYNGONTANTINTSPAWRKSRLERRYRTARTERTORTSRTZSARSHYSKYTCHTRETTYVERYED',
  QUE: 'ACHANSASEASYAZYBECDLYENSERSERYESTEVEINGLCHLEALLSLMENCHNDARESRNSRRESALSTSTCHTHEUEDUERUESZAL',
  QUI: 'APOBUSCHECKSDAEDAMETAETIETSFFSINALESLEZLLSLLYLTSNASNCENCHNCYNETNIANICNINNOANOLNONNSENSYNTANTENTONTSNUANYLNZEPPEPPUPPYPUSREDRESRKSRKYRTSSBYSLETCHVERZZY',
  QUO: 'HOGINSITSKKARUMTASTEDTEETERTESTHATIDTUM',
  QUR: 'USH',
  RAA: 'NANSCH',
  RAB: 'ANDATOBANBETBINBISBITBLEFAKIESLIN',
  RAC: 'CHEEMEERSHELHESHETHISIALIERILYINGIONISMISTKANKEDKERKETKLEONSOON',
  RAD: 'ARSDEDDLEEAUEURIACIALIANIONIOSIOVISHIUMIUSMANOMEONSULA',
  RAF: 'AELALEFEEFIAFLETEDTER',
  RAG: 'BAGEESERYGEDGEEGERGILGLEINGLANLETLINMANMENNAROUTTAGULEULY',
  RAH: 'DAR',
  RAI: 'DEDDERLEDLERLLYNEDNERNESOIDSEDSERSESSINSONYAT',
  RAJ: 'AHSEEVESHPUT',
  RAK: 'EESELYERSERYHALIJAILYINGISH',
  RAL: 'ISHLUSLYE',
  RAM: 'ACKADAAGEARKASSATEBEHBLABLEEALEANEESENTESHETSIESIFYIROISMISTJETMEDMELMERNESONAOONOSEOUSPEDPERRODSCHSEYSONTILULEUSI',
  RAN: 'CELCERCESCHECHOCIDCIOCORDALDANDEMDERDIADIEDIRDLEDOMDONEESEREGEDGERGESGEYGLEIDSINAINEJITKEDKERKETKLEKLYNELSELSESSOMTANTEDTERULA',
  RAP: 'ELYERSEYEHAEHESHIAHISHUSIDEIDOIDSIERINEINGISTORTPEDPEEPELPENPERTERTLYTORTUS',
  RAQ: 'UET',
  RAR: 'EFYELYESTETYIFYINGISHITY',
  RAS: 'ANTCALERSHERHESHLYHTIINGIONOIROURPEDPERPISSLETERTIKTLETUSURE',
  RAT: 'ALSANSANYBAGEENELSEROERSHEDHERIFYINEINGIONIOSITELINOONTANTEDTELTENTERTLETLYTONTUS',
  RAU: 'CIDGHTKLENGEQUE',
  RAV: 'AGEELSENSERSERYINEINGINSISH',
  RAW: 'ESTINGISHNIE',
  RAX: 'ING',
  RAY: 'AGEAHSFULINGLETONS',
  RAZ: 'EEDEESERSINGORSOURZEDZERZESZIAZLEZLY',
  RBO: 'UND',
  RCL: 'AME',
  REA: 'BLECHYCTSDDSDERGANGINLERLESLIALLYLMSLTYMEDMERPEDPERREDRERRIIRLYRMSSONSTYTASTUSUTEVEDVERVESVOW',
  REB: 'ACKAITAKEALEANKASEATEATOAWLBESEAREATECKECSELSENDENTIASIDSILLINDITELOTLOWLUEOILOKEOLDOLTONEOOKOOTOPSOREORNOSAOSOOTEOZOREDREWUFFUKEULKUOYURNURYUSHUSYUTEUTS',
  REC: 'ADOAGEALKALLANEANTAPSARTASEASHASTCHEEDEENTEPTESSHALHARHAWHEWHIPIDEIPEITEKEDKLAKONLADOALOATOCKOCTODEOILOINOKEOMBONDONSOOKOOLOPYORDORKOSTOUPOURREWROPTALTORTOSTUMTUSULEUMBUREURLURSUSEUSFUTS',
  RED: 'ACTAMEANSAREARNARTATEAUBAWNBAYBUDBUGCAPDEDDENDERDLEEALEARECKEEDEEMEFYELYENYEYEFINIAEIALIASINGIPSIPTIVELEGOCKOESONEOOMOUTOWARAGRAWREWRUGTABTOPUCEUCTUITUPLUSTWUDYEDYES',
  REE: 'ARNBOKCHOCHYDEDDENDERDITFEDFERKEDKERLEDLERMITNGEPERSLESTSSTYTAMTLEVEDVES',
  REF: 'ACEAITALLECTEEDEELELLELSELTERSETEFEDFOSILEILLILMINDINEIREITSLAGLEELETLEWLEXLOGLOWLUXOLDONTOOLOOTORDORMRIGUELUGEUNDURLUSEUTE',
  REG: 'AINALDALEALOARDAVEEARENTESTGAEGIEIANIFTILDILLILTIMEINAIONIRDIUSIVELETLOWLUENALNUMRABRETREWRIPROWULAULIUSH',
  REH: 'AIRALEANGARMASHAULBOCEADEALEAPEAREATEELEMSETEIDEIREONEOODOOKOOPUNG',
  REI: 'CEDGNSNEDNERTERVEDVERVES',
  REJ: 'AILANGECTERKOINOLT',
  REK: 'EYSHTIICKILLINGISSNITNOTNOW',
  REL: 'ACEADEAIDAISAMPANDASTATAATEAYSBUNEADEAPENDENTETSEVEEVYICKICSICTIDEIEDIEFIERIESIFTIMEINEINKISHISTIVEOADOANOCKONGOOKOSEOSTOVEUCEUCTUME',
  REM: 'ADEAILAIMAINAKEANDANSAPSARKASKASSASTBLEEDEEDYEETELTENDENEICAILLINDINTISEISSITSIXTOCKOLDORAORDOREOTEOVEUDA',
  REN: 'AILAMEATEDEDDEREGEEWSGUEIGSINSISHNERNETNINOIRONEOVEOWNTALTEDTEETERTESULEVOIVOY',
  REO: 'ILSMITPEN',
  REP: 'ACEACKAGEAIDAIRALEANDARKARTASSASTAVEAWNAYSEALEATELSENTERKICKILEINEINSIPEKIELANLAYLODLOTLOWLUMOLLONEOPEORTOSEOSTOURPEDRAYROSUFFUGNUMPUREUTE',
  REQ: 'UINUITUIZ',
  RER: 'ACKAILAKEANKATEEADEELENTINGISEOBEOLLOOFOOTOPEOSEUNS',
  RES: 'ACAACKAIDAILALEALTAVEAWNAWSAYSCANCUEEALEAMEATEAUECTEDAEEDEEKEENEESELFELLENDENEENTETSEWNEWSGATHESHEWHIPHODHOEHOTHOWHUNHUTIDEIDSIFTIGHIGNILEINAINGINKINSINYISTIZEKEWKINLAYLOTNAPNUBOAKOAPOILOLDOLEORBORTOWNOWSPANPINPOTPUETABTEDTEMTEPTERTESTIOTIRTISTOWUCKUITULTUMEWIM',
  RET: 'ACKAILAINAKEALKAMAAMEAPEARDAREEARELLEMSENEENTESTHAWHERIALIEDIERIESILEILLIMEINAINTIRELEDOLDOMBOOKOOLOREORNORTOSSOURRADRALREERIMRIPRODROSROTRUETEDTERUBEUCKUNDUNEURFURNUSEYPE',
  REU: 'BENRGESEDSES',
  REV: 'AMPARYEALEILELSENDENTERBEREERSERTERYESTETEETOETSIEWILEISEIVEOIROKEOLTOTEUESVED',
  REW: 'ADEAKEALLARDARMARNASHAVEEAREDSELDENDINDINGINSIREISHOKEOODORDOREORKOVERAP',
  REX: 'INE',
  REY: 'OKESON',
  REZ: 'ONE',
  RFO: 'UND',
  RHA: 'CHIGONPHEPISSON',
  RHE: 'BOKDAEDASNEANICSISSUSTORUMSUMYXESXIAXIS',
  RHI: 'BIANALNOS',
  RHO: 'DESDICMBIMBSNDATIC',
  RHU: 'MBAMBSSES',
  RHY: 'MEDMERMESMICNIASSATHMTONTTA',
  RIA: 'LTOLTYNCYTAS',
  RIB: 'ALDANDBEDBERBETBLEBONHUSIBELETOSEOSOOZAOZO',
  RIC: 'CIAERSHENHERHESHLYINEINGINSKEDKERKETKEYKLERACTALTUS',
  RID: 'DAMDEDDELDENDERDLEEAUENTERSGEDGELGERGESGILINGLEY',
  RIE: 'VER',
  RIF: 'ARTELYESTFEDFLEIANLEDLERLESTEDTER',
  RIG: 'GALGEDGERGOTHTOHTSHTYLETNUMOLEORSOURSBY',
  RIK: 'ARISHA',
  RIL: 'AWAINGLEDLESLETLOW',
  RIM: 'ATEERSERYIERINGMEDMEROSEOUSPLEULA',
  RIN: 'CONDEDDLEGEDGERGLEKERNERSEDSERSES',
  RIO: 'TEDTERTRY',
  RIP: 'ELYENSESTGUTIERINGOFFOSTPEDPERPETPITPLEPLYPONRAPSAW',
  RIS: 'ALAERSHISINGKEDKERPERQUESELSERSLESOASOM',
  RIT: 'ARDELYTERUALZES',
  RIV: 'AGEALSELLERSERYETSINAINGOSE',
  RIY: 'ALS',
  RIZ: 'ZARZERZLEZOM',
  ROA: 'DEDDERMEDMERREDRERSTS',
  ROB: 'ALOANDBEDBERBINERDERTHAHINGINSLESOMBOTSUST',
  ROC: 'HEAHERHETKATKEDKERKETOCOOLO',
  ROD: 'DEDDENDERDINENTEOSGERHAMINGLETMANMENNEY',
  ROE: 'MER',
  ROG: 'EROERSGLENONUEDUES',
  ROH: 'UNA',
  ROI: 'LED',
  ROL: 'ANDLEDLERLEYLIX',
  ROM: 'AICAINAJIANAANEANOANSANYBLEBOSEROISHNEYPEDPEEPERYKO',
  RON: 'ALDCETCHOCOSDELDLEDOSIERIONNELYON',
  ROO: 'DLEFEDFERINGKEDKERKIEKUSMEDMERMIEMTHSEDSERSESSTSSTYTEDTERTLEVED',
  ROP: 'ANDANIERSERYIERILYINGISH',
  ROQ: 'UERUESUET',
  ROR: 'IPA',
  ROS: 'ACEARYBIFCIDCOEEALERYETSETYIEDIERILYINEINGINSINYOLISERTELTERTRA',
  ROT: 'ALAANGARYATECHEGUTHERORSTANTEDTENTERTLEULAUNDURE',
  ROU: 'BLECHECOUDASENSGEDGESGHSGHTGHYMANNCENCYNDSNDYNGEPEDPERPETPIEPITSEDSERSESSTSTEDTERTESTHSTHY',
  ROV: 'ERSING',
  ROW: 'ANSELSENAENSERSINGLETLEYTEDTHS',
  ROX: 'ANAANE',
  ROY: 'ALEALSENA',
  ROZ: 'ZER',
  RRH: 'IZA',
  RUB: 'ACEATOBEDBEEBERBIOBLEBLYEFYENSIANIEDIERIESIFYIGOINELESLISOUTRIC',
  RUC: 'HESKEDKERKLEKUS',
  RUD: 'DERDLEELYERAESTISHITYOLFOUS',
  RUE: 'FULLLE',
  RUF: 'FEDFERFESFINFLEFLYOUSTER',
  RUG: 'ATEGEDGERGLEINEOSAOSEOUS',
  RUI: 'NEDNER',
  RUK: 'BAT',
  RUL: 'ERSINGLER',
  RUM: 'AGEBASBLEBLYDUMENSINAKINMERMESMLENEYORSOURPADPERPLEPLYPOTPUS',
  RUN: 'DELDLEITEKLEKLYLETMANNELNERNETOFFOUTRIGTEDTEEWAY',
  RUP: 'EESERTIAHIALPIA',
  RUR: 'BAN',
  RUS: 'CUSHEDHEEHENHERHESINEKINSELSETSIASUDTEDTICTLETLYTREWUT',
  RUT: 'ATEHERILETEDTEETERTLEULI',
  RWO: 'UND',
  RYA: 'NIA',
  RYK: 'ING',
  RYO: 'KAN',
  RYP: 'ECK',
  RYT: 'INA',
  RYU: 'KYU',
  SAA: 'NEN',
  SAB: 'ALOANABATBEDECAERSIANICUINAINEINGINOINSIRSLESORAOTSRASREDRESUJA',
  SAC: 'ATEBUTCHACLICOSCUSELAHEMHETIANKEDKENKERKETOPEQUERADRALREDRUM',
  SAD: 'DENDERDHUDIKDLEHESHUSISMISTITE',
  SAE: 'IMATERUME',
  SAF: 'ARIAVIELYESTETYINEINIROLTLY',
  SAG: 'AIEBUTEERELYENEESTGARGEDGERGONIERINAINGOIN',
  SAH: 'ARAIBSRAS',
  SAI: 'CESGASGONLEDLERLORLYENEDNTENTSRLYRVETHETICYID',
  SAJ: 'OUS',
  SAK: 'EENELLERSIEHKOIKOS',
  SAL: 'AAMADAADEADSAGOAMEAMIAMOARYDIDELEEMAEPSIANIFYINAINEISHITEIVALEELETLOOLOWMINMISMONOLSOMEONSOONOOPPAEPASPIDTEDTEETENTERTIETLYTUSUDAUGIUKIUNGUTEVEDVERVESVIAVORVOSWEYWIN',
  SAM: 'ADHANIARABALBARBASBELBOSBUKBULBURECHEKHEKSELYFOOGHAIANIELIRIISHITEITILETMELMEROANOHUORYPANPLESAMSHUSONUCUUELUINVAT',
  SAN: 'ANDCHECHOCTADAKDALDANDEDDERDHIDIADIPDIXDRADYXELYESTGAHGARGASGEIGERGHAGHOGHSGILGIRIESIFYINGITYJAKJAYJIBKHANOPNUPSARSEITALTARTEETIITIRTOLTONTOSZEN',
  SAP: 'ELEFULHIEIAOIUMONIORSOTAOTEOURPEDPERPHORINSAP',
  SAR: 'AADADAANSAPECELCLEDARDELEESGESGOSGUSINSKARKITLACLAKLYKODEODSONGRAFROWSARSENSONTORWANZAN',
  SAS: 'ANIHAYHEDHESINEINSSAKSANSEDSESTRA',
  SAT: 'ANGARAEENINEINGINSINYIONIREIVAIVEORIRAERAPRONSOPTARTIETLETVAURAURNURYYRS',
  SAU: 'CEDCERCESCHSDISGERGHSGHTGHYLGELIELTSMONMURMYANASNCYQUIRELRIATEDTERTES',
  SAV: 'ACUAGEANTARAATEERSERYILEINEINGINSIOROLAORSORYOUROYS',
  SAW: 'ALIBWADERERSFLYINGISHLOGMANMONNEBNEYNIEPITWAYYER',
  SAX: 'AULISHONSONYTENTIE',
  SAY: 'ERSESTIDSINGNAYYID',
  SBI: 'RRO',
  SBL: 'OOD',
  SCA: 'BBYBIABIDEANENAFFYIFELAELARLDSLDYLEDLERLESLETLFELLSLMALPSMPIMPSNCENIANICNTSNTYPEDPELPESPHAPHEPUSRABRCERCYRDSREDRERRESREYRFERFSRFYRIDRPARPERPHRPSRRYRTHRTSRUSTCHTHETHYTTSTTYUPSURSVELZON',
  SCE: 'NASNDSNESNICNTSRNE',
  SCH: 'ANZARFAVSEATELMEMAEMEEMYENEERMICKISMISTIZOIZYLEPMOENOZOLAONEOOLOONORLOUTOUWRIKUHEUITULEULNUSSUTEUYTWAS',
  SCI: 'AGEARAATHENTLLAONSOUSVVY',
  SCL: 'AFFATEENTERAEREIFFIMB',
  SCO: 'BBYDGYFFSGIELDSLEXLEYLIALOCLOGNCENESOCHOPSOTSPEDPESPETPICPUSRCERCHREDRERRESRIARNSRNYRSERZATALTCHTERTIATICTTYUCHURSURYUSEUTHUTSVELWEDWLS',
  SCR: 'ABEAGSAMBAMSANKAPEAPSAPYATHAWKAWLAWMAYEAZEEAKEAMEAREEDEEKEELEENEESEETEVEEWSEWYIBEIDEIKEIMEIMPIMSIMYINEIPSIPTITEIVEOBEODSOFFOGSOLLOOPOTAOUTUBSUFFUFTUMPUMSUNTUSHUTOUZEYER',
  SCU: 'BASDDYFFSFFYLCHLKSLLSLPSLPTLSHMMYNGENGYRDYRFSRFYRRYRVYSINTALTCHTELTESTTATTYTUMZZY',
  SCY: 'LLAPHAPHITHE',
  SDE: 'ATHIGN',
  SEA: 'BAGBEDBEEDOGLCHLEDLERLETMANMASMEDMENMERMEWMUSNCERCERCHREDRERSANSONTEDTERTHEWANWAY',
  SEB: 'AGOAITATEKHAUMS',
  SEC: 'ALEANTCOSEDEERNESHESSKELOHMONDPARQUERETTORUNDURE',
  SED: 'ANGANSATEENTERSGEDGESILEUCEUCTUMS',
  SEE: 'DEDDERINGKERLEDMEDMERMLYNIENILPEDSAWSEETHEWEE',
  SEF: 'TON',
  SEG: 'GARGEDGIOHOLNOSUEDUES',
  SEI: 'CHEDELMASNEDNERNESRENSEDSERSESSINSMSSORZEDZERZESZINZOR',
  SEJ: 'ANTEROOINOUR',
  SEK: 'ANEANIERE',
  SEL: 'AGOAHSDENDOMDORECTENAENEETYFEDFLYINAINGIONJUKLARLERLESLIESYNTERUNGVES',
  SEM: 'ANGBLEEEDEIAEMEENSENTESEIANIFYINAITAITEMELMETMITNAEOISOLAPERPLEPRESEMSEN',
  SEN: 'AAHAGEARYATECIODALDEEDERDLEECAEGAHORILEIORITIIUMLACNASNETNITORAORSSALSEDSESSORSUMSUSTRYUFOUSI',
  SEP: 'ALSAWNHENIAEIANIASIONIUMONEOSEOYSSESSIDSINSISTALTANTETTICTUMULT',
  SEQ: 'RCHUANUELUIN',
  SER: 'ACSAILAISANGAPEAPHAYABIACOMDABDAREANEINENAENEENOESTGEIGERGESGIOGIUIALIANIESIFSINEINGINSMONOONOOTOSAOSEOUSOWSRAERAIRANTUMULEUMSVALVEDVERVESVETVOSVUS',
  SES: 'AMEBANELIHATKINQUISEDTETTIATONUTO',
  SET: 'HICIBOIERNETOFFONSOSEOUSOUTPFXTEETERTLETOSUIDULAULEUPS',
  SEU: 'DAH',
  SEV: 'ENSEREERSERYIERRES',
  SEW: 'AGEANSARSERSERYING',
  SEX: 'ERNFIDIERILYINGISMISTPOTTANTARTETTICTONTOSTRYTURTUSUAL',
  SHA: 'BANBBYCKOCKSCKYDEDDERDESDOWDUFFIIFTSFTYGGYGIAHEEHIDHINIKHIRDIRNIVAKENKERKESKHAKILKOSKTAKTILEDLEELESLLULLYLOMMALMANMBAMBUMEDMERMESMIMMIRMMYMOYMUSNDYNGYNKSNNANNYNSANTINTYPEDPENPERPESPKAPOORANRDSRDYREDRERRESRIARIFRKIRKSRKYRNSRNYRONRPSRPYRRARRYSTATANUGHULAULSURIUWEVEDVEEVENVERVESVIEWEDWLSWMSWNYYEDYKHZAM',
  SHE: 'AFSAFYALSARDARSATHAVEBARBATDERDIMELYENSENYEPYERSETSETYEVEIKHIKSILAKELLAHLFYLLSLLYLTALTYLVELVYNDSOLSRDSRIARIFRPARRIRRYSHAUCHUGHVELVRIWEDWELWERYLE',
  SHF: 'SEP',
  SHI: 'BAHBARCERELDELSERSESTFTSFTYISMITEKARKIIKRAKSAKSELFALHALLALLSLLYLOHMALMEIMMYNDYNEDNERNESNNYNTONTYNZAPPOPPYRAZRESRKSRKYRRARRSRTSRTYSTSTTYVAHVASVERVESVEYVOOVVY',
  SHL: 'OCK',
  SHM: 'OES',
  SHN: 'APSOOK',
  SHO: 'ALSALYATSCKSDDYDERERSFARFULGUNHETHJIJISLOMNDEOEDOFAOKSOLSOTSPPEPPYRANREAREDRERRESRLSRTSRTYTESTTSTTYUGHULDUSEUTSVALVEDVELVERVESWEDWERWUP',
  SHR: 'ADDADHAMEANKAPEAVEEDSENDEWDEWSIDEIEKIFTIKEILLIMPINEINKITEIVEOFFOGSOUDOVEOVYUBSUFFUGSUNKUPSUTI',
  SHT: 'ETLICK',
  SHU: 'CKSDNAFTYGGYLERMACMALNTSTEDTESVRA',
  SHW: 'EBO',
  SHY: 'ERSESTINGISHPOO',
  SIA: 'LIALICLIDLIS',
  SIB: 'BEDBERYLS',
  SIC: 'ANAANICANCARCEDILYKEDKENKERKETKIEKLEKLYSACULAULIYOS',
  SID: 'DERDHADHIDOWDURESTINGIONLEDLERLESNEY',
  SIE: 'CLEGEDGERGESNNARRASTAURSVEDVERVES',
  SIF: 'AKAFLETEDTER',
  SIG: 'GERHEDHERHTSHTYILLILSLOILOSLUMMASNALNEDNEENERNETNOINONNORNUMRIMURD',
  SIJ: 'ILL',
  SIK: 'ARAHRAIMIKIM',
  SIL: 'AGEANEENEENIENTETZICAICOKEDKENKERKIELARLERLONOAMOEDPHATEDUREVAEVANVASVERVEXVIA',
  SIM: 'ABAARAARSBILCONEONIADIALIANIIDILEITYKINLINMERMONNELONYOOLOOMOONOUSPAIPERPLEPLYSIMSONULAULEURG',
  SIN: 'AICAWADERDHIDLEDOCDONDRYEWSEWYFULGEDGERGESGEYGFOGLEGLYIANICOIFYISMITEJERKEDKERNEDNENNERNETTERTOCZER',
  SIO: 'UAN',
  SIP: 'AGEAPUERSHACHONIBOINGPEDPERPETPIOPLE',
  SIR: 'CARDAREESENEENSENYESSIANINGIUSKARPEAPLERAHRASREEUPSUPYYAN',
  SIS: 'ALSHAMITHKINLEYSOOTENTERTLETRA',
  SIT: 'ARSCOMHENHESINGKANREPTEETENTERUALULAUPS',
  SIV: 'ERS',
  SIW: 'ASHENS',
  SIX: 'AINGUNINGISHMOSTESTHSTUS',
  SIZ: 'ARSERSIERINGZLE',
  SJO: 'MILUKE',
  SKA: 'LDSNCENDATEDTERTESTOL',
  SKE: 'ANEANSDGEECHELYENSERYETSICHIGHILYINSIPPLETLICLLYLPSLVYNAINESPPERRYTCHWEDWERWLY',
  SKH: 'IAN',
  SKI: 'BBYBOBDDYDOOECHEGHERSEURFFSINGLLOLLSLLYLTSLTYMOSMPSMPYNCHNKSNNYPPYRLSRRSRTSRTYTEDTERTESTTYVEDVERVESVIEVVY',
  SKL: 'ATEENT',
  SKO: 'ALSLLYUTH',
  SKR: 'EELIKEYER',
  SKU: 'LKSLLSLLYNKSNKYRRY',
  SKY: 'BALCAPFTEFULINGISHLABMANMENRINWAY',
  SLA: 'BBYCKSGGYKEDKERKESKINLOMNGSNGYNTSPPYRTHSHYTCHTEDTERTESVEDVERVESVEYVICVINYEDYER',
  SLE: 'AVEAZYDGEECHEKSEKYEPSEPYETSETYEVEEZYIGHPEZUTHWEDWERWTHYEDYER',
  SLI: 'CEDCERCESCHTCKSDEDDERDESESTGHTMEDMERMESMLYMSYNGENGSNKSNKYNTEPEDPESPPYPUPTCHTHYTTYVERWER',
  SLO: 'BBYDGEGANIDSJDSKEDKENOMYOPSOSHPEDPERPESPPYSHYTEDTHSUCHUGHUSHVAKVENWEDWERWLYWUPYDS',
  SLU: 'BBYDGEDGYFFSGGYICEICYINGMMYMPSMPYNGERBSRPSRRYSHYTCHTTY',
  SLY: 'ESTISHPES',
  SMA: 'CKSLLSLLYLTILTOLTSLTZRMSRMYRTSRTYTCHZES',
  SME: 'ARSARYATHECHEKSEKYETHGMALLSLLYLTSRKSRVYTHEUSEUTH',
  SMI: 'DDYDGELAXLEDLERLESLETLEYRCHRISRKSRKYTCHTERTESTHSTHY',
  SMO: 'CKSGGYKEDKERKESKEYKOSLTSOCHOGEOTHUCHUSE',
  SMR: 'ITI',
  SMU: 'DGEDGYGLYRKSRRYTCHTTY',
  SMY: 'RNA',
  SNA: 'BBYCKSCKYFUSGGYILSILYITHKEDKERKESKEYPERPPEPPSPPYREDRERRESRKSRLSRLYSTESTYTCHTHETHSVELWEDWLEZZY',
  SNE: 'AKSAKYAPSATHCKSERSERYESHESTEZEEZYLLSLLY',
  SNI: 'BELCKSDERFFSFFYFTYGHTPEDPERPESPPYTCHTHETHYVELVEY',
  SNO: 'BBYBOLCATDLYODSOKSOLSOPSOPYOSEOTSOTYOVEOZEOZYREDRERRESRTSRTYTTYUCHUTSUTYWEDWIE',
  SNU: 'BBYDGEFFSFFYGLYRLY',
  SNY: 'ING',
  SOA: 'KEDKENKERLLYPEDPERREDRERVES',
  SOB: 'BEDBEREITERSFULOLE',
  SOC: 'AGECERIALIESIOLIUSKEDKERKETLESMANMEN',
  SOD: 'AICDEDDENIUMOKUOMY',
  SOE: 'KOEVER',
  SOF: 'ANEARSFITKEETASTENTERTIETLY',
  SOG: 'GED',
  SOI: 'GNELEDREE',
  SOK: 'OKIULK',
  SOL: 'ACEACHANDANOANSARYATEDANDATDEREASEILEINELYEMNENTERAERTEUSEYNFGEGELIDIIDOIDSINGIONISTLARLERLYAODIOEDONSOTHUMSUTEVEDVERVESVUSYMA',
  SOM: 'ALIALOATABERBREDELERSITELERNERNUSPAYPNE',
  SON: 'ANTARSATADERDESERIGERGLEGOIICAICSINGIOUNETRAISIETAG',
  SOO: 'DLEDLYGANGEEJEEKIENERNLYPERRAHRKIRKYRMASOOTEDTERTHETHS',
  SOP: 'HERHIAHICHTAITEORSPEDPER',
  SOR: 'AGEBEDBETBICBINBOLBUSCERDESDIDDORELSELYEMAESTGHEGHOGOSITENEDNEROSERELRENROAROWTALTEDTERTESTIETLY',
  SOS: 'HEDSLE',
  SOT: 'HICHISNIANIKOLSTEDTERTIE',
  SOU: 'ARICARCHYDANDGEDGYEAKEEFFFLGANGHSGHTLEDMAKNDSPEDPERPLEPONRCEREDRENRERRLYSEDSERSESSHYTARTERTHSUSH',
  SOV: 'IETITERAN',
  SOW: 'ANSARSCARDERENSERSINGINSISHLTH',
  SOY: 'ATELED',
  SOZ: 'INEINSZLEZLY',
  SPA: 'CEDCERCESDEDDERDESDIXHEEHISILSITSKEDLAXLESLLSNDYNEDNKSNKYRCHREDRERRESRGERIDRKSRKYRRYRSERTARTHRUSSMSTEDTESTHATHETIOULDVERVIEVINVITWNSWNYYADYED',
  SPE: 'AKSANSARSARYAVECIECIFCKSCKYCUSECEECHEDOEDSEDYELSERSILSIRSISEISSLLSLTSLTZNCENCYNDSNSERMARMSRMYRONRSETCHWEDWER',
  SPH: 'ALMENEEREERYINX',
  SPI: 'CAECALCASCEDCERCESCEYCKSDERELSERSFFYGOTKEDKERKESLEDLERLESLLSLLYLTHLUSNAENALNEDNELNESNETNNYNORRAERALRANREAREDREMRESRITROLRTSSSYTALTEDTESVVY',
  SPL: 'AKEASHATSAYSEENEETENTICEINEINTITEITSOITOREOSHUNTURT',
  SPN: 'INX',
  SPO: 'ACHDESFFYGELILSILTKANKEDKENKESLIANGENGYOFSOKSOKYOLSONSONYORNORSRALREDRESRIDRTSRTYSHYTTYUSEUSYUTSUTY',
  SPR: 'ACKAGSAINANGANKATSAWLAYSEADEEDEESENGENTEWLIEDIERIGSINGINKINTITEITSITZOATOILONGOSEOTYOUTUCEUCYUERUESUGSUIKUITUNGUNKUNTUSHYERYLY',
  SPU: 'DDYGGYINGMEDMESNCHNGENKSNKYNNYRGERIARNSRRYRTSTTATUM',
  SPY: 'DOMINGISMROS',
  SQU: 'ABSADSAILALIALLALMAMAAMEAMYAREARKARYASHATSAWKAWLAWSDGEDGYEAKEALEAMEELEGSIBSIDSILLINTIREIRKIRLIRMIRRIRTISHISSOZESHYUSH',
  SRA: 'DHA',
  SRI: 'RAM',
  STA: 'BLEBLYBOYCEYCKSCTEDDADERDESDIADICDIEDLEFFSGEDGERGESGEYGGYGNEIGSINSIRSIRYITHKEDKERKESLAGLEDLERLESLINLKOLKSLKYLLSMBAMENMINMPSNCENCHNDINDSNEDNEKNESNGSNKSNLYNNONZANZENZOPESPHSPLEPLFRCHREDREERERRESRIKRKYRNYRRYRTSRTYRVERVYSESSISTALTEDTERTESTICTORTUATUETUSVEDVERVESXISYEDYER',
  STD: 'DMP',
  STE: 'ADSADYAKSALSALYAMSAMYEDSEKSELEELSELYEPSEPYERSERYEVEFANIGHINSKANLAELAILARLESLICLLAMMAMMYNARNCHNIANOGNOSPHEPPEPUPRADREORESRICRIDRINRNARNORNSROLRVETCHTHYVANVELVENVIAWED',
  STH: 'ENE',
  STI: 'BICCHSCKSCKYCTADDYFFSFLEGMAGMELESLETLLSLLYLTSLTYMESNGENGONGSNGYNKONKSNKYNTSNTYPEDPELPESPOSRIARKSRPSRRATCHTHETHYVER',
  STO: 'ACHATSCAHCKSCKYDGEDGYGEYGIEICSKEDKERKESLAELASLEDLENLESLIDLONMALMASMPSNEDNENNERNESNEYOGEOKSOLSONDOPSORYOTHPEDPENPERPESRAXRAYREDRERRESREYRGERKSRMSRMYTERUNDUPSUREURSURYUSHUTHUTSUTYVEDVENVERVESWCEWEDWERWPSWSEWTH',
  STR: 'ACKACTADEADLAFEAGEAIKAILAINAITAKAAKEAKYAMPANDANGANYAPSASSATAATEATHATIAVEAWSAWYAYSEAKEAMECKEEKEELEENEEPEETEITEKEENGENTEPSESSEWNEWSIAEIALICHICKICTIDEIFEIFTIGAIKEINDINGIPEIPSIPTIPYIVEIVYOAMOBEODEOILOKEOKYOLDOLLOMAOMBOMEONDONEONGOOKOOTOPSOTHOUDOUPOUTOVEOWDOWNOWSOYSUCKUCTUDEUISUMAUMSUNGUNTUSEUTHUTSYCHYPE',
  STU: 'ARTBBYBERBOYCCODDYDIADIODYSFFSFFYGGYLLSLTYMERMMYMORMPSMPYNTSNTYPASPEDPESPEXPHEPIDPORRDYRINRTERTSRTY',
  STY: 'ANYFULINGLARLEDLERLESLETLLILUSMIERAXROLRYLTHE',
  SUA: 'BLEBLYEDAVER',
  SUB: 'ACTAGEAHSAIDAUDBEDDEBDIEDITDUEERSETHFEUFIXGETGITGODGUMITOIYAJEELETLIDLOTMANMENMITNETNEXORNPARSEASETTLETLYURBVIIWARWAY',
  SUC: 'CAHCESCINCORCUBCUSHOSKEDKENKERKETKLELATRESURIURY',
  SUD: 'ANIARYATEDENDERDLEORSSEDSERSES',
  SUE: 'DEDDESGEERRERTEVIC',
  SUF: 'FERFIXISM',
  SUG: 'AMOANNARSARYENTGANGILHED',
  SUI: 'DAENTSSSETEDTESTLYTORVEZ',
  SUK: 'KAH',
  SUL: 'ABAAIBCALCARCUSDANFASFIDFURINGKEDKERLANLENLOWPHAPHOTAMTANTRYUANUNG',
  SUM: 'ACHACSAGEASSBALBULDUMMAEMARMASMATMEDMERMITMONMUTNERPERPHYPITPLE',
  SUN: 'BOWCKECUPDAEDARDAYDEKDERDEWDIKDOGDRADRIDRYGARGHAGLOHATKENKETKIELETLITNASNEDNUDRAYSETTANUPSWAYYIE',
  SUO: 'MIC',
  SUP: 'ARIAWNERBERIERLERSINELEXPEDPERPLEPLYPOS',
  SUR: 'AHIAHSBEDCLEELYESHESTETYFEDFERFIEFLEGEDGERGESHAIIGAMITNAINAPNAYRAHRASREYTAXVEYWAN',
  SUS: 'ANSIANLIKSEXUMUURR',
  SUT: 'AIOHERILELERRASTASTEETENTERTINTLEURE',
  SUZ: 'UKI',
  SVA: 'MINNTERAJ',
  SVE: 'LTE',
  SWA: 'BBYDDYGEDGERGESGGIGGYILSINSIRDLERLESLLOMISMPSMPYNGYNKSNKYNNYRAJRDSRDYRFSRGARMSRMYRRYRTHRTYRVESHYTCHTHETHSTHYTOWVERYEDYER',
  SWE: 'ARSATHATSATYDENDESDGEDRUENSENYEPSEPYERTESEETSETYLLSLLYLTHLTYRVEVEN',
  SWI: 'DGEFTSFTYLLSMMYNEYNGENGSNGYNKSPEDPERPESPLERLSRLYSHYTCHTHEVEDVELVERVESVETWET',
  SWO: 'ONSONYOPSOSEOSHRDSUGHUNDUNS',
  SWY: 'THE',
  SYB: 'OES',
  SYC: 'EESHEEITEOCKOMA',
  SYD: 'DIRNEY',
  SYL: 'ENEINGLABLIDLISPHSPHYVAEVANVASVIAVICVIN',
  SYM: 'BOLPUSTAB',
  SYN: 'CEDCHSCOMDETDICDOCEMAODSTANTAXURA',
  SYP: 'HERHON',
  SYR: 'ENSIACIANINXTICTISUPSUPY',
  SYS: 'OUTSELTEM',
  SYZ: 'YGY',
  TAB: 'ANDARDBEDBERBISEFYERSIRALASLEDLERLESLETOOSOOTORSOURRETRIZUEDULAULE',
  TAC: 'ANAHESKEDKERKETKEYKLEOMATICTORTUS',
  TAD: 'JIK',
  TAE: 'NIA',
  TAF: 'FIAFLEIASWIZ',
  TAG: 'ALAALOAURGEDGERGLEISHLETLIARAGUANULA',
  TAH: 'ALIAMIEENINAITIONASILSIN',
  TAI: 'AHAGASGLEHOAKIHKUNLEDLERLETLGELLELORLTELYEMENNANNOSNTENTSNUIPANPEIRGESCHSHOVERWANYAL',
  TAJ: 'IKI',
  TAK: 'AHEERSETHINGINS',
  TAL: 'AJEARIARSBOTCEDCERCKYCUMENTERSIONITEKEDKEEKERKIELERLESLETLISLITLOLLOWMASMUDONSOSEPIDTERUKAUKSUTOWARWEG',
  TAM: 'ALEALSANUARABACBERBOOBORBUREINELYERSESTIASINEINGINYISEMARMIEMUZOYOPANPEDPERPINPOEPONPOYPURURE',
  TAN: 'ACHAKAALABURCELDANDEMDLEEGAGEDGERGIEGKAGLEGLYGOSGUEGUMGUNGUTICAIERIKOISTITEJIBKAHKASKEDKERKIEKKAKLENEDNERNICNIDNINNYLOANRECSELSEYTLETRATUMZEBZIB',
  TAO: 'ISMISTIYATAIYIN',
  TAP: 'AJOALOERSERYETAETEETIINGIROIRSISMISTLETNETOSAOUNPEDPENPERPETPITUYAUYO',
  TAQ: 'LID',
  TAR: 'AGEANDAPHASCATABETBLEBOXBOYCELDLEEFAGEDGERGESGETGUMIFFINGIQAIRIISHMACMANNALOCSOKSOTSPANPONPOTPUMRASREDRERRESRIEROWSALSIASUSTANTARTEDTENTERTLETLYTROUMAVIAYBAZAN',
  TAS: 'AJOBIHCALHIEIANKEDKERKITLETSAHSALSELSERSESSETSIESOOTEDTENTERTES',
  TAT: 'AMIARYBEBCHYERSIANLEROOSTEDTERTIETLETOOTVA',
  TAU: 'GHTLCHLIANTSPESPOURICRIDRINRUSRYLTEDTENTERTITTLYTOG',
  TAV: 'ASTELLERNERSERTGHIOLA',
  TAW: 'DRYERSERYHAIHIDINGITEKEEKINNEYNIENLEPIESEDSESTIEYER',
  TAX: 'EMEERSIEDIESINEINGITEMANMENONSWAX',
  TAY: 'LORSMM',
  TAZ: 'EEAZAS',
  TCH: 'ASTEKAICK',
  TEA: 'BOXBOYCHECHYCUPGLEGUEINGISHISMMANMEDMEOMERNALPOTPOYREDRERSEDSELSERSESSLETEDTHEZELZERZLE',
  TEB: 'BADBETETH',
  TEC: 'ALIHEDHIEHNEOMATALTUMUMAUNA',
  TED: 'DEDDERIUM',
  TEE: 'DLEINGMEDMERNERNETNIENSYNTYPEERERTANTEETERTHETHYVEE',
  TEF: 'LON',
  TEG: 'EANMENUASULA',
  TEH: 'SIL',
  TEI: 'HTEIDSNDSOID',
  TEJ: 'ANO',
  TEK: 'IAHKENTOS',
  TEL: 'ANGARYEDUEGAEGNEGUEIAEPHESMEUTFERIALIUMLEELENLERLINLUSOMESONUGU',
  TEM: 'ENEIAKPEHPERPLEPOSPREPTSPUSPYOSER',
  TEN: 'ACEACYAILAIMANTDEDDERDONDREDRYENTETSIAEIASINONERNISONSOREORSOURPINRECSASSAWSEDSERSESSONSORTEDTERTHSTIETORUESUISUITUREURYUTIUTOZON',
  TEO: 'PAN',
  TEP: 'ALSARYEESEFYHRA',
  TER: 'AISAPHASSATABIABICCELCERCESCETCIACIOEDOESAETEEUSFEZGALGUMMALMANMEDMENMERMESMINMLYMONMORNALNARNEDNERNESPENPINRAERALRANRARRASRETRIERITRONRORSERTIATIITIOTONUAHVEEZETZIO',
  TES: 'ACKLASSELTAETAOTARTEDTEETERTESTISTONTOR',
  TET: 'ANYARDCHYHERHYSRADRAORASRICRIXROLRYLTERTIX',
  TEU: 'CERCRIFITTON',
  TEV: 'ISS',
  TEW: 'ARTHITINGTAWTER',
  TEX: 'ACOANSTUS',
  THA: 'CKSIRMKURLERLIALLIMESMINMUSNAHNANNESNKSPESRENRMSTCHTLLWEDWER',
  THE: 'AVEBANCAECALCIACLAFTSGNSINEINSIRNIRSISMISTMEDMERMESMISNADNALNARNCENNEODYORYRAPREDRESRIARMERMORMSRONSESSISTASTCHTICTINTISWEDYLLYREYVE',
  THI: 'ASIBETBLECKECKSCKYEVEGHSGHTLLSLLYNGSNGYNKSNLYOLSRAMRDSRLSRSTRTYSBESLLTKATSIVELXLE',
  THO: 'CHTGHTLEDLESLLILOILOSLUSMANMASNGANGSNGYOIDRALRAXRIARICRNSRNYRONRPERPSUEDUGHUSEWEL',
  THR: 'ACKAEPAILAINALLANGASHASOASTAVEAWNAWSEADEAPEATEEPEESEIPENEEPEESHICEIFTILLIMPINGIPSISTIVEOATOBSOCKOEDOESONEONGOPEOVEOWNOWSUMSUSHUST',
  THS: 'ANT',
  THU: 'BANJASJINJYLLIALIRMBSMBYMPSNARNGENORRGIRIORLERLSRSERSTSHISLYYAS',
  THW: 'ACKAITARTITEORL',
  THY: 'IADINEMESMEYMICMINMOLMUSMYLRISRSERSISELSEN',
  TIA: 'RAS',
  TIB: 'BIEBITERTIADIAEIALIAS',
  TIC: 'ALSHELKEDKENKERKETKEYKIEKLEKLYTACTICTOCUNA',
  TID: 'BITDERDLEDLYELYIEDIERIESIFEILYINGLEY',
  TIE: 'BOYDOGINGNDANTANTOPINRCEREDRERWIG',
  TIF: 'FEDFIEFINFLETER',
  TIG: 'ERSERYGERHTSLICLONNONNUMONSRAIRISTAG',
  TIK: 'KERKUNLINOOR',
  TIL: 'AKAAKSDENDESERSERYINGLEDLERLETLEYLOTMUSPAHSITTEDTERTHSTUPYER',
  TIM: 'ANIAUAAWABALBERBREELYERSIASINGISHISTMEROTE',
  TIN: 'AGEAJACALCTSDALDEREALEANEASEIDETYFULGEDGERGESGIDGISGLEGLYGUYIERILYINGKERKLEKLYLETMANMENNEDNENNERNETOSAPOTSELTEDTERTIE',
  TIP: 'CATFULHIAITILETMANMENOFFONIPEDPEEPERPETPLEPLYREETOETOPULAURA',
  TIR: 'ADEAGEIBAINGLEDRETRITWIT',
  TIS: 'ANEHRISUEWIN',
  TIT: 'ANOANSBITERSFERHALHEDHERHESIANIENIESLEDLERLESMALMANMENOKIRESTERTIETLETUPULEULI',
  TIV: 'OLI',
  TIZ: 'EURWIN',
  TJA: 'ELENDI',
  TME: 'SESSIS',
  TOA: 'DERSTSSTYTOA',
  TOB: 'IAHIASIESINEIRA',
  TOC: 'HEROMESIN',
  TOD: 'AYSDERDLEIES',
  TOE: 'CAPINGTOE',
  TOF: 'FEEILEOREORNTER',
  TOG: 'AEDATAATEGEDGELGERGLEUES',
  TOH: 'OME',
  TOI: 'LEDLERLESLETSEDSONTEDTOIVEL',
  TOK: 'AYSENSING',
  TOL: 'ANEANSEDOINGITELEDLERLONMENOWASELSEYTECTERUICUIDUOLUYLYLSZEY',
  TOM: 'ANDANSATOBACBAKBALBEDBICBOYCATCODENTIALISHIUMJONKINMEDMEROLOORNPONRIGTIT',
  TON: 'ADAANTEMEERSGANGASGEDGERGUEGUYICSIERIESIFYINGISHITEJONKINLETNERNESOUSSILSOR',
  TOO: 'ARTDLEKENLEDLERLSILSYMLYRIEROOSIETEDTERTHSTHYTLETSYZLEZOO',
  TOP: 'ASSATOAZYCAPEESEKAENGEPOERSFULHESHETHUSICSINGMANMENNETPEDPERPLEPLY',
  TOQ: 'UESUET',
  TOR: 'AHSAJAANACELCHYEROFELFLEGOTIESMAEMENNALNEYNITNUSOIDOSEOTHOUSPEXPIDPORQUERETRIDSELSESSKSSOSTENTESTIETILTISTORTUEULAULIVIDYFY',
  TOS: 'HERHESHLYILYSEDSERSESSUPSUTTAOTON',
  TOT: 'ALSARAEMSEMYERSERYHERINGORAOROTEDTENTERTIETLETUM',
  TOU: 'ARTCANCHECHYFICGHSGHTGHYPEEPETRBEREDRERRETRNERTESEDSELSERSESSLESLYTEDTERZLE',
  TOW: 'AGEARDBARDIEELSERSERYGHTHEEIESINGKAYNEDNEENERNETNIENLYSERSONZIE',
  TOX: 'IFYINEINSITYOIDONE',
  TOY: 'DOMERSFULINGISHMANMENONSOTA',
  TRA: 'BALBEABESCEDCERCESCEYCKSCTSDALDEDDERDESGALGIAGICGUSIKSIKYILSILYINSINYISTITSJETMALMELMPSNCENFDNKANKYNSENSFNSLNSPPANPESPPYSHYUMAVELVESVISVOYWLSYNEZIA',
  TRE: 'ADSASRATSATYBLEBLYFAHFLEGETMEXMIEMORNCHNDSNDYPAKPANPIDPPERONSISSSYTISVETVISVORWEL',
  TRI: 'ACEACTADSAGEALSARYAZOBALBESCAECARCEDCESCHICHYCIACKSCKYCONCOTDIIDRADUOENEENSERSFIDFLEFLYGAEGLAGLYGONGOSGYNJETKERKIRLBYLITLLILLOLLSMERMLYNALNEDNESNGANILNOLODEOLEOLSOPSOSEPALPELPESPLAPLEPLYPODPOSPOTSHASTESULTERTONTORTYLUMFUNEVATVETVIAWETXIE',
  TRO: 'AKSCARCHACHECHICKSGGSGONGUEIKAJANKEDKERKESLLSLLYMBAMBEMPEMPSNASNERNESOLYOPSPALPERPESPHIPHYPIAPICPINPPOPYLTHSTOLTTYTYLUGHUPEUSEUSSUTSUTYVERVESWEDWELWIEWTH',
  TRP: 'SET',
  TRU: 'ANTCEDCESCHACKSDDODGEESTFFEINGISHISMLLILLOLLSMANMPHMPSNCHNKSONGSTSSTYTHSTHYTTAVAT',
  TRY: 'GONINGOUTPANSTESTS',
  TSA: 'DESDIKDISMBA',
  TSE: 'TSE',
  TSK: 'INGTSK',
  TSO: 'TSI',
  TSU: 'RESRIS',
  TSW: 'ANA',
  TUA: 'REG',
  TUB: 'AGEATEBALBEDBERBIEBOEERSFULINGLETMANMENOIDULEULI',
  TUC: 'ANAANOHISHITHUNKEDKERKETSONUMAUNA',
  TUE: 'BOR',
  TUF: 'FETTEDTER',
  TUG: 'GEDGERHRAMANRIK',
  TUI: 'LLENGA',
  TUL: 'ADIAREASICANIACIPAIPIIPSIPYLESNICWAR',
  TUM: 'AINBAKBEKBLEBLYBOAEFYFIEIONMEDMELMERORSOURPHYTUMULIULT',
  TUN: 'DERDRADUNEBOERSEUPFULGAHGANGOSGUSICAICSINGISHISTKERKETNEDNELNERNEYNITNOR',
  TUP: 'AIAARAELOIANIKSLESMANMENPEDUNA',
  TUQ: 'UES',
  TUR: 'ACOBANBEHBIDBITBLEBOSBOTCICDUSEENFEDFENGIDGORINGIONKENKEYKICKISKLEMETMITMUTNEDNELNERNEYNIPNIXNORNUPPIDPISRELRETRUMSHASIOTANTLETURURIVESWAR',
  TUS: 'CANCHEHEDHERHESHIEKARKEDKERSAHSALSARSEHSERSISSLESORSUR',
  TUT: 'EESELAELEELOLERMANMENORSORYRIXSANTEDTIS',
  TUX: 'EDO',
  TUY: 'EREERS',
  TUZ: 'ZLE',
  TWA: 'DDYINSITENGSNGYNKYRLYZZY',
  TWE: 'AKSAKYEDSEDYENYESEESHESTETSEZELVENTYRPS',
  TWI: 'BILCERCETERSGGYLITLLSLLYNEDNERNESNGENLYRLSRLYRPSSELSTSSTYTCHTTY',
  TWO: 'FER',
  TWY: 'ERSVER',
  TYA: 'UVE',
  TYB: 'ALTURN',
  TYC: 'OON',
  TYD: 'DENDYNEUS',
  TYK: 'ING',
  TYL: 'ARIIONOMAOSEOTE',
  TYM: 'BALPAN',
  TYN: 'ING',
  TYP: 'EESHIAHICHONHUSICAIERIFYIKAINGIST',
  TYR: 'ANTIANINGOIDOMAONE',
  TYS: 'TIE',
  TYT: 'HEDHES',
  TZE: 'TSETZE',
  TZU: 'RIS',
  UAK: 'ARI',
  UBA: 'NGI',
  UBE: 'RTY',
  UBI: 'ETYQUE',
  UBO: 'UND',
  UBU: 'SSU',
  UCH: 'EAN',
  UCK: 'ERS',
  UCU: 'UBA',
  UDA: 'LER',
  UDD: 'ERS',
  UGA: 'NDA',
  UGH: 'TEN',
  UGL: 'IERIFYILY',
  UGR: 'IANOID',
  UGS: 'OME',
  UHL: 'ANS',
  UIG: 'HUR',
  UIR: 'INA',
  UKA: 'SES',
  UKI: 'YOE',
  ULA: 'MASULA',
  ULC: 'ERSERY',
  ULE: 'MASTIC',
  ULI: 'CONDIATIS',
  ULL: 'AGEINGUCOUCU',
  ULM: 'ATEOUS',
  ULN: 'AGEARE',
  ULS: 'TER',
  ULT: 'IMAIMEIMOIONRAS',
  UMB: 'ELSERSLESONERAERALRASRELRETRIL',
  UMF: 'AAN',
  UMG: 'ANG',
  UMI: 'ACKACSAKSAQS',
  UML: 'ANDAUTOAD',
  UMP: 'INGIREQUA',
  UMT: 'EEN',
  UNA: 'BLEBLYGEDKINRCHRMSVIDWAYWEDXED',
  UNB: 'AINAITALEANEANKARBAREARKARSASEEARELLELTENDENTIASINDITTLEDOATODYOLDOLTONEOOTORNRANREDUNGURNURYUSHUSKUSY',
  UNC: 'AGEAKEALKALLALMAMPAPSARTASEASKASTATEAVEHICHIDIAEIALINIITEITYLADLAYLESLEWLIPLOGLOTLUBOATOCKOFTOIFOILOINOLTOLYOMEOOLOOPOPEORDOREORKOSTOUSOWLRAMRIBURBURDURLUTEUTH',
  UND: 'AMNARKATEAUBEADEAFEANEARECKEEPEFTERNEWYIESINEIRKOCKOEROESONEOSERABRAGRAWREWUKEULLULYUREUSTUTYYED',
  UNE: 'ASEASYATHDGEGALMPTPICSCOVENVILYED',
  UNF: 'ACEACTAINAIRASTEEDEELELEELTILEILLILMINEIRMITSIXTLAGLATOLDONDOOLORKORMOULOXYREERETULLURL',
  UNG: 'AINAMYAROEARELTIFTILDILLILTIRDIRTIVELADLEELIBLUENAWOLDONEOODOWNRIDRIPROWUALUESUISULAULLULPYVE',
  UNH: 'AFTAIRALEANDANGARDASPATEATSAVEAZYEADEALEEDELDELEELMELPENTERDEROEWNIDEIGHIVEOEDOLDOLYOMEOODOOKOOPOPEOSEUEDULLUNGURTUSK',
  UNI: 'ATEBLECEDCEFCUMDLEDLYFICOIDOLAONSPEDPODQUESEXSONTALTEDTERTES',
  UNJ: 'OINUST',
  UNK: 'ENDENTEPTILLINDINGINKIRKISSISTNEWNITNOTNOW',
  UNL: 'ACEADEAIDAMEANDASHATHAYSEADEAFEALEANEFTENTESSIKEIMBIMEIMPINEINKISTIVEOADOCKOOKOOPORDOSTOVEUCKUSHUSTUTE',
  UNM: 'ADEAIDAILAKEANSASKASTATEAZEEEKEETELTESHETEETHEWSILDINDIRYISTIXTOLDOOROWN',
  UNN: 'AILAMEAPTEAREATESSESTETHICEIGHOSE',
  UNO: 'ILYPEDPENRALWED',
  UNP: 'ACKAIDALEARKASSAVEAWNEELEGSENSENTICKILEINSITYLANLATLOWLUGOPERAYRIMROPUFFURE',
  UNQ: 'UITUOD',
  UNR: 'ACKAKEANKAREASHEADEALEELEINENTESTICHIDEIFEIGSINDINGIPEIPSOBEOLLOOFOOTOPEOUTOVEUDEUEDUFEULEULYUNGUSTUTH',
  UNS: 'ACKAFEAGEAIDALTAMEANEASHAWNAYSEALEAMEATEELEENELFELLELYENTEREETSEWNEWSHEDHIPHODHOEHOPHOTHUTICKINGKINLIMLIPLITLOTLOWMUGNAPNIBNOWNUGOFTOILOLDOLEOMEOOTOREORTOULOUROWNPANPARPEDPINPITPOTPUNTARTEPTOPTOWUEDUITUNGUNKURE',
  UNT: 'ACKALLAMEAUTEAMEEMELLENTHAWIDYIEDIESILEILLILTIMEINEIPTIREOLDOMBONEORNOWNRIGRIMRODRUEUCKUNEURFURN',
  UNU: 'GLYREDSED',
  UNV: 'AINEILESTEXTOIDOTE',
  UNW: 'ALLAREARMARNARPARYEALEELEFTELDELLEPTHIGHIPILDILLILYINDINKIREISEISHISTITSIVEOMBONTOOFORKORNOVERAPRIT',
  UNY: 'OKE',
  UNZ: 'IPSONE',
  UPA: 'RCHRNASES',
  UPB: 'ANDANKEAREATELTENDINDLOWOILOLTORERAYREDRIMROWUOYURN',
  UPC: 'ALLARDASTITYOCKOILOMEROPURL',
  UPD: 'ARTATEECKIVEOMEOVERAGRAW',
  UPE: 'NDS',
  UPF: 'EEDILLLEELOWOLDURL',
  UPG: 'ALEANGAPEAZEIRDIRTIVEREWROWUSH',
  UPH: 'ALEANDANGASPEALEAPELDELMILLOLDOVEROEUNGURL',
  UPJ: 'ERK',
  UPK: 'EEPNIT',
  UPL: 'AIDAKEANDANEEADEANEAPICKIFTIMBINEINKOADOCKONGOOKOOMOOP',
  UPM: 'ASTOSTOVE',
  UPN: 'ESS',
  UPP: 'ARDBADENTERSILEINGISHITYLOWOURROPUFFULLUSH',
  UPR: 'EAREINENDESTISEISTIVEOADOAROOMOOTOSEUSH',
  UPS: 'EALEEKENDENTETSHOTHUTIDEKIPLIPOAKOARPEWPINTAYTEMTEPTIRUCKWAY',
  UPT: 'AKEEARENDIDEILLILTIMEOREORNOSSOWNREEUBEUCKURN',
  UPW: 'AFTALLARDARPAYSELLENTHIRINDITHORKRAP',
  UPY: 'ARDOKE',
  URA: 'CILEUSLICMILNIANICNINNUSNYLRESRISSESTESTICZIN',
  URB: 'ANAANEIANIFY',
  URC: 'EUSHIN',
  URE: 'ASEDIADOSIDEIDOMIAMICSISTALTERTIC',
  URG: 'ENTERSING',
  URH: 'EEN',
  URI: 'NALNES',
  URL: 'INGUCH',
  URN: 'FULINGISM',
  URO: 'CHSNICPHIPODSISXIN',
  URS: 'INEOIDONEULA',
  URT: 'EXTICAITE',
  URU: 'CUMISGSESSHI',
  USA: 'BLEBLYGERGESNCERON',
  USE: 'DLYDNTFULNET',
  USH: 'EENERS',
  USI: 'NGS',
  USK: 'ARA',
  USN: 'EAS',
  USP: 'OKE',
  USQ: 'UES',
  USS: 'ELFELS',
  UST: 'ION',
  USU: 'ALSARYRERRPS',
  USW: 'ARD',
  UTA: 'HAN',
  UTE: 'RUS',
  UTI: 'BLENAM',
  UTM: 'OST',
  UTO: 'PIA',
  UTR: 'UBI',
  UTT: 'ERS',
  UVA: 'LHA',
  UVE: 'LLAOUS',
  UVI: 'TIC',
  UVU: 'LAELARLAS',
  UXO: 'RIS',
  UZA: 'RINRON',
  VAA: 'DIM',
  VAC: 'ANTATEONAOUAOUFUALUITUUAUUM',
  VAD: 'IUMOSE',
  VAG: 'ANTARYILEINAOUSROMUERUIO',
  VAH: 'ANAINEINI',
  VAI: 'DICLEDNERNLYREESYA',
  VAK: 'ASSEELILS',
  VAL: 'ENSENTETAETSEUREWEGUSINEISEIUMKYRLARLEYLISLUMOISORSOURSESUEDUERUESUREUTAVAEVALVARVEDVES',
  VAM: 'OSEPEDPERPEYURE',
  VAN: 'DALDASGEEGLOISHISTITYLAYMANMENNAINEDNERNETNICNUS',
  VAP: 'ORSORYOUR',
  VAR: 'ECHIACIAGIEDIERIESIFYINGIOSKASLETNASSALSHAUNAVEDVELVES',
  VAS: 'CLACONSALSARSOSTERTLYTUS',
  VAT: 'FULMANTEDTER',
  VAU: 'GHNLTSLTYNCENTSNTY',
  VAW: 'ARD',
  VEA: 'DARLEDLER',
  VEC: 'TISTOR',
  VED: 'AICANAIKAISMISTUIS',
  VEE: 'NASPEERED',
  VEG: 'ANSETE',
  VEH: 'MIC',
  VEI: 'GLELEDLERNALNEDNER',
  VEL: 'ARSARYATECRODTSETAIKALONLUMOCEOUROUTUREVET',
  VEN: 'ADADEDDEEDERDISDORDUEEERENEEREEROERYETIEURGEDGERGESIALIAMICEINEINSIREISEITELINNELNEROMSOMYOSEOUSTEDTERTILTINTOYUESULAULEUST',
  VER: 'BALBIDBUMDEADETDINDOYDUNGEDGERGESIERIFYILYINEISMISTITEITYMESMILMINMISMIXNALNINNIXNONONARAYRELSALSEDSERSESSETSINSORSOSSTASTESTSSUSTEPTEXTUSUTAVELVERVESVET',
  VES: 'ICAKITPALPERPIDSELSESTALTASTEDTEETERTRY',
  VET: 'CHYOEDOEROESTEDUST',
  VEX: 'ERSFULILSING',
  VIA: 'BLEBLYLEDNDENDSSMATICTOR',
  VIB: 'ISTRIO',
  VIC: 'ARAARSARYETYINEINGKIETALTIMTORTUSUDAUNA',
  VID: 'AMEDUIDUYEOSIANKIDUAL',
  VIE: 'LLENNAWEDWERWLY',
  VIG: 'GLEIASILSNINONEORSOUR',
  VIH: 'ARA',
  VIK: 'ING',
  VIL: 'DLYELAELYESTIFYITYLAELANLARLASLUS',
  VIM: 'ANAFULINA',
  VIN: 'AGEALSATACASDEXEAEEALERYIERINGOSEOUSTEMTERTRYYLSYON',
  VIO: 'LALLANLASLERLETLINLON',
  VIP: 'ERAERSERY',
  VIR: 'AGOENTEOSGALGASGERGILGINGOSIALIFYILEIONLEDOLEOSEOUSTUETUS',
  VIS: 'AEDAGEARDAYACIDCINCUMCUSEEDHALHNUIERILEINGIONITAITEITSIVENEYORSORYTALTASUAL',
  VIT: 'ALSIALITAIUMRICRUMTAETLE',
  VIV: 'ACEANTARYELYERSEURIANIFYRES',
  VIX: 'ENS',
  VIZ: 'ARDIERIRSORSSLA',
  VMS: 'IZE',
  VOC: 'ALSATEOIDULE',
  VOD: 'KASUMS',
  VOE: 'TEN',
  VOG: 'UESUEY',
  VOI: 'CEDCERCESDEDDEEDERDLYLESVOD',
  VOL: 'AGEANSANTARYATACAECANENSENTERYINGLEYOSTSCITESUMEUPTUTAUTEVASVOXYER',
  VOM: 'ERSICAITOITSITY',
  VOO: 'DOO',
  VOR: 'AGEAGOANTPALTEX',
  VOT: 'ARYEENERSINGISHISTIVEYAK',
  VOU: 'LGESTY',
  VOW: 'ELSELYERSESSINGSON',
  VOY: 'AGEEUR',
  VRI: 'LLE',
  VRO: 'CHTOMSUWS',
  VUC: 'OMS',
  VUL: 'CANGARGUSNEDPESPICTURVAEVALVARVAS',
  VYR: 'NWY',
  WAB: 'AYOBERBLEBLYENAENORONUMA',
  WAC: 'AGOHNAKENKERKES',
  WAD: 'DEDDERDIEDLEDLYERSIESINGMALMELMOLSET',
  WAE: 'FUL',
  WAF: 'ERSERYFEDFIEFLEFLYLIBTEDTER',
  WAG: 'ANGATIAUNERSGEDGELGERGIEGLEGLYGONINGNEROGOOMAONSUHAWAGWIT',
  WAH: 'ABIAHEEHEIMAINEOOSWAH',
  WAI: 'ATAFEDKLYLEDLERNERRCHREDRSHSTSTEDTERVEDVERVESVODWAI',
  WAJ: 'ANG',
  WAK: 'ARIEELENSERSEUPIKIINGIUPKENORE',
  WAL: 'ACHERSIESINGKEDKERKIEKUPLAHLASLEDLERLETLIELONLOPLOWNUTRUSTER',
  WAM: 'ARABLEBLYEFUFLEMUSPEEPLEPUMPUS',
  WAN: 'DERDLEDOOELYGANGERGLEGUNHAPIERINGIONKELKERKLEKLYLASMOLNEDNERTEDTERTONWIT',
  WAP: 'ATAATOITIPEDPERPET',
  WAR: 'ABIAGIBLEBLYDAYDEDDENDERELYFULGUSIEDIERILYINEINGISHKEDLOWMANMEDMENMERMLYMTHMUPMUSNEDNELNERORIPEDPERPLERANRAURAYREDREERENRERRINROKRTYRYNSAWSELSLETED',
  WAS: 'ABIHEDHENHERHESHINHUPOGAPENSIETEDTELTERTESTMETRY',
  WAT: 'ALAAPEAPSERSERYFIVHERSONTERTISTLEUSI',
  WAU: 'BLECHTFIEGHTGHYKEDKENKITLEDMLENER',
  WAV: 'ERSERYEYSIERIESILYINGIRA',
  WAW: 'LED',
  WAX: 'ANDERSHAWIERILYINGMAN',
  WAY: 'AKAANGINGLAYMANMEN',
  WEA: 'KENKERKLYLDSLTHNEDNELNERNIENLYNOCPONREDRERSELSERSONVEDVERVESZEN',
  WEB: 'BEDBERERSEYEFED',
  WEC: 'CHEHTS',
  WED: 'ANABEDDEDDERELNELSFEEGEDGERGESGIESET',
  WEE: 'BLEDEDDERDOWKLYMENNEDNIENSYNTYPEDPERPLYSHYTEDVERVILWAWWEEWOWZLE',
  WEF: 'TED',
  WEH: 'NER',
  WEI: 'GHSGHTNERRDORDSRDY',
  WEJ: 'ACK',
  WEK: 'EEN',
  WEL: 'DEDDERDORFICKINLATLEDLERSHYSOMTEDTER',
  WEN: 'DEDDICETHTLEZEL',
  WEP: 'MAN',
  WER: 'ENTGILINGNERSTEVEL',
  WES: 'KITLEYSELTERTME',
  WET: 'HERTEDTER',
  WHA: 'BBYCKSCKYLEDLERLESLLYMMOMMYNGSRFERFSRRYRVESLETNATRETSOUPSUVE',
  WHE: 'ALSALYATSATYELSELYENSEPSEZEEZYKAULKSLKYLMSLPSLVENASNCENSOREDRESRRYRVEWERYEY',
  WHI: 'DAHFFSFFYLEDLESLEYLIELLYLOMLSTMMYMSYNEDNERNESNEYNGENNYPPAPPYRLSRLYRRSRRYSHTSKSSKYSTSTEDTENTERTESTEYTIN',
  WHO: 'LESLLYMPSMSOOPEOPSOSHOSYREDRESRLERLSRLYRRYRTSSENSIS',
  WHU: 'MPS',
  WHY: 'DAHFOR',
  WIB: 'BLE',
  WIC: 'HESKEDKENKERKETKUPOPY',
  WID: 'BINDERDIEDLEELYENSESTGETGIEISHOWSOWYTHS',
  WIE: 'LDSLDYNERNIE',
  WIF: 'ELYINGISHOCK',
  WIG: 'ANSDOMEONFULGEDGENGERGLEGLYHERHTSLETWAGWAM',
  WIK: 'ENOINGIUP',
  WIL: 'BURDEDDERDLYFULIERILYINGKINLEDLERLESLETLEYLIELOWMERSONTEDTERTON',
  WIM: 'BLEICKPLE',
  WIN: 'ARECEDCERCESCEYDASDEDDELDERDLEDOWDUPERSERYFULGEDGERGLEIERINGISHKEDKELKERKLENEDNELNERNIENLENOWOESONAROWTERTLETRYTUNZES',
  WIP: 'ERSINGPEN',
  WIR: 'BLEERSIERILYINGRAH',
  WIS: 'DOMELYENTESTHEDHERHESHLYINGKETPEDSEDSELSESSHESLETEDTERTITTLYURE',
  WIT: 'CHYESSFULHALHAMHEDHENHERHESHININGJARLETNEYOTOTALTEDTENTERTOL',
  WIV: 'ERNERSING',
  WIZ: 'ARDENSIERZEN',
  WLE: 'NCH',
  WOA: 'DEDDERLDS',
  WOB: 'BLEBLY',
  WOC: 'HUA',
  WOD: 'DIE',
  WOE: 'FUL',
  WOG: 'GLEIET',
  WOH: 'LAC',
  WOI: 'DRELIE',
  WOK: 'OWI',
  WOL: 'DESEAIFEDFENFERLOPTERVERVES',
  WOM: 'ANSBATBEDBLEERA',
  WON: 'DERDIEGAHGENINGNEDNERNOTTEDTON',
  WOO: 'DEDDENDIEDLYDOODSYERSFEDFERHOOINGLEDLENLERLIELLYLWAMERZLE',
  WOR: 'BLEDEDDENDERDLEKEDKERKUPLDSLDYMEDMERMILNILRALRELRITSENSERSESSETSLESTSSUMTHSTHYTLE',
  WOS: 'ITHOME',
  WOT: 'TED',
  WOU: 'BITLDNLFENDSNDY',
  WOV: 'OKA',
  WOW: 'INGSER',
  WRA: 'BBECKSGERISTITHKERNGSNNYPLEPUPSSETHSTHYXLE',
  WRE: 'AKSATHCKSCKYNCHSTSTCH',
  WRI: 'BLECHTESTGHTHTENGSSTSSTYTEETERTESTHETHYXLE',
  WRO: 'CHTKENNGSTHETHY',
  WRY: 'ESTING',
  WUD: 'DIE',
  WUL: 'DERLIE',
  WUM: 'BLEMANMEL',
  WUN: 'GEENERTEE',
  WUR: 'LEYMALRUPRUSSETSTSZEL',
  WUS: 'SER',
  WUT: 'HER',
  WUZ: 'ZERZLE',
  WYC: 'HES',
  WYL: 'ING',
  WYM: 'OTE',
  WYN: 'RIS',
  WYR: 'OCK',
  WYT: 'ING',
  WYV: 'ERN',
  XAR: 'QUE',
  XEB: 'ECS',
  XEN: 'IALIANIASIUMOMIONS',
  XER: 'IFFOMA',
  XMA: 'SES',
  XOA: 'NON',
  XYL: 'ANSATEEMSENEINAITEOIDOLSOMAOSEOYLYLS',
  XYS: 'TERTOITOSTUMTUS',
  YAB: 'BERBIEBLE',
  YAC: 'AREATAHANHTSHTYKED',
  YAD: 'AVA',
  YAF: 'FEDFILFLE',
  YAG: 'ERSGERNOBUAS',
  YAH: 'GANOOSUNAVEHWEH',
  YAI: 'RDS',
  YAJ: 'EIN',
  YAK: 'ALAALOIMAKEDKERMAKMANONASHASHI',
  YAL: 'LOW',
  YAM: 'ATOENSMERPEEUNS',
  YAN: 'DERKEDKEEKERNAMQUITRA',
  YAO: 'ORTURT',
  YAP: 'MANOCKOKSONSPEDPER',
  YAQ: 'ONA',
  YAR: 'DEDDERELYESTETAKEENEDNENNERPHARANROWURAURO',
  YAS: 'MAK',
  YAT: 'TER',
  YAU: 'PEDPERPONTIA',
  YAW: 'INGLEDLERNEDNERNEYPEDPER',
  YAX: 'CHE',
  YAZ: 'ATA',
  YBL: 'ENT',
  YCL: 'EPT',
  YEA: 'NEDREDRLYRNSRTHSTSSTY',
  YEC: 'CHSCHY',
  YEE: 'LINUCHUCK',
  YEL: 'LEDLERLOWMERPEDPERVER',
  YEM: 'ENIINGSEL',
  YEN: 'DERGEEITENEDTASTES',
  YEO: 'MANMEN',
  YEP: 'ELY',
  YER: 'AVABALBASCUMKED',
  YES: 'SEDSESTER',
  YET: 'APAHERLINTERZER',
  YEU: 'KED',
  YEZ: 'IDI',
  YFA: 'CKS',
  YFE: 'RRE',
  YGE: 'RNE',
  YIE: 'LDSLDY',
  YIL: 'DUN',
  YIP: 'PEDPEEPIE',
  YIR: 'REDTHS',
  YIZ: 'KOR',
  YNA: 'MBU',
  YOB: 'BOS',
  YOC: 'HELKEDKEL',
  YOD: 'ELSLEDLERLES',
  YOG: 'EESINIINSISMISTURT',
  YOI: 'CKS',
  YOJ: 'ANA',
  YOK: 'AGEELSINGUTS',
  YOL: 'DENDIAKED',
  YON: 'DERKERNERNIE',
  YOR: 'KERLINUBA',
  YOU: 'DENNGSPONTHSTHY',
  YOW: 'DENIESINGLEDLERLEY',
  YTT: 'RIARIC',
  YUA: 'PIN',
  YUC: 'CASKEDKELKERKLE',
  YUE: 'CHI',
  YUG: 'ADA',
  YUK: 'ATAIANKEDKEL',
  YUL: 'ANS',
  YUN: 'CANGANKER',
  YUP: 'ONSPIE',
  YUR: 'UNA',
  YUZ: 'LIKLUK',
  YVO: 'NNE',
  ZAB: 'ETAIANISMTIE',
  ZAC: 'ATEHUN',
  ZAD: 'DIK',
  ZAF: 'FARFERFIRFREREETIG',
  ZAG: 'AIEGEDUAN',
  ZAI: 'RESTHA',
  ZAK: 'KEU',
  ZAM: 'ANGBACBALBIABRAIAS',
  ZAN: 'ANADERIAHIERIESILYJONZAS',
  ZAP: 'ARAAROOTAOTEPEDUPE',
  ZAQ: 'QUM',
  ZAR: 'AMOEBAEMAIBANEC',
  ZAU: 'RAK',
  ZAY: 'INS',
  ZAZ: 'ENS',
  ZEA: 'LEDLOTTIN',
  ZEB: 'ECKECSRAS',
  ZEC: 'HIN',
  ZEE: 'KOE',
  ZEG: 'UHA',
  ZEH: 'NER',
  ZEI: 'DAE',
  ZEL: 'ANT',
  ZEN: 'AGAANADICDIKDOSICKITH',
  ZEP: 'HYR',
  ZEQ: 'UIN',
  ZER: 'EBAOEDOESOTH',
  ZES: 'TED',
  ZEU: 'GMA',
  ZIA: 'METRAT',
  ZIB: 'ETHETS',
  ZIC: 'ZAC',
  ZIE: 'GER',
  ZIG: 'GEDGERZAG',
  ZIL: 'LAHPAH',
  ZIM: 'MIS',
  ZIN: 'CEDCICCIDCKECKYCUMDIQEBSGEDGELGERKEDNIAZAR',
  ZIP: 'PEDPER',
  ZIR: 'AMSCONIAN',
  ZIT: 'HERTERZIT',
  ZIZ: 'ANYITHZLE',
  ZLO: 'TYS',
  ZOA: 'CUMRIA',
  ZOC: 'ALO',
  ZOD: 'IAC',
  ZOE: 'TIC',
  ZOF: 'TIG',
  ZOI: 'LUS',
  ZOM: 'BIEBIS',
  ZON: 'ARYATEERSINGITEKEDNAROIDULAULEURE',
  ZOO: 'IDSMEDNALNICSISTERTICZOO',
  ZOR: 'ILS',
  ZOS: 'TER',
  ZOU: 'AVENDS',
  ZOY: 'SIA',
  ZUF: 'OLO',
  ZUI: 'SIN',
  ZUN: 'IAN',
  ZUR: 'ICH',
  ZYD: 'ECO',
  ZYG: 'IONITEOIDOMAOSEOTEOUS',
  ZYM: 'ASEITEOIDOME',
  ZYR: 'IANYAN',
  ZYT: 'HEMHIAHUM'
}

const knownWords = []

for (const [key, value] of Object.entries(knownWordsDict)) {
  for(let i = 0; i < value.length; i += 3) {
    knownWords.push(key+value.substring(i, i+3))
  }
}

export default knownWords